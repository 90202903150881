import { Grid, Modal, Paper, IconButton } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MainComponent from "./Components/MainComponent.js";
import AreaComponent from "./Components/AreaComponent.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack.js";

const SelectAreaModal = props => {
    const topRef = useRef(null);
    const [selectedGeneralArea, setSelectedGeneralArea] = useState("");
    const [modalComponent, setModalComponent] = useState("main");
    const [selectedId, setSelectedId] = useState("");
    const [selectedVector, setSelectedVector] = useState("");
    const [selectedArea, setSelectedArea] = useState("");
    const componentProps = {
        selectedGeneralArea: selectedGeneralArea,
        setSelectedGeneralArea: setSelectedGeneralArea,
        setModalComponent: setModalComponent,
        selectedId: selectedId,
        setSelectedId: setSelectedId,
        selectedVector: selectedVector,
        setSelectedVector: setSelectedVector,
        selectedArea: selectedArea,
        setSelectedArea: setSelectedArea,
        setDetails: props.setDetails,
        setOpen: props.setOpen
    };

    const components = {
        main: <MainComponent {...componentProps} />,
        bottom: <AreaComponent {...componentProps} area="bottom" />,
        hull: <AreaComponent {...componentProps} area="hull" />,
        superstructure: <AreaComponent {...componentProps} area="superstructure" />,
        transom: <AreaComponent {...componentProps} area="transom" />,
        parts: <AreaComponent {...componentProps} area="parts" />
    };

    useEffect(() => {
        if (topRef && topRef.current) {
            topRef.current.scrollIntoView();
        }
    }, [modalComponent]);

    return (
        <Modal open={props.open} onClose={() => props.setOpen(false)}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "96%",
                    height: "96%",
                    maxWidth: 900,
                    bgcolor: "#e7ecef",
                    overflow: "auto"
                }}
            >
                <Grid container direction="row" sx={{ padding: "2.5% 2.5% 2.5% 2.5%" }} ref={topRef}>
                    {modalComponent !== "main" &&
                        <Grid container item xs={6}>
                            <IconButton onClick={() => setModalComponent("main")}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                    }
                    <Grid container item xs={modalComponent !== "main" ? 6 : 12} justifyContent="flex-end">
                        <IconButton onClick={() => props.setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <div style={{ padding: "0% 4% 4% 4%" }}>
                    {components[modalComponent]}
                </div>
            </Paper >
        </Modal >
    );
};

export default SelectAreaModal;
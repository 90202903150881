import { useState, useEffect, useContext, useRef } from "react";
import DataFilter from "../DataTable/DataFilter.js";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils.js";

const UsersDataFilter = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [search, setSearch] = useState("");
    const [updating, setUpdating] = useState(true);
    const [listening, setListening] = useState(false);

    const setData = props.setData;
    const setRowCountState = props.setRowCountState;
    const setLoading = props.setLoading;
    const setSnackbarMessage = props.setSnackbarMessage;
    const setOpenSnackbar = props.setOpenSnackbar;
    const setPaginationModel = props.setPaginationModel;
    const setOptions = props.setOptions;
    const initialized = useRef(false);
    const setActiveStatus = props.setActiveStatus;
    const setCompany = props.setCompany;

    const fields = [
        { name: "company", label: "Company", options: props.options.companies, state: props.company, setState: setCompany },
        { name: "activeStatus", label: "Active Status", options: props.options.activeStatuses, state: props.activeStatus, setState: setActiveStatus }
    ];

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setLoading(true);
        }
    }, [setData, setLoading, props.paginationModel]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setPaginationModel(paginationModel => ({ ...paginationModel, page: 0 }));
        }
    }, [props.company, props.activeStatus, search, setPaginationModel, setData]);

    useEffect(() => {
        if (!listening) {
            const eventSource = new EventSource(process.env.REACT_APP_API_URL + "/users/updates");

            eventSource.onmessage = event => {
                setUpdating(true);
            };

            return () => eventSource.close();
        }

        setListening(true);
    }, [listening, setUpdating]);

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + '/users?user_types=["standard"]&page=' + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (props.company) {
                url += "&company=" + encodeURIComponent(props.company);
            }

            if (props.activeStatus) {
                const active = props.activeStatus === "Active" ? "true" : "false";
                url += "&active=" + active;
            }

            if (search) {
                url += "&search=" + encodeURIComponent(search);
            }

            axiosInstance.get(url, config)
                .then(res => {
                    setData(res.data.entries);
                    setRowCountState(res.data.totalEntries);
                    setOptions(options => ({ ...options, companies: res.data.companyOptions, searches: res.data.searchOptions }));
                    setLoading(false);
                    setUpdating(false);
                    initialized.current = true;
                }).catch(err => {
                    setLoading(false);
                    setUpdating(false);
                    setSnackbarMessage("Unable to load user data");
                    setOpenSnackbar(true);
                });
        };

        if (props.loading || updating) {
            fetchData();
        }
    }, [props.loading, updating, setUpdating, userContext, setUserContext, props.paginationModel, setData, setRowCountState, props.company, props.activeStatus, search, setLoading, setSnackbarMessage, setOpenSnackbar, setOptions]);

    return (
        <DataFilter
            fields={fields}
            data={props.data}
            options={props.options}
            disableDates={true}
            search={search}
            setSearch={setSearch}
            enableSearch
        />
    );
};

export default UsersDataFilter;
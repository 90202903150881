import { Grid, Avatar, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { useContext, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackDialog from "../../Common/BackDialog";
import { axiosInstance } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const TopBar = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [anchorElement, setAnchorElement] = useState();
    const [openBackDialog, setOpenBackDialog] = useState(false);
    const openMenu = Boolean(anchorElement);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleOpenMenu = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorElement(null);
    };

    const handleLogOut = () => {
        const url = process.env.REACT_APP_API_URL + "/users/logout";
        const config = { withCredentials: true, headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.get(url, config)
            .then((res => {
                setUserContext({});
                window.localStorage.clear();
                window.localStorage.setItem("logout", Date.now());
                window.location = "/";
            }))
            .catch(err => { });
    };

    const handleClickBack = () => {
        if (props.modified) {
            setOpenBackDialog(true);
        } else {
            navigate("/main");
        }
    };

    return (
        <>
            {userContext.username &&
                <Grid item container wrap="nowrap" sx={{ alignItems: "center", justifyContent: "space-between", maxWidth: "1000px", padding: "25px 0px 25px 0px" }}>
                    <Grid container item flex={0.5}>
                        {props.enableBack &&
                            <Grid item>
                                <IconButton onClick={handleClickBack} sx={{ color: "#495464", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                        }
                    </Grid>
                    <Grid container item flex={2} alignItems="center" justifyContent="center">
                        <Grid item alignItems="center" sx={{ paddingLeft: 2.5, paddingRight: 2.5 }}>
                            <Typography variant="h5" align="center">{props.title}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item flex={0.5} justifyContent="flex-end">
                        <IconButton onClick={handleOpenMenu} sx={{ "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" } }}>
                            <Avatar sx={{ bgcolor: "transparent", color: "#495464", width: 31, height: 31, border: 2 }}>
                                {userContext.firstName ? userContext.firstName.substring(0, 1).toUpperCase() : userContext.username.substring(0, 1).toUpperCase()}
                            </Avatar>
                        </IconButton>
                        <Menu anchorEl={anchorElement} open={openMenu} onClose={handleCloseMenu}>
                            <MenuItem onClick={handleLogOut}>{t("topBar.signOut")}</MenuItem>
                        </Menu>
                    </Grid>
                    {openBackDialog && <BackDialog open={openBackDialog} setOpen={setOpenBackDialog} handleClose={() => navigate("/main")} />}
                </Grid>
            }
        </>
    );
};

export default TopBar;
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: { main: "#495464" },
        secondary: { main: "#e7ecef" },
        error: { main: "#d71313" },
        text: { primary: "#495464", secondary: "#bbbfca" },
        background: { default: "#e7ecef" }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#274c77",
                    color: "#e7ecef",
                    "&:hover": { backgroundColor: "#274c77" },
                    "&:disabled": { backgroundColor: "#bbbfca" },
                    paddingLeft: "25px",
                    paddingRight: "25px"
                },
                outlined: {
                    backgroundColor: "transparent",
                    color: "#274c77",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                    paddingLeft: "25px",
                    paddingRight: "25px"
                }
            }
        },
        MuiInputBase: { styleOverrides: { root: { color: "#000000" } } },
        MuiInputLabel: { styleOverrides: { root: { color: "#495464" } } },
        MuiAutocomplete: {
            styleOverrides: {
                paper: { background: "#274c77", color: "#e7ecef" },
                option: { "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6) !important" } }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
                }
            }
        },
        MuiInputAdornment: { styleOverrides: { root: { "& .MuiTypography-root": { color: "#495464" } } } },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    ".MuiDataGrid-overlayWrapperInner": {
                        height: "65vh !important"
                    },
                    ".MuiDataGrid-overlayWrapper": {
                        height: "65vh !important"
                    }
                }
            }
        },
        MuiPickersToolbar: {
            styleOverrides: {
                root: {
                    ".MuiButtonBase-root": {
                        backgroundColor: "#ffffff",
                        ".Mui-selected": {
                            backgroundColor: "rgba(42, 157, 143, 0.6)",
                            borderRadius: "5px",
                            padding: "3px"
                        },
                        "&:hover": {
                            backgroundColor: "transparent"
                        }
                    },
                    ".MuiTypography-root": {
                        color: "#274c77"
                    }
                }
            }
        },
        MuiPickersLayout: {
            styleOverrides: {
                contentWrapper: {
                    ".MuiClock-pin": {
                        backgroundColor: "#274c77"
                    },
                    ".MuiClockPointer-thumb": {
                        backgroundColor: "rgba(42, 157, 143, 0.6)",
                        border: "16px solid #96c3bc"
                    },
                    ".MuiClockPointer-root": {
                        backgroundColor: "#274c77"
                    },
                    ".MuiDayCalendar-weekContainer": {
                        ".Mui-selected": {
                            backgroundColor: "#274c77 !important",
                            "&:hover": {
                                backgroundColor: "#274c77 !important",
                            }
                        }
                    },
                    ".Mui-selected": {
                        backgroundColor: "#274c77"
                    }
                }
            }
        }
    }
});

export default theme;
import { Grid, TextField, Typography, FormControlLabel, Switch, Autocomplete, createFilterOptions, FormControl, RadioGroup, Radio } from "@mui/material";
import { useState } from "react";
import PasswordAdornment from "../../Common/PasswordAdornment";

const DetailsFields = props => {
    const filterOptions = createFilterOptions({ matchFrom: "start" });
    const headingParams = { variant: "body1", sx: { fontWeight: "bold" } };
    const gridItemParams = { sx: { width: "50%" } };
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: true, autoComplete: true, autoHighlight: true, filterOptions: filterOptions };
    const textFieldParams = { type: "text", fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value) };
    const [showPasssword, setShowPassword] = useState(false);

    const handleChange = (name, value) => props.setValues(pairs => ({ ...pairs, [name]: value }));

    return (
        <>
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography {...headingParams}>Personal Information</Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid item sx={{ width: "42.5%" }}>
                    <TextField {...textFieldParams} name="firstName" label="First name" value={props.values.firstName} />
                </Grid>
                <Grid item sx={{ width: "42.5%" }}>
                    <TextField {...textFieldParams} name="lastName" label="Last name" value={props.values.lastName} />
                </Grid>
                <Grid item sx={{ width: "15%" }}>
                    <TextField {...textFieldParams} name="middleInitial" label="M.I." value={props.values.middleInitial} />
                </Grid>
            </Grid>
            <br />
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography {...headingParams}>Company Information</Typography>
                </Grid>
            </Grid>
            <Grid container item {...gridItemParams}>
                <Autocomplete
                    {...autocompleteParams}
                    options={props.options.companies}
                    renderInput={params => (<TextField {...params} label="Company" />)}
                    onInputChange={(event, value) => handleChange("company", value)}
                    inputValue={String(props.values.company)}
                />
            </Grid>
            <br />
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography {...headingParams}>User Information</Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <TextField
                        {...textFieldParams}
                        name="username"
                        label="Username"
                        value={props.values.username}
                        disabled={props.disableUsername === true}
                    />
                </Grid>
                <Grid item {...gridItemParams}>
                    <TextField
                        {...textFieldParams}
                        type={!showPasssword ? "password" : "text"}
                        InputProps={{ endAdornment: <PasswordAdornment showPassword={showPasssword} setShowPassword={setShowPassword} /> }}
                        name="newPassword"
                        label="Password"
                        value={props.values.newPassword}
                    />
                </Grid>
            </Grid>
            <br />
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography {...headingParams}>Language</Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid item>
                    <FormControl>
                        <RadioGroup
                            value={props.values.language}
                            row
                            onChange={event => handleChange("language", event.target.value)}
                        >
                            <FormControlLabel value="en" control={<Radio />} label="English" sx={{ mr: 7 }} />
                            <FormControlLabel value="es" control={<Radio />} label="Spanish" sx={{ mr: 7 }} />
                            <FormControlLabel value="vi" control={<Radio />} label="Vietnamese" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography {...headingParams}>Active Status</Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography>
                        <FormControlLabel
                            checked={props.values.active}
                            control={<Switch />}
                            sx={{ margin: 0 }}
                            onChange={event => handleChange("active", event.target.checked)}
                        />
                        Active
                    </Typography>
                </Grid>
            </Grid>
            {props.showLockedStatus &&
                <>
                    <Grid container item spacing={2.5}>
                        <Grid item {...gridItemParams}>
                            <Typography {...headingParams}>Locked Status</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2.5}>
                        <Grid item {...gridItemParams}>
                            <Typography>
                                <FormControlLabel
                                    checked={props.values.locked}
                                    control={<Switch />}
                                    sx={{
                                        margin: 0,
                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                            color: "#bb3030",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                            backgroundColor: "#bb3030",
                                        }
                                    }}
                                    onChange={event => handleChange("locked", event.target.checked)}
                                />
                                Locked
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
};

export default DetailsFields;
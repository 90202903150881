import { Button, Grid, Autocomplete, TextField, List, ListItem, ListItemText, Typography, IconButton, Tooltip, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import FieldsOutline from "../../../Common/FieldsOutline";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import AddClientModalComponent from "../ModalModalComponents/AddClientModalComponent";
import AddProjectModalComponent from "../ModalModalComponents/AddProjectModalComponent";
import TheModal from "../../../Common/TheModal";

const ChooseComponent = props => {
    const [search, setSearch] = useState(props.lastSelectedProject.replace("Tender", "").replace("tender", "").trim());
    const headingParams = { variant: "body1", sx: { fontWeight: "bold" } };
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [selectedClient, setSelectedClient] = useState();
    const [selectedProject, setSelectedProject] = useState();
    const [addClient, setAddClient] = useState({ open: false, title: "New Client", subtitle: "Add a new client and project", modified: false, openPrevious: true });
    const [addProject, setAddProject] = useState({ open: false, title: "New Project", subtitle: "Add a new project", modified: false, openPrevious: true });
    const [loadingClients, setLoadingClients] = useState(false);

    const handleClickChange = event => {
        const updatedData = [...props.data];

        props.selectionIds.forEach(id => {
            const project = updatedData.find(entry => entry._id === id);

            if (project.paymoTimeEntryId !== undefined || project.paymoTimeEntryId !== null) {
                project.paymoProject = selectedProject.name;
                project.paymoProjectId = selectedProject.id;
            }
        });

        props.setData(updatedData);
        props.setChoose(choose => ({ ...choose, open: false }));
        props.setSnackbarMessage("Paymo projects changed");
        props.setOpenSnackbar(true);
    };

    const addClientModalComponent = <AddClientModalComponent
        addClient={addClient}
        setAddClient={setAddClient}
        lastSelectedProject={props.lastSelectedProject}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        data={props.data}
        setData={props.setData}
        selectionIds={props.selectionIds}
    />;

    const addProjectModalComponent = <AddProjectModalComponent
        addProject={addProject}
        setAddProject={setAddProject}
        client={selectedClient}
        lastSelectedProject={props.lastSelectedProject}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        data={props.data}
        setData={props.setData}
        selectionIds={props.selectionIds}
    />;

    useEffect(() => {
        const compareNames = (a, b) => {
            if (a.name > b.name) {
                return 1;
            } else if (a.name < b.name) {
                return -1;
            }

            return 0;
        };

        const fetchPaymoData = () => {
            setLoadingClients(true);

            let url = process.env.REACT_APP_PAYMO_API_URL + "/clients?where=active=true";
            const config = { headers: { Authorization: `Basic ${btoa(process.env.REACT_APP_PAYMO_API_KEY + ":")}` } };

            axios.get(url, config)
                .then(res => {
                    res.data.clients.sort(compareNames);
                    setClients(res.data.clients);
                    setLoadingClients(false);

                    url = process.env.REACT_APP_PAYMO_API_URL + "/projects?where=active=true";

                    axios.get(url, config)
                        .then(res => {
                            res.data.projects.sort(compareNames);
                            setProjects(res.data.projects);
                        })
                        .catch(err => {
                            if (err.response.status === 429) {
                                setTimeout(() => {
                                    axios.get(url, config)
                                        .then(res => {
                                            res.data.projects.sort(compareNames);
                                            setProjects(res.data.projects);
                                        })
                                        .catch(err => {
                                            // Do nothing
                                        });
                                }, 4000);
                            }
                        });
                })
                .catch(err => {
                    setLoadingClients(false);
                });
        };

        fetchPaymoData();
    }, []);

    useEffect(() => {
        if (selectedClient) {
            const projectsToShow = projects.filter(project => project.client_id === selectedClient?.id);
            setFilteredProjects(projectsToShow);
        } else {
            setFilteredProjects([]);
        }
    }, [selectedClient, projects]);

    const handleSelectClient = client => {
        setSelectedClient(client);
    };

    const handleSelectProject = project => {
        setSelectedProject(project);
    };

    useEffect(() => {
        setFilteredProjects([]);
        setSelectedClient();
        setSelectedProject();

        if (search) {
            const lowerCaseSearch = search.toLowerCase();
            const clientsToShow = clients.filter(client => client.name.toLowerCase().indexOf(lowerCaseSearch) >= 0);
            setFilteredClients(clientsToShow);
        } else {
            setFilteredClients(clients);
        }
    }, [clients, search]);

    const setChoose = props.setChoose;

    useEffect(() => {
        if (!addClient.openPrevious || !addProject.openPrevious) {
            setChoose(choose => ({ ...choose, open: false, modified: false }));
        }
    }, [addClient.openPrevious, addProject.openPrevious, setChoose]);

    return (
        <Grid container direction="column" spacing={2.5}>
            <Grid container item>
                <FieldsOutline title="Selection">
                    <Grid container item alignItems="center">
                        <Grid item flex={1}>
                            <Typography {...headingParams}>Clients</Typography>
                        </Grid>
                        <Grid item justifyItems="flex-end">
                            <Tooltip title="Add a new client and project" placement="left">
                                <IconButton onClick={() => setAddClient(addClient => ({ ...addClient, open: true }))}><AddIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item flex={1} sx={{ width: "60%", mb: 1 }}>
                        <Autocomplete
                            options={[]}
                            renderInput={params => (<TextField {...params} label="Search clients" />)}
                            onInputChange={(event, value) => setSearch(value)}
                            value={search}
                            freeSolo
                            sx={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid container item>
                        <List
                            sx={{
                                width: "100%",
                                border: "1px solid rgba(0, 0, 0, 0.2)",
                                position: "relative",
                                overflow: "auto",
                                height: 350,
                            }}
                        >
                            {loadingClients ?
                                <Grid container item justifyContent="center" alignItems="center" sx={{ width: "100%", height: 350 }}>
                                    <CircularProgress />
                                </Grid>
                                :
                                filteredClients.length === 0 ?
                                    <Grid container item justifyContent="center" alignItems="center" sx={{ width: "100%", height: 350 }}>
                                        <Typography>No clients found</Typography>
                                    </Grid>
                                    :
                                    filteredClients.map(client => (
                                        <ListItem
                                            key={client.id}
                                            onClick={() => handleSelectClient(client)}
                                            selected={selectedClient?.id === client.id}
                                            sx={{
                                                "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.2)", cursor: "pointer" },
                                                "&.Mui-selected": {
                                                    "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.4)" },
                                                    backgroundColor: "rgba(42, 157, 143, 0.4)"
                                                }
                                            }}
                                        >
                                            <ListItemText primary={client.name} />
                                        </ListItem>
                                    ))
                            }
                        </List>
                    </Grid>
                    <br />
                    <Grid container item alignItems="center">
                        <Grid item flex={1}>
                            <Typography {...headingParams}>Projects</Typography>
                        </Grid>
                        <Grid item justifyItems="flex-end">
                            {selectedClient &&
                                <Tooltip title="Add a new project" placement="left">
                                    <IconButton onClick={() => setAddProject(addProject => ({ ...addProject, open: true }))}><AddIcon /></IconButton>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        <List
                            sx={{
                                width: "100%",
                                border: "1px solid rgba(0, 0, 0, 0.2)",
                                position: "relative",
                                overflow: "auto",
                                height: 350,
                            }}
                        >
                            {
                                selectedClient && filteredProjects.length === 0 ?
                                    <Grid container item justifyContent="center" alignItems="center" sx={{ width: "100%", height: 350 }}>
                                        <Typography>No active projects found</Typography>
                                    </Grid>
                                    :
                                    filteredProjects.map(project => (
                                        <ListItem
                                            key={project.id}
                                            onClick={() => handleSelectProject(project)}
                                            selected={selectedProject?.id === project.id}
                                            sx={{
                                                "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.2)", cursor: "pointer" },
                                                "&.Mui-selected": {
                                                    "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.4)" },
                                                    backgroundColor: "rgba(42, 157, 143, 0.4)"
                                                }
                                            }}
                                        >
                                            <ListItemText primary={project.name} />
                                        </ListItem>
                                    ))}
                        </List>
                    </Grid>
                    <br />
                </FieldsOutline>
            </Grid>
            <Grid container item justifyContent="flex-end">
                <Grid item sx={{ mr: 2.5 }}>
                    <Button variant="outlined" onClick={() => props.setChoose({ ...props.choose, open: false, modified: false })}>Cancel</Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={handleClickChange}
                        disabled={!selectedProject}
                        sx={{ backgroundColor: "#6096ba", color: "#e7ecef", "&:hover": { backgroundColor: "#6096ba" } }}
                    >
                        Change
                    </Button>
                </Grid>
            </Grid>
            {addClient.open && <TheModal task={addClient} setTask={setAddClient} modalComponent={addClientModalComponent} enableBack />}
            {addProject.open && <TheModal task={addProject} setTask={setAddProject} modalComponent={addProjectModalComponent} enableBack />}
        </Grid >
    );
};

export default ChooseComponent;
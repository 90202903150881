import { useState, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import DataTable from "../../DataTable/DataTable";
import DataTableFooter from "../../DataTable/TableComponents/DataTableFooter";
import DataTableToolbar from "../../DataTable/TableComponents/DataTableToolbar";
import TotalEntriesAndHours from "../../DataTable/TableComponents/TotalEntriesAndHours";
import { Tooltip, Link } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid";
import TimesheetsToolbar from "./TimesheetsToolbar";
import TableSummary from "../../DataTable/TableComponents/TableSummary";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const TimesheetsTable = props => {
    const [edit, setEdit] = useState({ open: false, title: "Edit time", subtitle: "Modify the selected time entry", _id: "", modified: false });
    const [selectionIds, setSelectionIds] = useState([]);
    const rows = useMemo(() => props.data, [props.data]);
    const apiRef = useGridApiRef();

    const setProject = props.setProject;
    const setAreas = props.setAreas;
    const setPersons = props.setPersons;

    const columns = useMemo(() => {
        const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };

        const handleClick = (event, field, value) => {
            event.stopPropagation();

            if (field === "project") {
                setProject(value);
            } else if (field === "area") {
                setAreas([value]);
            } else if (field === "person") {
                setPersons([value]);
            }
        };

        return [
            {
                field: "fullName",
                headerName: "Name",
                minWidth: 100,
                flex: 1.4,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "person", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "company",
                headerName: "Company",
                minWidth: 100,
                flex: 1.4,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "date",
                headerName: "Date",
                minWidth: 110,
                flex: 0.5,
                valueGetter: params => dayjs(params.value).format("YYYY-MM-DD"),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "project",
                headerName: "Project",
                minWidth: 105,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "project", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "description",
                headerName: "Description",
                minWidth: 105,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "area",
                headerName: "Area",
                minWidth: 105,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "area", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "startDate",
                headerName: "Start",
                minWidth: 90,
                flex: 0.5,
                valueGetter: params => dayjs(params.value).format("hh:mm a"),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "endDate",
                headerName: "End",
                minWidth: 90,
                flex: 0.5,
                valueGetter: params => dayjs(params.value).format("hh:mm a"),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "breakMinutes",
                headerName: "Break",
                type: "number",
                minWidth: 95,
                flex: 1.3,
                renderCell: params => <Tooltip title={params.value + " min"}><div {...cellParams}>{params.value + " min"}</div></Tooltip>
            },
            {
                field: "hours",
                headerName: "Hours",
                type: "number",
                minWidth: 65,
                flex: 1.3,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "paymoTimeEntryId",
                headerName: "Synced",
                minWidth: 75,
                flex: 0.8,
                headerAlign: "center",
                renderCell: params => {
                    return (params.value ?
                        <div style={{ ...cellParams.style, display: "flex", width: "100%", justifyContent: "center" }}>
                            <CheckCircleIcon fontSize="small" sx={{ color: "rgba(42, 157, 143, 0.6)" }} />
                        </div>
                        :
                        null
                    );
                }
            },
            {
                field: "paymoProject",
                headerName: "Paymo Project",
                minWidth: 105,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}>
                    <div style={{ ...cellParams.style, color: params.row.paymoTimeEntryId ? null : "#e76f51" }}>
                        {params.row.paymoTimeEntryId === -1 ? "Archive" : null}
                        {params.row.paymoTimeEntryId === -2 ? "Skipped" : null}
                        {params.row.paymoTimeEntryId >= 0 ? params.value : null}
                        {params.row.paymoProjectId ? params.value : null}
                    </div>
                </Tooltip>
            }
        ];
    }, [setAreas, setPersons, setProject]);

    const CustomFooter = useCallback(() => { return (<DataTableFooter rows={rows} />) }, [rows]);

    const CustomToolbar = useCallback(() => {
        const totalComponent = <TotalEntriesAndHours totalEntries={props.rowCountState} totalHours={props.totalHours} />;
        const toolbarComponent = <TableSummary totalComponent={totalComponent} title="Timesheets" columns={columns} />;

        return (<DataTableToolbar toolbarComponent={toolbarComponent} />)
    }, [props.rowCountState, props.totalHours, columns]);

    const handlePageChange = useCallback(newPaginationModel => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("timesheetsTablePageSize", newPaginationModel.pageSize);

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    }, [props]);

    const handleRowClick = useCallback(row => {
        setEdit(edit => ({ ...edit, open: true, _id: row._id }));
    }, []);

    return (
        <>
            <div style={{ backgroundColor: "#e7ecef", position: "sticky", top: -20, padding: "10px", zIndex: 1 }}>
                <TimesheetsToolbar
                    apiRef={apiRef}
                    selectionIds={selectionIds}
                    setSelectionIds={setSelectionIds}
                    edit={edit}
                    setEdit={setEdit}
                    options={props.options}
                    setSnackbarMessage={props.setSnackbarMessage}
                    setOpenSnackbar={props.setOpenSnackbar}
                    rowCountState={props.rowCountState}
                    totalHours={props.totalHours}
                    data={props.data}
                    setData={props.setData}
                    syncStatus={props.syncStatus}
                    setLoading={props.setLoading}
                    setRightSidebar={props.setRightSidebar}
                    disableModal={props.disableModal}
                />
            </div>
            <div>
                <DataTable
                    columns={columns}
                    rows={rows}
                    customFooter={CustomFooter}
                    customToolbar={CustomToolbar}
                    paginationMode="server"
                    paginationModel={props.paginationModel}
                    handlePageChange={handlePageChange}
                    handleRowClick={handleRowClick}
                    rowCountState={props.rowCountState}
                    loading={props.loading}
                    setSelectionIds={setSelectionIds}
                    apiRef={apiRef}
                />
            </div>
        </>
    );
};

export default TimesheetsTable;
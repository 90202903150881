import { useState, useEffect, useContext, useRef } from "react";
import DataFilter from "../DataTable/DataFilter.js";
import dayjs from "dayjs";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils.js";

const CreateReportDataFilter = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [dates, setDates] = useState({
        startDate: userContext.createReportDataFilterStartDate !== undefined ? dayjs(userContext.createReportDataFilterStartDate) : dayjs(new Date()).subtract(1, "month"),
        endDate: userContext.createReportDataFilterEndDate !== undefined ? dayjs(userContext.createReportDataFilterEndDate) : dayjs(new Date())
    });
    const [fields, setFields] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const [personOptions, setPersonOptions] = useState([]);
    const initialized = useRef(false);

    const setData = props.setData;
    const setRowCountState = props.setRowCountState;
    const setTotalHours = props.setTotalHours;
    const setLoading = props.setLoading;
    const setSnackbarMessage = props.setSnackbarMessage;
    const setOpenSnackbar = props.setOpenSnackbar;
    const setPaginationModel = props.setPaginationModel;
    const setTitle = props.setTitle;
    const setProject = props.setProject;
    const setAreas = props.setAreas;
    const setPersons = props.setPersons;

    useEffect(() => {
        if (dates.startDate) {
            setUserContext(userContext => ({ ...userContext, createReportDataFilterStartDate: dates.startDate }));
        }

        if (dates.endDate) {
            setUserContext(userContext => ({ ...userContext, createReportDataFilterEndDate: dates.endDate }));
        }
    }, [dates, setUserContext]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setLoading(true);
        }
    }, [setData, setLoading, props.paginationModel]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setPaginationModel(paginationModel => ({ ...paginationModel, page: 0 }));
        }
    }, [props.persons, setPaginationModel, setData]);

    useEffect(() => {
        if (initialized.current) {
            setPersons([]);
        }
    }, [props.areas, setPersons]);

    useEffect(() => {
        if (initialized.current) {
            setAreas([]);
        }
    }, [props.project, selectedDates, setAreas]);

    useEffect(() => {
        if (initialized.current) {
            setSelectedDates([]);
        }
    }, [dates]);

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + "/timesheets";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            let query = "";
            let title = "";

            if (Date.parse(dates.startDate) && Date.parse(dates.endDate)) {
                const startDate = new Date(dates.startDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                const endDate = new Date(dates.endDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                query += "&start_date=" + startDate + "&end_date=" + endDate;
            }

            if (props.paginationModel) {
                query += "&page=" + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;
            }

            if (props.project) {
                query += "&project=" + encodeURIComponent(props.project);
                title += props.project;
            }

            if (props.areas.length > 0) {
                query += "&areas=[";

                props.areas.forEach(area => {
                    query += '"' + encodeURIComponent(area) + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            if (props.persons.length > 0) {
                query += "&persons=[";

                props.persons.forEach(person => {
                    query += '"' + encodeURIComponent(person) + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            if (selectedDates.length > 0) {
                query += "&selected_dates=[";

                selectedDates.forEach(date => {
                    query += '"' + date + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            if (query !== "") {
                url += "?" + query.slice(1);
            }

            if (title !== "") {
                title += " - Time";
            } else {
                title = "Time"
            }

            setTitle(title);

            axiosInstance.get(url, config)
                .then(res => {
                    const fetchedData = res.data.entries.map(entry => ({ ...entry, date: entry.startDate }));
                    setData(fetchedData);
                    setRowCountState(res.data.totalEntries);
                    setTotalHours(res.data.totalHours);
                    setProjectOptions(res.data.projectOptions);

                    if (props.areas.length === 0) {
                        setAreaOptions(res.data.areaOptions);
                    }

                    if (props.persons.length === 0) {
                        setPersonOptions(res.data.personOptions);
                    }

                    setLoading(false);
                    initialized.current = true;
                })
                .catch(err => {
                    setLoading(false);
                    setSnackbarMessage("Unable to load timesheet data");
                    setOpenSnackbar(true);
                });
        };

        if (props.loading) {
            fetchData();
        }
    }, [props.loading, dates, userContext, setUserContext, props.paginationModel, props.project, props.persons, props.areas, setData, setRowCountState, setTotalHours, setLoading, setSnackbarMessage, setOpenSnackbar, setTitle, selectedDates]);

    useEffect(() => {
        const updateFields = () => {
            setFields([
                { name: "project", label: "Project", options: projectOptions, state: props.project, setState: setProject },
                { name: "area", label: "Area", options: areaOptions, state: props.areas, setState: setAreas, multiple: true },
                { name: "person", label: "Person", options: personOptions, state: props.persons, setState: setPersons, multiple: true }
            ]);
        };

        updateFields();
    }, [areaOptions, props.areas, projectOptions, props.project, personOptions, props.persons, setProject, setPersons, setAreas]);

    return (
        <DataFilter
            fields={fields}
            setData={props.setData}
            dates={dates}
            setDates={setDates}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            data={props.data}
            setTitle={props.setTitle}
            enableDateSelection={true}
        />
    );
};

export default CreateReportDataFilter;
import { Button, Grid, Typography, Paper, FormControlLabel, Checkbox } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import areas from "../../../Lists/areas.js";
import { useTranslation } from "react-i18next";

const AreaComponent = props => {
    const [checkedArea, setCheckedArea] = useState({ _id: null, text: "" });
    const { t } = useTranslation();
    const [selection, setSelection] = useState(null);
    const ref = useRef();

    const handleSelect = (area, index) => {
        if (checkedArea._id === area._id) {
            setCheckedArea({ _id: null, text: "" });
            setSelection(null);
        } else {
            setCheckedArea({ _id: area._id, text: area.text });
            setSelection(index);
        }
    };

    useEffect(() => {
        if (selection !== null) {
            ref.current.scrollIntoView();
        }
    }, [selection]);

    const handleDone = () => {
        props.setDetails(details => ({ ...details, area: checkedArea.text }));
        props.setOpen(false);
    };

    return (
        <>
            <Grid container item alignItems="center" justifyContent="center" sx={{ paddingBottom: "25px" }}>
                <Typography variant="h5">{areas[props.area].title}</Typography>
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">{t(areas[props.area].subtitle)}</Typography>
            </Grid>
            <Grid container direction="column">
                <Grid container item>
                    {areas[props.area].areas.map((area, index) => {
                        return (
                            <Paper
                                key={area._id}
                                ref={selection === index ? ref : null}
                                sx={{
                                    backgroundColor: "white",
                                    border: "1px solid rgba(0, 0, 0, 0.2)",
                                    minHeight: "160px",
                                    padding: "12px",
                                    marginBottom: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    boxShadow: "none",
                                    width: "100%",
                                    cursor: "pointer",
                                    scrollMargin: "5px"
                                }}
                                onClick={event => handleSelect(area, index)}
                            >
                                <Grid container direction={checkedArea._id === area._id ? "column" : "row"} alignContent="center" sx={{ width: "100%" }}>
                                    <Grid container item xs={checkedArea._id === area._id ? null : 7} sx={{ mb: checkedArea._id === area._id ? "15px" : 0, pr: checkedArea._id === area._id ? 0 : "20px" }}>
                                        <Grid item xs={1} alignContent="center">
                                            <FormControlLabel
                                                checked={checkedArea._id === area._id}
                                                control={<Checkbox />}
                                            />
                                        </Grid>
                                        <Grid item xs={11} alignContent="center" sx={{ pl: "20px" }}>
                                            <Typography style={{ color: "#495464", whiteSpace: "pre-line", width: "fit-content", fontSize: "17px" }}>
                                                {area.text}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={checkedArea._id === area._id ? null : 5} alignContent="center" justifyContent="flex-end">
                                        <img src={area.image} alt="" width="100%" />
                                    </Grid>
                                </Grid>
                            </Paper>
                        )
                    })}
                </Grid>
                <Grid container item justifyContent="center">
                    <Button
                        disabled={!checkedArea._id}
                        onClick={handleDone}
                        sx={{
                            width: "100%",
                            marginTop: "20px",
                            height: "45px"
                        }}>
                        {t("chooseArea.done")}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default AreaComponent;
import { Grid } from "@mui/material";
import Title from "../../components/Common/Title";
import Greeting from "../../components/Dashboard/Greeting";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import MonthlyHoursChart from "../../components/Dashboard/MonthlyHoursChart";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const Dashboard = () => {
    const cardGridParams = { sx: { width: "33.3%" } };
    const startDate = new Date(new Date(new Date().toDateString()).getTime() - (86400000 * 7)).toISOString().slice(0, 10);
    const endDate = new Date(new Date().toDateString()).toISOString().slice(0, 10);
    const cardHeight = "30vh";
    const [, setRightSidebar] = useOutletContext();

    useEffect(() => {
        setRightSidebar({ open: false });
    }, [setRightSidebar]);

    return (
        <div>
            <Title title="Dashboard" />
            <Grid container spacing={2.5} sx={{ width: "100%" }}>
                <Grid container item><Greeting /></Grid>
                <Grid container item spacing={5}>
                    <Grid item {...cardGridParams}>
                        <DashboardCard
                            heading="PROJECTS"
                            description="Projects this week"
                            url={process.env.REACT_APP_API_URL + "/timesheets/totalprojects?start_date=" + startDate + "&end_date=" + endDate}
                            height={cardHeight}
                        />
                    </Grid>
                    <Grid item {...cardGridParams}>
                        <DashboardCard
                            heading="EMPLOYEES"
                            description="Employees this week"
                            url={process.env.REACT_APP_API_URL + "/timesheets/totalemployees?start_date=" + startDate + "&end_date=" + endDate}
                            height={cardHeight}
                        />
                    </Grid>
                    <Grid item {...cardGridParams}>
                        <DashboardCard
                            heading="HOURS"
                            description="Hours worked this week"
                            url={process.env.REACT_APP_API_URL + "/timesheets/totalhours?start_date=" + startDate + "&end_date=" + endDate}
                            height={cardHeight}
                        />
                    </Grid>
                </Grid>
                <Grid container item sx={{ minHeight: "475px", height: "45vh" }}><MonthlyHoursChart /></Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
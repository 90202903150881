import { Grid, Autocomplete, TextField, IconButton } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { LocalizationProvider, DatePicker, PickersDay } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import dayjs from "dayjs";
import { axiosInstance } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const TimesheetsDataTable = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [anchorElement, setAnchorElement] = useState();
    const [selectedDates, setSelectedDates] = useState(userContext.mainSelectedDates ? userContext.mainSelectedDates : []);
    const [options, setOptions] = useState({ searches: [] });
    const [search, setSearch] = useState(userContext.mainSearch ? userContext.mainSearch : "");
    const { t } = useTranslation();

    const setData = props.setData;
    const setRowCountState = props.setRowCountState;
    const setSnackbarMessage = props.setSnackbarMessage;
    const setOpenSnackbar = props.setOpenSnackbar;
    const setLoading = props.setLoading;

    const Day = dayProps => {
        const { day, outsideCurrentMonth, ...other } = dayProps;
        const isSelected = !outsideCurrentMonth && selectedDates.includes(day.format("YYYY-MM-DD"));

        return (<PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} selected={isSelected} />);
    };

    const handleChange = value => {
        if (selectedDates.includes(value.format("YYYY-MM-DD"))) {
            const updatedDates = selectedDates.filter(date => date !== value.format("YYYY-MM-DD"));
            setSelectedDates(updatedDates);
        } else {
            setSelectedDates([...selectedDates, value.format("YYYY-MM-DD")]);
        }
    };

    useEffect(() => {
        setUserContext(userContext => ({ ...userContext, mainSelectedDates: selectedDates }));
    }, [selectedDates, setUserContext]);

    useEffect(() => {
        setUserContext(userContext => ({ ...userContext, mainSearch: search }));
    }, [setUserContext, search]);

    const handleOpenMenu = event => {
        setAnchorElement(event.currentTarget);
        setOpenCalendar(true);
    };

    const handleClear = () => {
        setSelectedDates([]);
        setOpenCalendar(false);
    };

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + "/timesheets";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            let query = "";

            if (props.paginationModel) {
                query += "&page=" + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;
            }

            if (search) {
                query += "&search=" + encodeURIComponent(search);
            }

            if (selectedDates.length > 0) {
                query += "&selected_dates=[";

                selectedDates.forEach(date => {
                    query += '"' + date + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            if (query !== "") {
                url += "?" + query.slice(1);
            }

            axiosInstance.get(url, config)
                .then(res => {
                    setData(res.data.entries);
                    setRowCountState(res.data.totalEntries);
                    setLoading(false);
                })
                .catch(err => {
                    setSnackbarMessage(t("main.errorMessage"));
                    setOpenSnackbar(true);
                });
        };

        if (props.loading) {
            fetchData();
        }
    }, [props.loading, userContext, setUserContext, props.paginationModel, setLoading, setData, setRowCountState, setSnackbarMessage, setOpenSnackbar, setOptions, search, selectedDates, t]);

    useEffect(() => {
        setLoading(true);
    }, [setLoading, selectedDates, search, props.paginationModel]);

    return (
        <Grid container item direction="row" spacing={2.5} sx={{ justifyContent: "center", alignItems: "center" }}>
            <Grid item flex={1} sx={{ maxWidth: "365px" }}>
                <Autocomplete
                    options={options.searches}
                    renderInput={params => (<TextField {...params} label={t("main.search")} />)}
                    onChange={(event, value) => setSearch(value)}
                    value={search ? search : null}
                    freeSolo
                    sx={{ width: "100%" }}
                    blurOnSelect
                />
            </Grid>
            <Grid item justifyItems="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <IconButton onClick={handleOpenMenu} sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                        <DateRangeIcon sx={{ width: 33, height: 33, color: "#495464" }} />
                    </IconButton>
                    <DatePicker
                        onChange={handleChange}
                        open={openCalendar}
                        onClose={() => setOpenCalendar(false)}
                        slots={{ day: Day }}
                        slotProps={{
                            textField: { sx: { display: "none" } },
                            popper: { anchorEl: anchorElement },
                            actionBar: { actions: ["clear", "accept"], onAccept: () => setOpenCalendar(false), onClear: handleClear }
                        }}
                        closeOnSelect={false}
                        maxDate={dayjs(new Date())}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>
    );
};

export default TimesheetsDataTable;
import { Button, IconButton, Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import FieldsOutline from "../../../Common/FieldsOutline";
import DetailsFields from "../Fields/DetailsFields";
import DeleteEntryDialog from "../../../Common/DeleteEntryDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorBox from "../../../Common/ErrorBox";
import { combineDateTime } from "../../../../utils/utils";
import { UserContext } from "../../../../context/UserContext";
import { axiosInstance } from "../../../../utils/utils";

const EditModalComponent = props => {
    const [entry, setEntry] = useState({
        _id: "",
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        project: "",
        area: "",
        description: "",
        breakMinutes: "",
        person: "",
        userId: ""
    });
    const [disableButton, setDisableButton] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);
    const [openDelete, setOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const loaded = useRef(false);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const startDate = combineDateTime(entry.startDate, entry.startTime);
        const endDate = combineDateTime(entry.endDate, entry.endTime);

        const url = process.env.REACT_APP_API_URL + "/timesheets/" + props.edit._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const body = { ...entry, startDate: startDate, endDate: endDate };
        delete body["startTime"];
        delete body["endTime"];

        axiosInstance.post(url, body, config)
            .then(res => {
                props.setEdit({ ...props.edit, "open": false });
                props.setSnackbarMessage("Time entry updated");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_URL + "/timesheets/" + props.edit._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.delete(url, config)
            .then(res => {
                props.setEdit({ ...props.edit, "open": false });
                props.setSnackbarMessage("Time entry deleted");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    useEffect(() => {
        const fetchEntry = () => {
            const url = process.env.REACT_APP_API_URL + '/timesheets?ids=["' + props.edit._id + '"]';
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setEntry({
                        _id: res.data[0]._id,
                        startDate: dayjs(res.data[0].startDate),
                        startTime: dayjs(res.data[0].startDate),
                        endDate: dayjs(res.data[0].endDate),
                        endTime: dayjs(res.data[0].endDate),
                        project: res.data[0].project,
                        area: res.data[0].area,
                        description: res.data[0].description,
                        breakMinutes: res.data[0].breakMinutes,
                        person: res.data[0].fullName,
                        userId: res.data[0].userId,
                        paymoTimeEntryId: res.data[0].paymoTimeEntryId
                    });
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchEntry();
    }, [props.edit._id, userContext, setUserContext]);

    const setEdit = props.setEdit;

    useEffect(() => {
        const fieldsNotEmpty = () => {
            const startDate = dayjs(combineDateTime(entry.startDate, entry.startTime));
            const endDate = dayjs(combineDateTime(entry.endDate, entry.endTime));
            const validDateRange = startDate.isBefore(endDate);

            const requiredFields = { ...entry };
            delete requiredFields.paymoTimeEntryId;
            delete requiredFields.area;

            return Object.values(requiredFields).every(value => value !== "" && value !== undefined && value !== null) && !isNaN(entry.breakMinutes) && validDateRange;
        };

        if (!isLoading) {
            if (loaded.current) {
                setEdit(edit => ({ ...edit, modified: true }));
            } else {
                loaded.current = true;
            }
        }

        setDisableButton(!fieldsNotEmpty());
    }, [entry, isLoading, setEdit]);

    useEffect(() => setIsLoading(entry._id === ""), [entry]);

    return (!isLoading &&
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields
                            values={entry}
                            setValues={setEntry}
                            options={props.options}
                            users={props.users}
                            disablePerson
                            enablePaymo
                            setErrorMessage={setErrorMessage}
                            setShowErrorMessage={setShowErrorMessage}
                            setSnackbarMessage={props.setSnackbarMessage}
                            setOpenSnackbar={props.setOpenSnackbar}
                            entries={[entry]}
                            loaded={loaded}
                            setTask={props.setEdit}
                        />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item>
                    <Grid container item xs={6} alignItems="center">
                        <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                        <Grid item sx={{ mr: 2.5 }}>
                            <Button variant="outlined" onClick={() => props.setEdit({ ...props.edit, open: false, modified: false })}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                disabled={disableButton}
                                sx={{ backgroundColor: "rgba(42, 157, 143, 0.6)", color: "#495464", "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" } }}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openDelete && <DeleteEntryDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} />}
        </form>
    );
};

export default EditModalComponent;
import { Typography, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import CloseDialog from "../../Common/CloseDialog";

const RightSidebar = props => {
    const [openCloseDialog, setOpenCloseDialog] = useState(false);

    const handleCloseWithChange = () => {
        if (props.rightSidebar?.task?.modified) {
            setOpenCloseDialog(true);
        } else {
            props.rightSidebar.setTask(add => ({ ...add, open: false }));
        }
    };

    const handleClose = () => props.rightSidebar.setTask(add => ({ ...add, open: false, modified: false }));

    return (
        <div style={{ width: props.rightSidebar.task?.open ? props.rightSidebarWidth : "0px", transition: props.transition }}>
            <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 0" }}>
                <IconButton onClick={handleCloseWithChange}><CloseIcon /></IconButton>
            </Grid>
            <div style={{ padding: "0% 6% 6% 6%" }}>
                <Typography variant="h5" sx={{ mb: 0.5 }}>{props.rightSidebar?.task?.title}</Typography>
                <Typography variant="body1" sx={{ mb: 2.5, color: "rgba(49, 54, 64, 0.6)" }}>{props.rightSidebar?.task?.subtitle}</Typography>
                {props.rightSidebar.component}
                {openCloseDialog && <CloseDialog open={openCloseDialog} setOpen={setOpenCloseDialog} handleClose={handleClose} />}
            </div>
        </div>
    );
};

export default RightSidebar;
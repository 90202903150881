import DataFilter from "../../DataTable/DataFilter.js";
import { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../../context/UserContext.js";
import dayjs from "dayjs";
import { axiosInstance } from "../../../utils/utils.js";

const MissingTimesheetsDataFilter = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const initialized = useRef(false);
    const [updating, setUpdating] = useState(true);
    const [selectedDates, setSelectedDates] = useState([dayjs().subtract(1, "day")]);

    const setData = props.setData;
    const setRowCountState = props.setRowCountState;
    const setLoading = props.setLoading;
    const setSnackbarMessage = props.setSnackbarMessage;
    const setOpenSnackbar = props.setOpenSnackbar;
    const setPaginationModel = props.setPaginationModel;
    const setOptions = props.setOptions;
    const setTitle = props.setTitle;

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setLoading(true);
        }
    }, [setData, setLoading, props.paginationModel]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setPaginationModel(paginationModel => ({ ...paginationModel, page: 0 }));
        }
    }, [selectedDates, setPaginationModel, setData]);

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + "/users";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            let query = "missing=true";

            if (props.paginationModel) {
                query += "&page=" + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;
            }

            if (selectedDates.length > 0) {
                query += "&selected_dates=[";

                selectedDates.forEach(date => {
                    query += '"' + date + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            url += "?" + query;

            let title = "Missing Timesheets";

            if (selectedDates.length > 0) {
                title += " - " + dayjs(selectedDates[0]).format("MMMM DD, YYYY");
            }

            setTitle(title);

            axiosInstance.get(url, config)
                .then(res => {
                    let sortedEntries = res.data.entries.sort((a, b) => {
                        const lowerA = a.firstName.toLowerCase();
                        const lowerB = b.firstName.toLowerCase();
                        return lowerA < lowerB ? -1 : lowerA > lowerB ? 1 : 0;
                    });
                    setData(sortedEntries);
                    setRowCountState(res.data.totalEntries);
                    setLoading(false);
                    setUpdating(false);
                    initialized.current = true;
                })
                .catch(err => {
                    setLoading(false);
                    setUpdating(false);
                    setSnackbarMessage("Unable to load user data");
                    setOpenSnackbar(true);
                });
        };

        if (props.loading || updating) {
            fetchData();
        }
    }, [props.loading, updating, setUpdating, selectedDates, userContext, setUserContext, props.paginationModel, setData, setRowCountState, setLoading, setSnackbarMessage, setOpenSnackbar, setOptions, setTitle]);

    return (
        <DataFilter
            data={props.data}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            disableMultipleSelectedDates
            enableDateSelection={true}
            disableDates
        />
    );
};

export default MissingTimesheetsDataFilter;
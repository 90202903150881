import { Modal, Paper, Typography, Grid, IconButton } from "@mui/material";
import CloseDialog from "./CloseDialog";
import { useState, useEffect, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack.js";

const TheModal = props => {
    const [openCloseDialog, setOpenCloseDialog] = useState(false);
    const [clickedBack, setClickedBack] = useState(null);

    const handleCloseWithChange = useCallback(() => {
        if (props.task.modified) {
            setOpenCloseDialog(true);
        } else {
            if (!props.task.openPrevious) {
                props.setTask({ ...props.task, open: false });
            } else {
                props.setTask({ ...props.task, open: false, openPrevious: clickedBack });
            }
        }
    }, [clickedBack, props]);

    const handleClose = () => {
        if (!props.task.openPrevious) {
            props.setTask({ ...props.task, open: false, modified: false });
        } else {
            props.setTask({ ...props.task, open: false, modified: false, openPrevious: clickedBack });
        }
    };

    const handleClickBack = () => {
        setClickedBack(true);
    };

    useEffect(() => {
        if (clickedBack !== null) {
            handleCloseWithChange();
        }
    }, [clickedBack, handleCloseWithChange]);

    useEffect(() => {
        if (!openCloseDialog) {
            setClickedBack(null);
        }
    }, [openCloseDialog]);

    return (
        <Modal open={props.task.open} onClose={handleCloseWithChange} sx={{ "& .MuiModal-backdrop": { display: props.enableBack ? "none" : null } }}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: props.width ? props.width : "65%",
                    height: props.height ? props.height : "90%",
                    maxWidth: 900,
                    bgcolor: "#e7ecef",
                    overflow: "auto"
                }}
            >
                {!props.enableBack ?
                    <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 0" }}>
                        <IconButton onClick={handleCloseWithChange}><CloseIcon /></IconButton>
                    </Grid>
                    :
                    <Grid container direction="row" sx={{ padding: "2.5% 2.5% 2.5% 2.5%" }}>
                        <Grid container item xs={6}>
                            <IconButton onClick={handleClickBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid container item xs={6} justifyContent="flex-end">
                            <IconButton onClick={handleCloseWithChange}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                }
                <div style={{ padding: "0% 6% 6% 6%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>{props.task.title}</Typography>
                    <Typography variant="body1" sx={{ mb: 2.5, color: "rgba(49, 54, 64, 0.6)" }}>{props.task.subtitle}</Typography>
                    {props.modalComponent}
                    {openCloseDialog && <CloseDialog open={openCloseDialog} setOpen={setOpenCloseDialog} handleClose={handleClose} />}
                </div>
            </Paper>
        </Modal>
    );
};

export default TheModal;
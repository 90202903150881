import { useState, useRef, useEffect } from "react";
import Title from "../../components/Common/Title";
import TimesheetsTable from "../../components/Timesheets/Admin/TimesheetsTable";
import TimesheetsDataFilter from "../../components/Timesheets/Admin/TimesheetsDataFilter";
import TheSnackbar from "../../components/Common/TheSnackbar";
import { Grid, IconButton } from "@mui/material";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";
import { useOutletContext } from "react-router-dom";

const Timesheets = () => {
    const storageHasDisableModal = window.localStorage.getItem("disableModal") !== null;
    const [rightSidebar, setRightSidebar] = useOutletContext();
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({ projects: [], persons: [], areas: [], syncStatuses: ["Synced", "Not synced"] });
    const topRef = useRef();
    const [rowCountState, setRowCountState] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: window.localStorage.getItem("timesheetsTablePageSize") !== null ? parseInt(window.localStorage.getItem("timesheetsTablePageSize")) : 50
    });
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [totalHours, setTotalHours] = useState(0);
    const [project, setProject] = useState();
    const [persons, setPersons] = useState([]);
    const [areas, setAreas] = useState([]);
    const [syncStatus, setSyncStatus] = useState();
    const [disableModal, setDisableModal] = useState(storageHasDisableModal ? JSON.parse(window.localStorage.getItem("disableModal")) : false);

    useEffect(() => {
        window.localStorage.setItem("disableModal", disableModal);
    }, [disableModal]);

    return (
        <div ref={topRef}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Title title="Timesheets" subtitle="View and edit times" />
            <Grid container>
                <Grid container item xs={10}>
                    <TimesheetsDataFilter
                        data={data}
                        setData={setData}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                        setRowCountState={setRowCountState}
                        setSnackbarMessage={setSnackbarMessage}
                        setOpenSnackbar={setOpenSnackbar}
                        loading={loading}
                        setLoading={setLoading}
                        options={options}
                        setOptions={setOptions}
                        setTotalHours={setTotalHours}
                        project={project}
                        setProject={setProject}
                        areas={areas}
                        setAreas={setAreas}
                        persons={persons}
                        setPersons={setPersons}
                        syncStatus={syncStatus}
                        setSyncStatus={setSyncStatus}
                    />
                </Grid>
                {(!rightSidebar.task || !rightSidebar.task?.open) &&
                    <Grid container item xs={2} justifyContent="flex-end" alignContent={"flex-end"}>
                        <IconButton onClick={() => setDisableModal(false)} sx={{ backgroundColor: !disableModal ? "rgba(0, 0, 0, 0.1)" : "transparent", ":hover": { backgroundColor: !disableModal ? "rgba(0, 0, 0, 0.1)" : null } }}><FlipToFrontIcon /></IconButton>
                        <IconButton onClick={() => setDisableModal(true)} sx={{ backgroundColor: disableModal ? "rgba(0, 0, 0, 0.1)" : "transparent", ":hover": { backgroundColor: disableModal ? "rgba(0, 0, 0, 0.1)" : null } }}><VerticalSplitIcon /></IconButton>
                    </Grid>
                }
            </Grid>
            <br />
            <TimesheetsTable
                data={data}
                setData={setData}
                rowCountState={rowCountState}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                topRef={topRef}
                totalHours={totalHours}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                options={options}
                setOptions={setOptions}
                setProject={setProject}
                setAreas={setAreas}
                setPersons={setPersons}
                syncStatus={syncStatus}
                setLoading={setLoading}
                setRightSidebar={setRightSidebar}
                disableModal={disableModal}
            />
        </div>
    );
};

export default Timesheets;
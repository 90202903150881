import { Button, Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import DetailsFields from "../Fields/DetailsFields";
import FieldsOutline from "../../../Common/FieldsOutline";
import { combineDateTime } from "../../../../utils/utils";
import ErrorBox from "../../../Common/ErrorBox";
import { UserContext } from "../../../../context/UserContext";
import { axiosInstance } from "../../../../utils/utils";

const AddModalComponent = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [disableButton, setDisableButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const loaded = useRef(false);

    const [details, setDetails] = useState(
        {
            startDate: dayjs(new Date()),
            startTime: dayjs(new Date()).set("hour", 8).set("minute", 0).set("second", 0).set("millisecond", 0),
            endDate: dayjs(new Date()),
            endTime: dayjs(new Date()).set("hour", 16).set("minute", 30).set("second", 0).set("millisecond", 0),
            project: "",
            area: "",
            description: "",
            breakMinutes: 30,
            person: "",
            userId: ""
        }
    );

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const startDate = combineDateTime(details.startDate, details.startTime);
        const endDate = combineDateTime(details.endDate, details.endTime);

        let body = { ...details };
        delete body["startTime"];
        delete body["endTime"];
        body = { ...body, startDate: startDate, endDate: endDate };

        const url = process.env.REACT_APP_API_URL + "/timesheets";
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.post(url, body, config)
            .then(res => {
                props.setAdd({ ...props.add, "open": false });
                props.setSnackbarMessage("Time entry added");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const setAdd = props.setAdd;

    useEffect(() => {
        const fieldsNotEmpty = () => {
            const startDate = dayjs(combineDateTime(details.startDate, details.startTime));
            const endDate = dayjs(combineDateTime(details.endDate, details.endTime));
            const requiredDetails = { ...details };
            const validDateRange = startDate.isBefore(endDate);

            return Object.values(requiredDetails).every(value => value !== "" && value !== undefined && value !== null) && !isNaN(details.breakMinutes) && validDateRange;
        };

        if (loaded.current) {
            setAdd(add => ({ ...add, modified: true }));
        } else {
            loaded.current = true;
        }

        setDisableButton(!fieldsNotEmpty());
    }, [details, setAdd]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields values={details} setValues={setDetails} options={props.options} users={props.users} loaded={loaded} />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item justifyContent="flex-end">
                    <Grid item sx={{ mr: 2.5 }}>
                        <Button variant="outlined" onClick={() => props.setAdd({ ...props.add, open: false, modified: false })}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="submit"
                            disabled={disableButton}
                            sx={{ backgroundColor: "rgba(42, 157, 143, 0.6)", color: "#495464", "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" } }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddModalComponent;
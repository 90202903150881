import { Drawer, Grid, Avatar, List, ListItemButton, ListItemIcon, ListItemText, Typography, Tooltip, IconButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useContext, useCallback } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import menuItems from "./menuItems";
import LogoutIcon from "@mui/icons-material/Logout";
import { UserContext } from "../../../context/UserContext";
import { axiosInstance } from "../../../utils/utils";

const Sidebar = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const { pathname } = useLocation();
    const tooltipParams = { placement: "right", style: { paddingRight: "10px" } };

    const handleLogOut = () => {
        const url = process.env.REACT_APP_API_URL + "/users/logout";
        const config = { withCredentials: true, headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.get(url, config)
            .then((res => {
                setUserContext({});
                window.localStorage.clear();
                window.localStorage.setItem("logout", Date.now());
                window.location = "/";
            }))
            .catch(err => { });
    };

    const syncLogout = useCallback(event => {
        if (event.key === "logout") {
            window.location = "/";
        }
    }, []);

    const handleWidthToggle = () => {
        window.localStorage.setItem("shrink", !props.shrink);
        props.setShrink(!props.shrink);
    };

    useEffect(() => props.setWidth(props.shrink ? props.shrinkWidth : props.openWidth), [props, props.shrink, props.openWidth, props.shrinkWidth, props.setWidth]);

    useEffect(() => {
        window.addEventListener("storage", syncLogout);
        return () => { window.removeEventListener("storage", syncLogout) };
    }, [syncLogout]);

    return (
        <Drawer
            open={!props.shrink}
            variant="permanent"
            sx={{ "& .MuiDrawer-paper": { width: props.width, bgcolor: "#274c77", transition: props.transition, borderRadius: "0px 5px 5px 0px", overflow: "hidden" } }}
        >
            <Grid container direction="column" sx={{ height: "100%" }}>
                <Grid container item wrap="nowrap" sx={{ padding: "15px 15px 35px 8px", minWidth: props.shrinkWidth }}>
                    <Grid item container xs={2} alignItems="center" sx={{ marginRight: "18px" }}>
                        <Tooltip {...tooltipParams} title={props.shrink ? "Expand" : null}>
                            <IconButton onClick={handleWidthToggle} sx={{ "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" } }}>
                                <MenuIcon sx={{ color: "#e7ecef", width: 31, height: 31, "&:hover": { color: "rgba(255, 255, 255, 0.5)" } }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {userContext.username &&
                        <Grid item container alignItems="center" wrap="nowrap" xs={10} sx={{ display: props.shrink ? "none" : "flex" }}>
                            <Grid item component={Link} to="/dashboard" sx={{ paddingRight: "10px" }}>
                                <Avatar sx={{ height: 25, width: 25, bgcolor: "transparent", border: "2px solid #e7ecef", fontWeight: "bold" }}>
                                    {userContext.username.substring(0, 1).toUpperCase()}
                                </Avatar>
                            </Grid>
                            <Grid item component={Link} to="/dashboard">
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: "22px",
                                        overflow: "hidden",
                                        width: "125px",
                                        mask: "linear-gradient(to right, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x"
                                    }}
                                    color="secondary"
                                >
                                    {userContext.username}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Grid item>
                    <List
                        disablePadding
                        sx={{
                            width: props.shrink ? props.shrinkWidth : "240px",
                            transition: props.transition,
                            "& .MuiButtonBase-root": { borderRadius: "10px" }
                        }}
                    >
                        {menuItems.map((item, index) => (
                            <ListItemButton
                                selected={pathname === item.path}
                                key={index}
                                component={Link}
                                to={item.path}
                                sx={{
                                    height: 51,
                                    "&.Mui-selected": { backgroundColor: "rgba(0, 0, 0, 0.275)" },
                                    "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" },
                                    "&.Mui-selected:hover": { backgroundColor: "rgba(0, 0, 0, 0.275)" }
                                }}
                            >
                                <Tooltip {...tooltipParams} title={props.shrink ? item.name : null}>
                                    <ListItemIcon sx={{ color: "#e7ecef", minWidth: 0 }}>{item.icon}</ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    disableTypography
                                    primary={item.name}
                                    sx={{ color: "#e7ecef", fontSize: 18, display: props.shrink ? "none" : "flex", whiteSpace: "nowrap" }}
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </Grid>
                <Grid container item sx={{ padding: "0px 0px 15px 8px", alignContent: "flex-end", flexGrow: 1 }}>
                    <Tooltip {...tooltipParams} title="Log out">
                        <IconButton onClick={handleLogOut} sx={{ "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" } }}>
                            <LogoutIcon sx={{ color: "#e7ecef", width: 31, height: 31, "&:hover": { color: "rgba(255, 255, 255, 0.5)" } }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default Sidebar;
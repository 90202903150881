import { Grid, TextField, Autocomplete, Typography, Button, CircularProgress, Box } from "@mui/material";
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCallback, useEffect, useState, useContext } from "react";
import { combineDateTime, getBreakMinutes } from "../../../../utils/utils";
import dayjs from "dayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import RefreshIcon from "@mui/icons-material/Refresh";
import UnsyncDialog from "../../../Common/UnsyncDialog";
import RefreshDialog from "../../../Common/RefreshDialog";
import UnskipDialog from "../../../Common/UnskipDialog";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import { axiosInstance } from "../../../../utils/utils";
import areasList from "../../Lists/areas.js";
import descriptions from "../../../../components/Timesheets/Lists/descriptions";

const DetailsFields = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const headingParams = { variant: "body1", sx: { fontWeight: "bold" } };
    const gridItemParams = { sx: { width: "50%" } };
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: true, autoComplete: true, autoHighlight: true };
    const textFieldParams = { type: "text", fullWidth: true, variant: "outlined" };
    const [openUnsync, setOpenUnsync] = useState(false);
    const [openRefresh, setOpenRefresh] = useState(false);
    const [openUnskip, setOpenUnskip] = useState(false);
    const [disableUnsyncButton, setDisableUnsyncButton] = useState(false);
    const [disableRefreshButton, setDisableRefreshButton] = useState(false);
    const [disableUnskipButton, setDisableUnskipButton] = useState(false);
    const dateTimeParams = { sx: { width: "100%", ".Mui-error": { color: "#495464" }, ".Mui-error .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(0, 0, 0, 0.2)" } } };
    const multipleValues = "(Multiple Values)";
    const [optionsDates] = useState({ startDate: dayjs(new Date()).subtract(1, "month"), endDate: dayjs(new Date()) });
    const [projectOptions, setProjectOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const [descriptionOptions] = useState(descriptions);
    const [users, setUsers] = useState([]);
    const [unsyncing, setUnsyncing] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [unskipping, setUnskipping] = useState(false);

    const setValues = props.setValues;

    const handleChange = useCallback((name, value) => setValues(pairs => ({ ...pairs, [name]: value })), [setValues]);

    const handleDateChange = (name, value) => {
        if (!Date.parse(value)) {
            handleChange(name, null);
        } else {
            handleChange(name, value);
        }
    };

    // Sets the break time based on start and end dates
    useEffect(() => {
        if (props.values.startDate === multipleValues
            || props.values.startTime === multipleValues
            || props.values.endDate === multipleValues
            || props.values.endTime === multipleValues
            || !props.loaded.current
        ) {
            return;
        }

        if (props.values.startDate && props.values.startTime && props.values.endDate && props.values.endTime && props.loaded.current) {
            const startDate = dayjs(combineDateTime(props.values.startDate, props.values.startTime));
            const endDate = dayjs(combineDateTime(props.values.endDate, props.values.endTime));
            handleChange("breakMinutes", getBreakMinutes(startDate, endDate));
        }
    }, [props.values.startDate, props.values.startTime, props.values.endDate, props.values.endTime, handleChange, props.loaded]);

    // Sets the end date based on start and end times
    useEffect(() => {
        if (props.values.startDate === multipleValues
            || props.values.startTime === multipleValues
            || props.values.endTime === multipleValues
            || !props.loaded.current
        ) {
            return;
        }

        if (props.values.startDate && props.values.startTime && props.values.endTime && props.loaded.current) {
            const startTime = props.values.startTime.set("month", 1).set("date", 1).set("year", 1980);
            const endTime = props.values.endTime.set("month", 1).set("date", 1).set("year", 1980);

            if (startTime.isSameOrBefore(endTime)) {
                setValues(values => ({ ...values, endDate: props.values.startDate }));
            } else {
                setValues(values => ({ ...values, endDate: props.values.startDate.add(1, "day") }));
            }
        }
    }, [setValues, props.values.startDate, props.values.startTime, props.values.endTime, props.loaded]);

    const handleUnsync = async () => {
        props.setShowErrorMessage(false);
        setOpenUnsync(false);
        setDisableUnsyncButton(true);
        setUnsyncing(true);

        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const paymoConfig = { headers: { Authorization: `Basic ${btoa(process.env.REACT_APP_PAYMO_API_KEY + ":")}` } };

        for (const entry of props.entries) {
            if (entry.paymoTimeEntryId && entry.paymoTimeEntryId >= 0) {
                let url = "";
                let res = "";

                // Find time entry on Paymo
                url = process.env.REACT_APP_PAYMO_API_URL + "/entries/" + entry.paymoTimeEntryId;

                try {
                    res = await axios.get(url, paymoConfig);
                } catch (err) {
                    if (err.response.status === 429) {
                        try {
                            await new Promise(resolve => setTimeout(resolve, 4000));
                            res = await axios.get(url, paymoConfig);
                        } catch {
                            // Do nothing
                        }
                    } else {
                        // Do nothing
                    }
                }

                const timeEntry = res.data?.entries[0];

                // Delete time entry from Paymo
                if (timeEntry) {
                    try {
                        res = await axios.delete(url, paymoConfig);
                    } catch (err) {
                        if (err.response.status === 429) {
                            try {
                                await new Promise(resolve => setTimeout(resolve, 4000));
                                res = await axios.delete(url, paymoConfig);
                            } catch {
                                setDisableUnsyncButton(false);
                                setUnsyncing(false);
                                props.setErrorMessage(err.response.data.message);
                                props.setShowErrorMessage(true);
                                return;
                            }
                        } else {
                            setDisableUnsyncButton(false);
                            setUnsyncing(false);
                            props.setErrorMessage(err.response.data.message);
                            props.setShowErrorMessage(true);
                            return;
                        }
                    }
                }

                // Remove Paymo information from timesheet entry
                url = process.env.REACT_APP_API_URL + "/timesheets/" + entry._id;
                const body = { paymoProject: "", paymoTimeEntryId: "" };

                try {
                    await axiosInstance.post(url, body, config);
                } catch (err) {
                    setDisableUnsyncButton(false);
                    setUnsyncing(false);
                    props.setErrorMessage("Error unsyncing");
                    props.setShowErrorMessage(true);
                    return;
                }

                // Find task in Paymo the time entry belongs to and delete if no hours
                if (timeEntry) {
                    url = process.env.REACT_APP_PAYMO_API_URL + "/entries?where=task_id=" + timeEntry.task_id;

                    try {
                        res = await axios.get(url, paymoConfig);
                    } catch (err) {
                        if (err.response.status === 429) {
                            try {
                                await new Promise(resolve => setTimeout(resolve, 4000));
                                res = await axios.get(url, paymoConfig);
                            } catch {
                                setDisableUnsyncButton(false);
                                setUnsyncing(false);
                                props.setErrorMessage(err.response.data.message);
                                props.setShowErrorMessage(true);
                                return;
                            }
                        } else {
                            setDisableUnsyncButton(false);
                            setUnsyncing(false);
                            props.setErrorMessage(err.response.data.message);
                            props.setShowErrorMessage(true);
                            return;
                        }
                    }

                    const timeEntries = res.data.entries;

                    if (timeEntries.length === 0) {
                        url = process.env.REACT_APP_PAYMO_API_URL + "/tasks/" + timeEntry.task_id;

                        try {
                            await axios.delete(url, paymoConfig);
                        } catch (err) {
                            if (err.response.status === 429) {
                                try {
                                    await new Promise(resolve => setTimeout(resolve, 4000));
                                    await axios.delete(url, paymoConfig);
                                } catch {
                                    setDisableUnsyncButton(false);
                                    setUnsyncing(false);
                                    props.setErrorMessage(err.response.data.message);
                                    props.setShowErrorMessage(true);
                                    return;
                                }
                            } else {
                                setDisableUnsyncButton(false);
                                setUnsyncing(false);
                                props.setErrorMessage(err.response.data.message);
                                props.setShowErrorMessage(true);
                                return;
                            }
                        }
                    }
                }
            }
        };

        handleChange("paymoTimeEntryId", "");
        props.setSnackbarMessage("Time entries unsynced");
        props.setOpenSnackbar(true);
        setDisableUnsyncButton(false);
        props.setTask(task => ({ ...task, open: false, modified: false }));
        setUnsyncing(false);
    };

    const handleRefresh = async () => {
        props.setShowErrorMessage(false);
        setOpenRefresh(false);
        setDisableRefreshButton(true);
        setRefreshing(true);

        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const paymoConfig = { headers: { Authorization: `Basic ${btoa(process.env.REACT_APP_PAYMO_API_KEY + ":")}` } };

        for (const entry of props.entries) {
            if (entry.paymoTimeEntryId && entry.paymoTimeEntryId >= 0) {
                let url = "";
                let res = "";

                let entryProjectId = "";
                url = process.env.REACT_APP_PAYMO_API_URL + "/entries/" + entry.paymoTimeEntryId;

                try {
                    res = await axios.get(url, paymoConfig);
                } catch (err) {
                    if (err.response.status === 429) {
                        try {
                            await new Promise(resolve => setTimeout(resolve, 4000));
                            res = await axios.get(url, paymoConfig);
                        } catch {
                            props.setErrorMessage(err.response.data.message);
                            props.setShowErrorMessage(true);
                            setDisableRefreshButton(false);
                            setRefreshing(false);
                            return;
                        }
                    } else {
                        props.setErrorMessage(err.response.data.message);
                        props.setShowErrorMessage(true);
                        setDisableRefreshButton(false);
                        setRefreshing(false);
                        return;
                    }
                }

                entryProjectId = res.data.entries[0].project_id;

                let project = "";
                url = process.env.REACT_APP_PAYMO_API_URL + "/projects/" + entryProjectId;

                try {
                    res = await axios.get(url, paymoConfig);
                } catch (err) {
                    if (err.response.status === 429) {
                        try {
                            await new Promise(resolve => setTimeout(resolve, 4000));
                            res = await axios.get(url, paymoConfig);
                        } catch {
                            props.setErrorMessage(err.response.data.message);
                            props.setShowErrorMessage(true);
                            setDisableRefreshButton(false);
                            setRefreshing(false);
                            return;
                        }
                    } else {
                        props.setErrorMessage(err.response.data.message);
                        props.setShowErrorMessage(true);
                        setDisableRefreshButton(false);
                        setRefreshing(false);
                        return;
                    }
                }

                project = res.data.projects[0].name;

                if (project) {
                    try {
                        url = process.env.REACT_APP_API_URL + "/timesheets/" + entry._id;
                        const body = { paymoProject: project };
                        res = await axiosInstance.post(url, body, config);
                    } catch (err) {
                        props.setErrorMessage(err.response.data);
                        props.setShowErrorMessage(true);
                        setDisableRefreshButton(false);
                        setRefreshing(false);
                        return;
                    }
                }
            }
        };

        props.setSnackbarMessage("Paymo info refreshed");
        props.setOpenSnackbar(true);
        setDisableRefreshButton(false);
        props.setTask(task => ({ ...task, open: false, modified: false }));
        setRefreshing(false);
    };

    const handleUnskip = async () => {
        props.setShowErrorMessage(false);
        setOpenUnskip(false);
        setDisableUnskipButton(true);
        setUnskipping(true);

        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        for (const entry of props.entries) {
            if (entry.paymoTimeEntryId && entry.paymoTimeEntryId === -2) {
                try {
                    const url = process.env.REACT_APP_API_URL + "/timesheets/" + entry._id;
                    const body = { paymoTimeEntryId: "" };
                    await axiosInstance.post(url, body, config);
                } catch (err) {
                    setDisableUnskipButton(false);
                    setUnskipping(false);
                    props.setSnackbarMessage("Unable to complete unskipping");
                    props.setOpenSnackbar(true);
                    return;
                }
            }
        }

        props.setSnackbarMessage("Unskip completed");
        props.setOpenSnackbar(true);
        setDisableUnskipButton(false);
        props.setTask(task => ({ ...task, open: false, modified: false }));
        setUnskipping(false);
    };

    useEffect(() => {
        const fetchOptions = () => {
            let url = process.env.REACT_APP_API_URL + "/timesheets/options";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            let query = "";

            if (Date.parse(optionsDates.startDate) && Date.parse(optionsDates.endDate)) {
                const startDate = new Date(optionsDates.startDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                const endDate = new Date(optionsDates.endDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                query += "&start_date=" + startDate + "&end_date=" + endDate;
            }

            if (query !== "") {
                url += "?" + query.slice(1);
            }

            axiosInstance.get(url, config)
                .then(res => {
                    let url = process.env.REACT_APP_INVENTORY_APP_API_URL + "/inventory/projects/options";
                    const config = { params: { token: userContext.token }, userContext: userContext, setUserContext: setUserContext };

                    let query = "";

                    let startDate = dayjs(new Date()).subtract(1, "month");
                    let endDate = dayjs(new Date());
                    startDate = new Date(startDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                    endDate = new Date(endDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);

                    query += "&start_date=" + startDate + "&end_date=" + endDate;

                    if (query !== "") {
                        url += "?" + query.slice(1);
                    }

                    axiosInstance.get(url, config)
                        .then(rslts => {
                            const projects = Array.from(new Set(res.data.projectOptions.concat(rslts.data.projectOptions))).sort();
                            setProjectOptions(projects);
                        })
                        .catch(err => {
                            setProjectOptions(res.data.projectOptions);
                        });
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchOptions();
    }, [userContext, setUserContext, optionsDates]);

    useEffect(() => {
        const fetchUsers = () => {
            let url = process.env.REACT_APP_API_URL + '/users?user_types=["standard"]';
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setUsers(res.data.entries);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchUsers();
    }, [setUsers, userContext, setUserContext]);

    useEffect(() => {
        const areas = [];

        Object.keys(areasList).forEach(key => {
            areasList[key].areas.forEach(area => areas.push(area.text));
        });

        setAreaOptions(areas.sort());
    }, []);

    return (
        <>
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography {...headingParams}>Person Information</Typography>
                </Grid>
            </Grid>
            <Grid container item {...gridItemParams}>
                <Autocomplete
                    {...autocompleteParams}
                    autoSelect={true}
                    freeSolo={props.disablePerson ? true : false}
                    options={users}
                    getOptionLabel={option => option.fullName || option}
                    renderInput={params => (<TextField {...params} label="Person" autoFocus={props.values.person === ""} />)}
                    onInputChange={(event, value) => handleChange("person", value)}
                    onChange={(event, value) => { value?._id && handleChange("userId", value._id) }}
                    inputValue={String(props.values.person)}
                    disabled={props.disablePerson}
                />
            </Grid>
            <br />
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography {...headingParams}>Start Time</Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            {...dateTimeParams}
                            label="Date"
                            value={props.values.startDate}
                            onChange={value => handleDateChange("startDate", value)}
                            slotProps={props.values.startDate === multipleValues ? { textField: { InputProps: { value: multipleValues } } } : null}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            {...dateTimeParams}
                            label="Start time"
                            value={props.values.startTime}
                            onChange={value => handleDateChange("startTime", value)}
                            timeSteps={{ minutes: 15 }}
                            slotProps={props.values.startTime === multipleValues ? { textField: { InputProps: { value: multipleValues } } } : null}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item {...gridItemParams}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            {...dateTimeParams}
                            label="End time"
                            value={props.values.endTime}
                            onChange={value => handleDateChange("endTime", value)}
                            timeSteps={{ minutes: 15 }}
                            slotProps={props.values.endTime === multipleValues ? { textField: { InputProps: { value: multipleValues } } } : null}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <br />
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography {...headingParams}>Break Time</Typography>
                </Grid>
            </Grid>
            <Grid container item {...gridItemParams}>
                <TextField
                    {...textFieldParams}
                    name="breakMinutes"
                    label="Break minutes"
                    value={String(props.values.breakMinutes)}
                    onChange={event => handleChange(event.target.name, event.target.value)}
                    sx={{ width: "50%" }}
                />
            </Grid>
            <br />
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography {...headingParams}>Project Information</Typography>
                </Grid>
            </Grid>
            <Grid container item {...gridItemParams}>
                <Autocomplete
                    {...autocompleteParams}
                    options={projectOptions}
                    renderInput={params => (<TextField {...params} label="Project" />)}
                    onInputChange={(event, value) => handleChange("project", value)}
                    inputValue={String(props.values.project)}
                />
            </Grid>
            <br />
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Typography {...headingParams}>Work Information</Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <Autocomplete
                        {...autocompleteParams}
                        options={descriptionOptions}
                        renderInput={params => (<TextField {...params} label="Description" />)}
                        onInputChange={(event, value) => handleChange("description", value)}
                        inputValue={String(props.values.description)}
                    />
                </Grid>
                <Grid item {...gridItemParams}>
                    <Autocomplete
                        {...autocompleteParams}
                        options={areaOptions}
                        renderInput={params => (<TextField {...params} label="Area" />)}
                        onInputChange={(event, value) => handleChange("area", value)}
                        inputValue={String(props.values.area)}
                    />
                </Grid>
            </Grid>
            {props.enablePaymo &&
                <>
                    <br />
                    <Grid container item spacing={2.5}>
                        <Grid item {...gridItemParams}>
                            <Typography {...headingParams}>Paymo Status</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2.5}>
                        <Grid container item xs={6}>
                            <Grid item sx={{ mr: 1 }}>
                                <Typography>Sync status:</Typography>
                            </Grid>
                            <Grid item>
                                {props.entries.every(entry => !entry.paymoTimeEntryId || entry.paymoTimeEntryId === "") ?
                                    <DoDisturbIcon fontSize="small" sx={{ color: "#e76161" }} />
                                    :
                                    props.entries.every(entry => entry.paymoTimeEntryId) ?
                                        <CheckCircleIcon fontSize="small" sx={{ color: "rgba(42, 157, 143, 0.6)" }} />
                                        :
                                        <Typography>(Multiple Values)</Typography>
                                }
                            </Grid>
                        </Grid>
                        {props.entries.every(entry => entry.paymoTimeEntryId && entry.paymoTimeEntryId >= 0) &&
                            <Grid container item xs={6} justifyContent="flex-end">
                                <Grid item>
                                    <Box sx={{ position: "relative" }}>
                                        <Button variant="outlined" disabled={disableUnsyncButton} onClick={() => setOpenUnsync(true)} sx={{ mr: 2.5, "&:disabled": { backgroundColor: "transparent" } }}>
                                            Unsync
                                        </Button>
                                        {unsyncing &&
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "#274c77",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: "-12px",
                                                    marginLeft: "-20px",
                                                }}
                                            />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box sx={{ position: "relative" }}>
                                        <Button
                                            onClick={() => setOpenRefresh(true)}
                                            size="medium"
                                            disabled={disableRefreshButton}
                                            sx={{ backgroundColor: "#6096ba", color: "#e7ecef", "&:hover": { backgroundColor: "#6096ba" } }}
                                        >
                                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><RefreshIcon sx={{ fontSize: 20 }} /></span>
                                            Refresh
                                        </Button>
                                        {refreshing &&
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "#6096ba",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: "-12px",
                                                    marginLeft: "-12px",
                                                }}
                                            />
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                        {props.entries.every(entry => entry.paymoTimeEntryId && entry.paymoTimeEntryId === -2) &&
                            <Grid container item xs={6} justifyContent="flex-end">
                                <Grid item>
                                    <Box sx={{ position: "relative" }}>
                                        <Button
                                            onClick={() => setOpenUnskip(true)}
                                            size="medium"
                                            disabled={disableUnskipButton}
                                            sx={{ backgroundColor: "#6096ba", color: "#e7ecef", "&:hover": { backgroundColor: "#6096ba" } }}
                                        >
                                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><CallMissedIcon sx={{ fontSize: 20 }} /></span>
                                            Unskip
                                        </Button>
                                        {unskipping &&
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "#6096ba",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: "-12px",
                                                    marginLeft: "-12px",
                                                }}
                                            />
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </>
            }
            {openUnsync && <UnsyncDialog open={openUnsync} setOpen={setOpenUnsync} handleUnsync={handleUnsync} />}
            {openRefresh && <RefreshDialog open={openRefresh} setOpen={setOpenRefresh} handleRefresh={handleRefresh} />}
            {openUnskip && <UnskipDialog open={openUnskip} setOpen={setOpenUnskip} handleUnskip={handleUnskip} />}
        </>
    );
};

export default DetailsFields;
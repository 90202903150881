import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";

const iconParams = { sx: { width: 31, height: 31 } };

const menuItems = [
    { name: "Dashboard", path: "/dashboard", icon: <DashboardIcon {...iconParams} /> },
    { name: "Timesheets", path: "/timesheets", icon: <CalendarMonthIcon {...iconParams} /> },
    { name: "Create Report", path: "/createreport", icon: <AssessmentIcon {...iconParams} /> },
    { name: "Users", path: "/users", icon: <GroupIcon {...iconParams} /> },
    { name: "Settings", path: "/settings", icon: <SettingsIcon {...iconParams} /> }
];

export default menuItems;
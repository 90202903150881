import { Button, IconButton, Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import FieldsOutline from "../../../Common/FieldsOutline";
import DetailsFields from "../Fields/DetailsFields";
import DeleteEntryDialog from "../../../Common/DeleteEntryDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorBox from "../../../Common/ErrorBox";
import { combineDateTime, axiosInstance } from "../../../../utils/utils";
import { UserContext } from "../../../../context/UserContext";

const EditMultipleModalComponent = props => {
    const [entry, setEntry] = useState({
        _id: "",
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        project: "",
        area: "",
        description: "",
        breakMinutes: "",
        person: "",
        userId: ""
    });
    const [entries, setEntries] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);
    const [openDelete, setOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const loaded = useRef(false);
    const multipleValues = "(Multiple Values)";

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        let updatedEntries = [];

        entries.forEach(fetchedEntry => {
            let updatedEntry = { _id: fetchedEntry._id };

            Object.entries(entry).forEach(([key, value]) => {
                if (key === "startDate" || key === "endDate" || key === "startTime" || key === "endTime") {
                    if (value === multipleValues) {
                        updatedEntry[key] = fetchedEntry[key];
                    } else {
                        updatedEntry[key] = value;
                    }
                } else {
                    if (value === multipleValues) {
                        updatedEntry[key] = fetchedEntry[key];
                    } else {
                        updatedEntry[key] = value;
                    }
                }
            });

            // Calculate the end date
            updatedEntry.startTime = updatedEntry.startTime.set("month", 1).set("date", 1).set("year", 1980);
            updatedEntry.endTime = updatedEntry.endTime.set("month", 1).set("date", 1).set("year", 1980);

            if (updatedEntry.startTime.isSameOrBefore(updatedEntry.endTime)) {
                updatedEntry.endDate = updatedEntry.startDate;
            } else {
                updatedEntry.endDate = updatedEntry.startDate.add(1, "day");
            }

            updatedEntries.push(updatedEntry);
        });

        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        let promises = [];

        updatedEntries.forEach(updatedEntry => {
            const startDate = combineDateTime(updatedEntry.startDate, updatedEntry.startTime);
            const endDate = combineDateTime(updatedEntry.endDate, updatedEntry.endTime);

            const url = process.env.REACT_APP_API_URL + "/timesheets/" + updatedEntry._id;
            const body = { ...updatedEntry, startDate: startDate, endDate: endDate };
            delete body["startTime"];
            delete body["endTime"];

            promises.push(axiosInstance.post(url, body, config));
        });

        Promise.all(promises)
            .then(res => {
                props.setEditMultiple({ ...props.editMultiple, "open": false });
                props.setSnackbarMessage("Time entries updated");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const handleDelete = async () => {
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        for (const _id of props.selectionIds) {
            const url = process.env.REACT_APP_API_URL + "/timesheets/" + _id;

            try {
                await axiosInstance.delete(url, config);
            } catch (err) {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                return;
            }
        }

        props.setEditMultiple({ ...props.editMultiple, "open": false });
        props.setSnackbarMessage("Time entries deleted");
        props.setOpenSnackbar(true);
    };

    useEffect(() => {
        const fetchEntries = () => {
            let ids = "";
            props.selectionIds.forEach(id => ids += ',"' + id + '"');
            ids = ids.slice(1);

            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            const url = process.env.REACT_APP_API_URL + "/timesheets?ids=[" + ids + "]";

            axiosInstance.get(url, config)
                .then(res => {
                    setEntries(res.data.map(entry => ({
                        ...entry,
                        startDate: dayjs(entry.startDate),
                        startTime: dayjs(entry.startDate),
                        endDate: dayjs(entry.endDate),
                        endTime: dayjs(entry.endDate)
                    })));
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchEntries();
    }, [userContext, setUserContext, props.selectionIds]);

    const setEditMultiple = props.setEditMultiple;

    useEffect(() => {
        const initializeEntry = () => {
            const entrySets = {
                startDate: new Set(),
                startTime: new Set(),
                endDate: new Set(),
                endTime: new Set(),
                fullName: new Set(),
                project: new Set(),
                area: new Set(),
                description: new Set(),
                breakMinutes: new Set(),
                paymoTimeEntryId: new Set()
            };

            entries.forEach(entry => {
                Object.entries(entrySets).forEach(([key, value]) => {
                    if (key === "startDate" || key === "endDate") {
                        entrySets[key].add(new Date(entry[key]).toLocaleDateString());
                    }
                    else if (key === "startTime" || key === "endTime") {
                        const time = entry[key].get("hour") + ":" + entry[key].get("minute") + ":000:000";
                        entrySets[key].add(time);
                    }
                    else {
                        value.add(entry[key]);
                    }
                });
            });

            entrySets.person = entrySets.fullName;

            let updatedEntry = {};

            Object.entries(entrySets).forEach(([key, value]) => {
                if (entrySets[key].size > 1) {
                    if (key === "startDate" || key === "startTime" || key === "endDate" || key === "endTime") {
                        updatedEntry[key] = multipleValues;
                    } else {
                        updatedEntry[key] = multipleValues;
                    }
                } else {
                    if (key === "startDate" || key === "startTime" || key === "endDate" || key === "endTime") {
                        updatedEntry[key] = entries[0] ? entries[0][key] : null;
                    } else {
                        updatedEntry[key] = Array.from(value)[0];
                    }
                }
            });

            setEntry(updatedEntry);
        };

        if (!isLoading) {
            if (loaded.current) {
                setEditMultiple(editMultiple => ({ ...editMultiple, modified: true }));
            } else {
                loaded.current = true;
            }
        }

        initializeEntry();
    }, [entries, isLoading, setEditMultiple]);

    useEffect(() => {
        const fieldsNotEmpty = () => {
            let requiredFields = { ...entry };
            delete requiredFields.paymoTimeEntryId;
            delete requiredFields.area;

            return Object.values(requiredFields).every(value => value !== "" && value !== undefined) && (!isNaN(entry.breakMinutes) || entry.breakMinutes === multipleValues);
        };

        if (!isLoading) {
            if (loaded.current) {
                setEditMultiple(editMultiple => ({ ...editMultiple, modified: true }));
            } else {
                loaded.current = true;
            }
        }

        setDisableButton(!fieldsNotEmpty());
    }, [entry, isLoading, setEditMultiple]);

    useEffect(() => setIsLoading(entries.length === 0), [entries]);

    return (!isLoading &&
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields
                            values={entry}
                            setValues={setEntry}
                            options={props.options}
                            users={props.users}
                            disablePerson
                            enablePaymo
                            setErrorMessage={setErrorMessage}
                            setShowErrorMessage={setShowErrorMessage}
                            setSnackbarMessage={props.setSnackbarMessage}
                            setOpenSnackbar={props.setOpenSnackbar}
                            entries={entries}
                            loaded={loaded}
                            setTask={props.setEditMultiple}
                        />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item>
                    <Grid container item xs={6} alignItems="center">
                        <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                        <Grid item sx={{ mr: 2.5 }}>
                            <Button variant="outlined" onClick={() => props.setEditMultiple({ ...props.editMultiple, open: false, modified: false })}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                disabled={disableButton}
                                sx={{ backgroundColor: "rgba(42, 157, 143, 0.6)", color: "#495464", "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" } }}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openDelete && <DeleteEntryDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} />}
        </form>
    );
};

export default EditMultipleModalComponent;
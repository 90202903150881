import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const Unskip = props => {
    const handleClose = () => { props.setOpen(false) };

    return (
        <Dialog open={props.open} onClose={handleClose} PaperProps={{ style: { backgroundColor: "#274c77", padding: 30 } }}>
            <DialogTitle color="#e7ecef">Confirm Unskip</DialogTitle>
            <DialogContent><DialogContentText>Are you sure you want to unskip?</DialogContentText></DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={{ backgroundColor: "transparent", color: "#e7ecef", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                    Cancel
                </Button>
                <Button onClick={props.handleUnskip} sx={{ backgroundColor: "#6096ba", color: "#e7ecef", "&:hover": { backgroundColor: "#6096ba" } }}>
                    Unskip
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Unskip;
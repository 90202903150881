import DataFilter from "../../DataTable/DataFilter.js";
import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import { UserContext } from "../../../context/UserContext";
import { axiosInstance } from "../../../utils/utils.js";

const TimesheetsDataFilter = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [dates, setDates] = useState({
        startDate: userContext.timesheetsDataFilterStartDate !== undefined ? dayjs(userContext.timesheetsDataFilterStartDate) : dayjs(new Date()).subtract(1, "month"),
        endDate: userContext.timesheetsDataFilterEndDate !== undefined ? dayjs(userContext.timesheetsDataFilterEndDate) : dayjs(new Date())
    });
    const [listening, setListening] = useState(false);
    const initialized = useRef(false);
    const [updating, setUpdating] = useState(true);

    const setData = props.setData;
    const setRowCountState = props.setRowCountState;
    const setLoading = props.setLoading;
    const setSnackbarMessage = props.setSnackbarMessage;
    const setOpenSnackbar = props.setOpenSnackbar;
    const setPaginationModel = props.setPaginationModel;
    const setOptions = props.setOptions;
    const setTotalHours = props.setTotalHours;
    const setProject = props.setProject;
    const setAreas = props.setAreas;
    const setPersons = props.setPersons;
    const setSyncStatus = props.setSyncStatus;

    const fields = [
        { name: "syncStatus", label: "Sync Status", options: props.options.syncStatuses, state: props.syncStatus, setState: setSyncStatus },
        { name: "project", label: "Project", options: props.options.projects, state: props.project, setState: setProject },
        { name: "area", label: "Area", options: props.options.areas, state: props.areas, setState: setAreas, multiple: true },
        { name: "person", label: "Person", options: props.options.persons, state: props.persons, setState: setPersons, multiple: true }
    ];

    useEffect(() => {
        if (dates.startDate) {
            setUserContext(userContext => ({ ...userContext, timesheetsDataFilterStartDate: dates.startDate }));
        }

        if (dates.endDate) {
            setUserContext(userContext => ({ ...userContext, timesheetsDataFilterEndDate: dates.endDate }));
        }
    }, [dates, setUserContext]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setLoading(true);
        }
    }, [setData, setLoading, props.paginationModel]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setPaginationModel(paginationModel => ({ ...paginationModel, page: 0 }));
        }
    }, [props.persons, setPaginationModel, setData]);

    useEffect(() => {
        if (initialized.current) {
            setAreas([]);
        }
    }, [props.project, dates, props.syncStatus, setAreas]);

    useEffect(() => {
        if (initialized.current) {
            setPersons([]);
        }
    }, [props.areas, setPersons]);

    useEffect(() => {
        if (!listening) {
            const eventSource = new EventSource(process.env.REACT_APP_API_URL + "/timesheets/updates");

            eventSource.onmessage = event => {
                setUpdating(true);
            };

            return () => eventSource.close();
        }

        setListening(true);
    }, [listening, setUpdating]);

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + "/timesheets";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            let query = "";

            if (Date.parse(dates.startDate) && Date.parse(dates.endDate)) {
                const startDate = new Date(dates.startDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                const endDate = new Date(dates.endDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                query += "&start_date=" + startDate + "&end_date=" + endDate;
            }

            if (props.paginationModel) {
                query += "&page=" + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;
            }

            if (props.syncStatus) {
                if (props.syncStatus === "Synced") {
                    query += "&sync_status=true";
                } else if (props.syncStatus === "Not synced") {
                    query += "&sync_status=false";
                }
            }

            if (props.project) {
                query += "&project=" + encodeURIComponent(props.project);
            }

            if (props.areas.length > 0) {
                query += "&areas=[";

                props.areas.forEach(area => {
                    query += '"' + encodeURIComponent(area) + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            if (props.persons.length > 0) {
                query += "&persons=[";

                props.persons.forEach(person => {
                    query += '"' + encodeURIComponent(person) + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            if (query !== "") {
                url += "?" + query.slice(1);
            }

            axiosInstance.get(url, config)
                .then(res => {
                    const fetchedData = res.data.entries.map(entry => ({ ...entry, date: entry.startDate }));
                    setData(fetchedData);
                    setRowCountState(res.data.totalEntries);
                    setTotalHours(res.data.totalHours);
                    setOptions(options => ({
                        ...options,
                        projects: res.data.projectOptions,
                        persons: props.persons.length > 0 ? options.persons : res.data.personOptions,
                        areas: props.areas.length > 0 ? options.areas : res.data.areaOptions
                    }));
                    setLoading(false);
                    setUpdating(false);
                    initialized.current = true;
                })
                .catch(err => {
                    setLoading(false);
                    setUpdating(false);
                    setSnackbarMessage("Unable to load timesheet data");
                    setOpenSnackbar(true);
                });
        };

        if (props.loading || updating) {
            fetchData();
        }
    }, [props.loading, updating, setUpdating, dates, userContext, setUserContext, props.paginationModel, props.project, props.areas, props.persons, setData, setRowCountState, setLoading, setSnackbarMessage, setOpenSnackbar, setOptions, setTotalHours, props.syncStatus]);

    return (
        <DataFilter fields={fields} dates={dates} setDates={setDates} data={props.data} />
    );
};

export default TimesheetsDataFilter;
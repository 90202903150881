import { FlexibleXYPlot, XAxis, YAxis, VerticalBarSeries } from "react-vis";
import { useState, useEffect, useContext, useMemo } from "react";
import { Typography, Paper } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import dayjs from "dayjs";
import { axiosInstance } from "../../utils/utils";

const MonthlyHoursChart = props => {
    const [data, setData] = useState([]);
    const [userContext, setUserContext] = useContext(UserContext);
    const [leftMargin, setLeftMargin] = useState(0);
    const YAxisLeftMargin = -30;
    const [hintValues, setHintValues] = useState({});
    const [showHint, setShowHint] = useState(false);

    const monthNames = useMemo(() => ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"], []);
    let xDomain = [];
    var date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() - 11);

    for (var i = 0; i <= 11; i++) {
        xDomain.push(monthNames[date.getMonth()]);
        date.setMonth(date.getMonth() + 1);
    }

    useEffect(() => {
        const fetchData = () => {
            let startDate = dayjs(new Date()).subtract(12, "month");
            let endDate = dayjs(new Date());
            startDate = startDate.toISOString().substring(0, 10);
            endDate = endDate.toISOString().substring(0, 10);

            const url = process.env.REACT_APP_API_URL + "/timesheets/monthlyhours?start_date=" + startDate + "&end_date=" + endDate;;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    const transformedData = res.data.map(hours => {
                        return { x: monthNames[hours.month - 1], y: String(hours.totalHours) };
                    });

                    setData(transformedData);
                })
                .catch(err => { });
        };

        fetchData();
    }, [userContext, setUserContext, monthNames]);

    useEffect(() => {
        const updateLeftMargin = () => {
            let max = 0;

            for (const d of data) {
                if (d.y > max) {
                    max = d.y;
                }
            }

            let str = max ? max : "";
            let margin = (15 - YAxisLeftMargin) + (str.length * 8.8);

            setLeftMargin(margin);
        };

        updateLeftMargin();
    }, [data, YAxisLeftMargin]);

    return (
        <Paper
            sx={{
                backgroundColor: "#ffffff",
                width: "100%",
                height: "100%",
                padding: "25px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)"
            }}
        >
            <Typography variant="h8" sx={{ mb: 2.5 }}>MONTHLY HOURS WORKED</Typography>
            <FlexibleXYPlot xType="ordinal" xDomain={xDomain} margin={{ left: leftMargin }}>
                <XAxis />
                <YAxis title="Hours" left={YAxisLeftMargin} tickFormat={value => value} />
                <VerticalBarSeries
                    color="#f4a261"
                    data={data}
                    onValueMouseOver={(datapoint, event) => {
                        setHintValues({ value: datapoint.y, x: event.event.pageX, y: event.event.pageY }, setShowHint(true));
                    }}
                    onValueMouseOut={() => setHintValues({}, setShowHint(false))}
                />
                {showHint &&
                    <Paper
                        sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            padding: "10px",
                            display: "inline-block",
                            color: "#bbbfca",
                            position: "absolute",
                            left: hintValues.x,
                            top: hintValues.y
                        }}
                    >
                        <Typography variant="body1">Hours:</Typography>
                        <Typography variant="body1">{hintValues.value + " hrs"}</Typography>
                    </Paper>
                }
            </FlexibleXYPlot>
        </Paper>
    );
};

export default MonthlyHoursChart;
import { useState, useRef, useEffect } from "react";
import Title from "../../components/Common/Title";
import UsersTable from "../../components/Users/UsersTable";
import UsersDataFilter from "../../components/Users/UsersDataFilter";
import TheSnackbar from "../../components/Common/TheSnackbar";
import { Grid, IconButton } from "@mui/material";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";
import { useOutletContext } from "react-router-dom";

const Users = () => {
    const storageHasDisableModal = window.localStorage.getItem("disableModal") !== null;
    const [rightSidebar, setRightSidebar] = useOutletContext();
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({ companies: [], activeStatuses: ["Active", "Inactive"], searches: [] });
    const topRef = useRef();
    const [rowCountState, setRowCountState] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: window.localStorage.getItem("usersTablePageSize") !== null ? parseInt(window.localStorage.getItem("usersTablePageSize")) : 50
    });
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [company, setCompany] = useState();
    const [activeStatus, setActiveStatus] = useState();
    const [disableModal, setDisableModal] = useState(storageHasDisableModal ? JSON.parse(window.localStorage.getItem("disableModal")) : false);

    useEffect(() => {
        window.localStorage.setItem("disableModal", disableModal);
    }, [disableModal]);

    return (
        <div ref={topRef}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Title title="Users" subtitle="View and edit users" />
            <Grid container>
                <Grid container item xs={10}>
                    <UsersDataFilter
                        data={data}
                        setData={setData}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                        setRowCountState={setRowCountState}
                        setSnackbarMessage={setSnackbarMessage}
                        setOpenSnackbar={setOpenSnackbar}
                        loading={loading}
                        setLoading={setLoading}
                        options={options}
                        setOptions={setOptions}
                        company={company}
                        setCompany={setCompany}
                        activeStatus={activeStatus}
                        setActiveStatus={setActiveStatus}
                    />
                </Grid>
                {(!rightSidebar.task || !rightSidebar.task?.open) &&
                    <Grid container item xs={2} justifyContent="flex-end" alignContent={"flex-end"}>
                        <IconButton onClick={() => setDisableModal(false)} sx={{ backgroundColor: !disableModal ? "rgba(0, 0, 0, 0.1)" : "transparent", ":hover": { backgroundColor: !disableModal ? "rgba(0, 0, 0, 0.1)" : null } }}><FlipToFrontIcon /></IconButton>
                        <IconButton onClick={() => setDisableModal(true)} sx={{ backgroundColor: disableModal ? "rgba(0, 0, 0, 0.1)" : "transparent", ":hover": { backgroundColor: disableModal ? "rgba(0, 0, 0, 0.1)" : null } }}><VerticalSplitIcon /></IconButton>
                    </Grid>
                }
            </Grid>
            <br />
            <UsersTable
                data={data}
                rowCountState={rowCountState}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                topRef={topRef}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                options={options}
                setOptions={setOptions}
                setCompany={setCompany}
                setActiveStatus={setActiveStatus}
                setRightSidebar={setRightSidebar}
                disableModal={disableModal}
            />
        </div>
    );
};

export default Users;
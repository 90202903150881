import { Grid, Typography, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useMemo, useContext } from "react";
import TheSnackbar from "../../Common/TheSnackbar";
import { UserContext } from "../../../context/UserContext";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const TimesheetsTable = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const rows = useMemo(() => props.data, [props.data]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const setOpenSnackbar = props.setOpenSnackbar;

    const handleClickEntry = id => {
        navigate("/edittime?id=" + id);
    };

    const handlePageChange = (newPaginationModel, scrollToTop = true) => {
        props.setPaginationModel(newPaginationModel);
        setUserContext(userContext => ({ ...userContext, mainPage: newPaginationModel.page }));

        if (scrollToTop && props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView();
        }
    };

    const entryIsEditable = row => {
        const entryCreatedDate = dayjs(row.createdAt);
        const limitDate = dayjs(new Date()).subtract(1, "week");
        return entryCreatedDate.isSameOrAfter(limitDate) && row.paymoTimeEntryId !== -1;
    };

    const columns = [{
        field: "_id",
        headerName: "Time",
        flex: 1,
        renderCell: params => {
            return (
                <Paper
                    key={params.row._id}
                    sx={{
                        backgroundColor: userContext.userType === "supervisor" || entryIsEditable(params.row) ? "#ffffff" : "rgba(255, 255, 255, 0.5)",
                        minHeight: "165px",
                        padding: "12px",
                        display: "flex",
                        justifyContent: "center",
                        boxShadow: "none",
                        width: "100%"
                    }}
                    onClick={event => { if (userContext.userType === "standard" && entryIsEditable(params.row)) handleClickEntry(params.row._id) }}
                >
                    <Grid container direction="row" justifyContent="space-between" spacing={1.5} sx={{ width: "100%" }}>
                        <Grid container item direction="column" xs={8.5}>
                            <Grid item>
                                {userContext.userType === "supervisor" &&
                                    <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontWeight: "800", fontSize: "17px" }}>
                                        {params.row.fullName}
                                    </Typography>
                                }
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontWeight: "800", fontSize: "15px" }}>
                                    {dayjs(params.row.startDate).format("ddd, MMM DD, YYYY")}
                                </Typography>
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontWeight: "800", fontSize: "15px" }}>
                                    {dayjs(params.row.startDate).format("hh:mm a")} - {dayjs(params.row.endDate).format("hh:mm a")}
                                </Typography>
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontWeight: "800", color: userContext.userType === "standard" ? "rgba(0, 0, 0, 0.65)" : "#495464", fontSize: userContext.userType === "standard" ? "17px" : "15px" }}>
                                    {params.row.project}
                                </Typography>
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontSize: "15px" }}>
                                    {params.row.description}
                                </Typography>
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontSize: "15px" }}>
                                    {params.row.area}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3.5} alignContent="center">
                            <Grid display="flex" justifyContent="flex-end">
                                <Typography sx={{ cursor: "pointer", width: "fit-content", fontWeight: "normal", fontSize: "17px" }}>
                                    {+params.row.hours.toFixed(2)} Hr
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            );
        }
    }];

    return (
        <Grid container item>
            <TheSnackbar open={props.openSnackbar} setOpen={setOpenSnackbar} message={props.snackbarMessage} />
            <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                autoHeight
                disableRowSelectionOnClick
                disableColumnMenu
                getRowId={row => row._id}
                slots={{
                    noRowsOverlay: () => {
                        return <Typography variant="body1" align="center" sx={{ color: "#495464", pt: "100px" }}>{t("main.noTimeMessage")}</Typography>;
                    }
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                paginationMode="server"
                paginationModel={props.paginationModel}
                onPaginationModelChange={handlePageChange}
                rowCount={props.rowCountState}
                loading={props.loading}
                sx={{
                    fontSize: 16,
                    border: 0,
                    "& .MuiDataGrid-cell": {
                        padding: "0 0 20px 0",
                        border: 0,
                        "&:focus-within, &:focus": { outline: "none" }
                    },
                    "& .MuiDataGrid-footerContainer": { border: 0 },
                    "& .MuiDataGrid-columnHeaders": { display: "none" },
                    "& .MuiDataGrid-row:hover": { borderRadius: "0px", backgroundColor: "transparent" },
                    "& .MuiDataGrid-overlayWrapper": { height: "100vh !important" }
                }}
            />
        </Grid>
    );
};

export default TimesheetsTable;
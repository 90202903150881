import { useState, useEffect, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import DataTable from "../DataTable/DataTable";
import DataTableFooter from "../DataTable/TableComponents/DataTableFooter";
import DataTableToolbar from "../DataTable/TableComponents/DataTableToolbar";
import TotalEntriesAndHours from "../DataTable/TableComponents/TotalEntriesAndHours";
import { Tooltip, Grid, Link } from "@mui/material";
import CreateReportToolbar from "./CreateReportToolbar";
import TableSummary from "../DataTable/TableComponents/TableSummary";
import { useGridApiRef } from "@mui/x-data-grid";

const CreateReportTable = props => {
    const [selectionIds, setSelectionIds] = useState([]);
    const [showSelected, setShowSelected] = useState(false);
    const [rows, setRows] = useState([]);
    const apiRef = useGridApiRef();

    const setProject = props.setProject;
    const setAreas = props.setAreas;
    const setPersons = props.setPersons;

    const columns = useMemo(() => {
        const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };

        const handleClick = (event, field, value) => {
            event.stopPropagation();

            if (field === "project") {
                setProject(value);
            } else if (field === "area") {
                setAreas([value]);
            } else if (field === "person") {
                setPersons([value]);
            }
        };

        return [
            {
                field: "fullName",
                headerName: "Name",
                minWidth: 100,
                flex: 1.4,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "person", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "company",
                headerName: "Company",
                minWidth: 100,
                flex: 1.4,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "date",
                headerName: "Date",
                minWidth: 110,
                flex: 0.5,
                valueGetter: params => dayjs(params.value).format("YYYY-MM-DD"),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "project",
                headerName: "Project",
                minWidth: 95,
                flex: 1.5,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "project", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "description",
                headerName: "Description",
                minWidth: 105,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "area",
                headerName: "Area",
                minWidth: 95,
                flex: 1.5,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "area", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "startDate",
                headerName: "Start",
                minWidth: 95,
                flex: 0.5,
                valueGetter: params => dayjs(params.value).format("hh:mm a"),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "endDate",
                headerName: "End",
                minWidth: 95,
                flex: 0.5,
                valueGetter: params => dayjs(params.value).format("hh:mm a"),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "breakMinutes",
                headerName: "Break",
                type: "number",
                minWidth: 80,
                flex: 1,
                renderCell: params => <Tooltip title={params.value + " min"}><div {...cellParams}>{params.value + " min"}</div></Tooltip>
            },
            {
                field: "hours",
                headerName: "Hours",
                type: "number",
                minWidth: 80,
                flex: 0.7,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            }
        ];
    }, [setAreas, setPersons, setProject]);

    const setRowCountState = props.setRowCountState;
    const setTotalHours = props.setTotalHours;

    useEffect(() => {
        if (showSelected) {
            const idsSet = new Set(selectionIds);
            const selections = props.data.filter(entry => idsSet.has(entry._id));
            setTotalHours(selections.reduce((partialSum, entry) => partialSum + entry.hours, 0));
            setRowCountState(selections.length);
            setRows(selections);
        } else {
            setTotalHours(props.fetchedTotalHours);
            setRowCountState(props.fetchedRowCountState);
            setRows(props.data);
        }
    }, [showSelected, props.data, selectionIds, setTotalHours, props.fetchedTotalHours, setRowCountState, props.fetchedRowCountState]);

    const CustomFooter = useCallback(() => { return (<DataTableFooter rows={rows} />) }, [rows]);

    const CustomToolbar = useCallback(() => {
        const totalComponent = <TotalEntriesAndHours totalEntries={props.rowCountState} totalHours={props.totalHours} />;
        const toolbarComponent = <TableSummary totalComponent={totalComponent} title={props.title} columns={columns} />;

        return (<DataTableToolbar toolbarComponent={toolbarComponent} showToolbarContainer />)
    }, [props.rowCountState, props.totalHours, columns, props.title]);

    const handlePageChange = useCallback(newPaginationModel => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("createReportTablePageSize", newPaginationModel.pageSize);

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    }, [props]);

    useEffect(() => {
        if (selectionIds.length === 0) {
            setShowSelected(false);
        }
    }, [props.data, selectionIds]);

    return (
        <>
            <Grid container item sx={{ mb: 1.5 }}>
                <CreateReportToolbar
                    apiRef={apiRef}
                    selectionIds={selectionIds}
                    options={props.options}
                    setSnackbarMessage={props.setSnackbarMessage}
                    setOpenSnackbar={props.setOpenSnackbar}
                    setUpdating={props.setUpdating}
                    rowCountState={props.rowCountState}
                    totalHours={props.totalHours}
                    setShowSelected={setShowSelected}
                    showSelected={showSelected}
                />
            </Grid>
            <Grid container item sx={{ border: "1px dashed rgba(0, 0, 0, 0.15)", backgroundColor: "rgba(0, 0, 0, 0.025)" }}>
                <Grid
                    item
                    sx={{
                        width: "11in",
                        backgroundColor: "rgba(255, 255, 255, 0.25)",
                        padding: "0.5in",
                        "& .MuiTypography-root": { color: "black" }
                    }}
                >
                    <DataTable
                        columns={columns}
                        rows={rows}
                        customFooter={CustomFooter}
                        customToolbar={CustomToolbar}
                        paginationMode="server"
                        paginationModel={props.paginationModel}
                        handlePageChange={handlePageChange}
                        rowCountState={props.rowCountState}
                        loading={props.loading}
                        setSelectionIds={setSelectionIds}
                        apiRef={apiRef}
                        selectedColumns={props.selectedColumns}
                        sx={{
                            fontSize: "13px",
                            color: "black",
                            border: 0,
                            "& .MuiTypography-root": { color: "black" },
                            "& .MuiDataGrid-cell": {
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                display: "flex",
                                alignItems: "flex-start",
                                border: 0,
                                "&:focus-within, &:focus": { outline: "none" }
                            },
                            "& .MuiDataGrid-overlay": { bgcolor: "transparent" },
                            "& .MuiDataGrid-columnHeaders": { borderColor: "#bbbfca" },
                            "& .MuiDataGrid-iconSeparator": { color: "#bbbfca" },
                            "& .MuiDataGrid-footerContainer": { border: 0 },
                            "&.MuiDataGrid-root": { lineHeight: "normal" },
                            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": { display: "none" },
                            "& .MuiCheckbox-root": { padding: 0 },
                            "& .MuiTablePagination-selectLabel": { color: "black" },
                            "& .MuiInputBase-root": { color: "black" },
                            "& .MuiTablePagination-displayedRows": { color: "black" },
                            "& .MuiTablePagination-actions": { color: "black" },
                            "@media print": {
                                color: "#000000",
                                display: "table",
                                ".MuiDataGrid-footerContainer": { display: "none" },
                                "& .MuiDataGrid-virtualScroller": { display: "table", overflow: "visible" },
                                ".css-i4bv87-MuiSvgIcon-root": { color: "white" },
                                "@page": { size: "landscape" }
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default CreateReportTable;
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Sidebar from "./Sidebar";
import { useState, useEffect } from "react";
import RightSidebar from "./RightSidebar";

const Layout = () => {
    const openSidebarWidth = "255px";
    const shrinkSidebarWidth = "65px";
    const [shrinkSidebar, setShrinkSidebar] = useState(JSON.parse(window.localStorage.getItem("shrink")));
    const [sidebarWidth, setSidebarWidth] = useState(JSON.parse(window.localStorage.getItem("shrink")) ? shrinkSidebarWidth : openSidebarWidth);
    const transition = "width 0.2s ease-in-out";
    const shrinkAt = 750;
    const [rightSidebar, setRightSidebar] = useState({ open: false });
    const rightSidebarWidth = "750px";
    const rightSidebarTransition = null;

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    };

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (windowDimensions.width < shrinkAt) {
            setShrinkSidebar(true);
        } else {
            setShrinkSidebar(JSON.parse(window.localStorage.getItem("shrink")));
        }

    }, [windowDimensions]);

    return (
        <Box sx={{ display: "space-between" }}>
            <Box sx={{ width: sidebarWidth, transition: transition }}>
                <Sidebar
                    width={sidebarWidth}
                    setWidth={setSidebarWidth}
                    openWidth={openSidebarWidth}
                    shrinkWidth={shrinkSidebarWidth}
                    transition={transition}
                    shrink={shrinkSidebar}
                    setShrink={setShrinkSidebar}
                />
            </Box>
            <Box sx={{ padding: "20px 20px 0px 35px", flexGrow: 1, height: "100vh", overflow: "scroll" }}>
                <Outlet context={[rightSidebar, setRightSidebar]} />
                <Box sx={{ paddingBottom: "32px" }} />
            </Box>
            <Box
                sx={{
                    borderLeft: "1px solid #bbbfca",
                    width: rightSidebar.task?.open ? rightSidebarWidth : "0px",
                    transition: rightSidebarTransition,
                    height: "100vh",
                    overflow: "scroll"
                }}
            >
                {rightSidebar.task?.open && <RightSidebar rightSidebar={rightSidebar} transition={rightSidebarTransition} width={rightSidebarWidth} />}
                <Box sx={{ paddingBottom: "32px" }} />
            </Box>
        </Box>
    );
};

export default Layout;
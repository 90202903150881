import TotalEntriesAndHours from "../DataTable/TableComponents/TotalEntriesAndHours";
import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { Grid } from "@mui/material";
import RuleIcon from "@mui/icons-material/Rule";

const CreateReportToolbar = props => {
    const totalComponent = <TotalEntriesAndHours totalEntries={props.rowCountState} totalHours={props.totalHours} />;

    return (
        <Grid container wrap="nowrap">
            <Grid container item spacing={2} alignItems="center" justifyContent="flex-start">
                <Grid item>
                    <Button onClick={() => props.apiRef.current.exportDataAsPrint()} size="medium">
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><PrintIcon sx={{ fontSize: 20 }} /></span>
                        Print
                    </Button>
                </Grid>
                {props.selectionIds.length > 0 &&
                    <Grid item>
                        <Button
                            onClick={() => props.setShowSelected(!props.showSelected)}
                            size="medium"
                            sx={{ backgroundColor: "#6096ba", color: "#e7ecef", "&:hover": { backgroundColor: "#6096ba" } }}
                        >
                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><RuleIcon sx={{ fontSize: 20 }} /></span>
                            {!props.showSelected ? <>Filter</> : <>Show All</>}
                        </Button>
                    </Grid>
                }
            </Grid>
            <Grid container item>{totalComponent}</Grid>
        </Grid>
    );
};

export default CreateReportToolbar;
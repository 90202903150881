import DetailsFields from "../../components/Timesheets/Standard/Fields/DetailsFields";
import { Grid, Typography, Button } from "@mui/material";
import TopBar from "../../components/Layout/Standard/TopBar";
import { useContext, useRef, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import ErrorBox from "../../components/Common/ErrorBox";
import dayjs from "dayjs";
import { combineDateTime } from "../../utils/utils";
import { axiosInstance } from "../../utils/utils";
import { useTranslation } from "react-i18next";

const AddTime = () => {
    const [userContext, setUserContext] = useContext(UserContext);
    const topRef = useRef(null);
    const navigate = useNavigate();
    const [disableButton, setDisableButton] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [, setModified] = useState(false);
    const loaded = useRef(false);
    const { t } = useTranslation();

    const [details, setDetails] = useState({
        startDate: dayjs(new Date()),
        startTime: null,
        endDate: null,
        endTime: null,
        project: "",
        area: "",
        description: "",
        breakMinutes: ""
    });

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const startDate = combineDateTime(details.startDate, details.startTime);
        const endDate = combineDateTime(details.endDate, details.endTime);

        let body = { ...details };
        delete body["startTime"];
        delete body["endTime"];
        body = { ...body, startDate: startDate, endDate: endDate };

        const url = process.env.REACT_APP_API_URL + "/timesheets";
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.post(url, body, config)
            .then(res => {
                navigate("/main", { replace: true, state: { snackbarMessage: t("addTime.submittedMessage"), goToFirstPage: true } });
            })
            .catch(err => {
                setErrorMessage(t("addTime.errorMessage"));
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    useEffect(() => {
        const fieldsNotEmpty = () => {
            const startDate = dayjs(combineDateTime(details.startDate, details.startTime));
            const endDate = dayjs(combineDateTime(details.endDate, details.endTime));
            const requiredDetails = { ...details };
            const validDateRange = startDate.isBefore(endDate);

            return Object.values(requiredDetails).every(value => value !== "" && value !== undefined && value !== null) && validDateRange;
        };

        if (loaded.current) {
            setModified(true);
        } else {
            loaded.current = true;
        }

        setDisableButton(!fieldsNotEmpty());
    }, [details]);

    useEffect(() => {
        if (!topRef.current) return;
        topRef.current.scrollIntoView();
    }, [topRef]);

    return (
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <Grid container item sx={{ justifyContent: "center" }}>
                <TopBar title={t("addTime.heading")} enableBack modified={false} />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">{t("addTime.subheading")}</Typography>
            </Grid>
            <Grid container item sx={{ justifyContent: "center" }}>
                <form onSubmit={handleSubmit} style={{ maxWidth: "650px" }}>
                    <DetailsFields details={details} setDetails={setDetails} loaded={loaded} />
                    <br />
                    <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                    <Button disabled={disableButton} type="submit" sx={{ width: "100%", marginTop: "30px", height: "45px", marginBottom: "35px" }}>{t("addTime.add")}</Button>
                </form>
            </Grid>
        </Grid >
    );
};

export default AddTime;
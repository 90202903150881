import { Button, IconButton, Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import FieldsOutline from "../../Common/FieldsOutline";
import DetailsFields from "../Fields/DetailsFields";
import DeleteEntryDialog from "../../Common/DeleteEntryDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorBox from "../../Common/ErrorBox";
import { UserContext } from "../../../context/UserContext";
import { axiosInstance } from "../../../utils/utils";

const EditModalComponent = props => {
    const [entry, setEntry] = useState({
        _id: "",
        firstName: "",
        middleInitial: "",
        lastName: "",
        username: "",
        password: "",
        company: "",
        active: false,
        locked: false,
        attempts: 0,
        language: ""
    });

    const [disableButton, setDisableButton] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);
    const [openDelete, setOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const loaded = useRef(false);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const url = process.env.REACT_APP_API_URL + "/users/" + props.edit._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const body = { ...entry };

        if (body.attempts === 9 && entry.locked === false) {
            body.attempts = 0;
        } else if (entry.locked === true) {
            body.attempts = 9;
            body.last = new Date();
        }

        delete body.locked;

        axiosInstance.post(url, body, config)
            .then(res => {
                props.setEdit({ ...props.edit, "open": false });
                props.setSnackbarMessage("User updated");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_URL + "/users/" + props.edit._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.delete(url, config)
            .then(res => {
                props.setEdit({ ...props.edit, "open": false });
                props.setSnackbarMessage("User deleted");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    useEffect(() => {
        const fetchEntry = () => {
            const url = process.env.REACT_APP_API_URL + '/users?ids=["' + props.edit._id + '"]';
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    const unlockInterval = 1000 * 60 * 60;

                    setEntry({
                        _id: res.data[0]._id,
                        firstName: res.data[0].firstName,
                        lastName: res.data[0].lastName,
                        middleInitial: res.data[0].middleInitial,
                        company: res.data[0].company,
                        username: res.data[0].username,
                        active: res.data[0].active,
                        attempts: res.data[0].attempts,
                        locked: res.data[0].attempts === 9 && new Date() - new Date(res.data[0].last) < unlockInterval ? true : false,
                        language: res.data[0].language
                    });
                })
                .catch(err => {
                    setErrorMessage(err.response.data);
                    setShowErrorMessage(true);
                });
        };

        fetchEntry();
    }, [props.edit._id, userContext, setUserContext]);

    const setEdit = props.setEdit;

    useEffect(() => {
        const fieldsNotEmpty = () => {
            const requiredFields = ["username"];
            return requiredFields.every(field => entry[field]);
        };

        if (!isLoading) {
            if (loaded.current) {
                setEdit(edit => ({ ...edit, modified: true }));
            } else {
                loaded.current = true;
            }
        }

        setDisableButton(!fieldsNotEmpty());
    }, [entry, isLoading, setEdit]);

    useEffect(() => setIsLoading(entry._id === ""), [entry]);

    return (!isLoading &&
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields values={entry} setValues={setEntry} options={props.options} showLockedStatus />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item>
                    <Grid container item xs={6} alignItems="center">
                        <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                        <Grid item sx={{ mr: 2.5 }}>
                            <Button variant="outlined" onClick={() => props.setEdit({ ...props.edit, open: false, modified: false })}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                disabled={disableButton}
                                sx={{ backgroundColor: "rgba(42, 157, 143, 0.6)", color: "#495464", "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" } }}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openDelete && <DeleteEntryDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} />}
        </form>
    );
};

export default EditModalComponent;
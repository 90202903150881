import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const DeleteEntryDialog = props => {
    const handleClose = () => { props.setOpen(false) };

    return (
        <Dialog open={props.open} onClose={handleClose} PaperProps={{ style: { backgroundColor: "#274c77", padding: 30 } }}>
            <DialogTitle color="#e7ecef">{props.title ? props.title : "Confirm Delete"}</DialogTitle>
            <DialogContent><DialogContentText>{props.text ? props.text : "Are you sure you want to delete?"}</DialogContentText></DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={{ backgroundColor: "transparent", color: "#e7ecef", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                    {props.cancelText ? props.cancelText : "Cancel"}
                </Button>
                <Button onClick={props.handleDelete} sx={{ backgroundColor: "#e76161", color: "#e7ecef", "&:hover": { backgroundColor: "#e76161" } }}>
                    {props.deleteText ? props.deleteText : "Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteEntryDialog;
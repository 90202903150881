import dayjs from "dayjs";
import axios from "axios";
import { useEffect } from "react";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const combineDateTime = (date, time) => {
    date = dayjs(date).format("YYYY-MM-DD");
    time = dayjs(time).format("HH:mm");
    return dayjs(`${date}T${time}`).toDate();
};

export const getBreakMinutes = (startDate, endDate) => {
    const twelve = dayjs(combineDateTime(startDate, dayjs(new Date()).set("hour", 12).set("minute", 0).set("second", 0).set("millisecond", 0)));
    const twelveThirty = dayjs(combineDateTime(startDate, dayjs(new Date()).set("hour", 12).set("minute", 30).set("second", 0).set("millisecond", 0)));

    if (endDate.diff(startDate, "hours") >= 24) {
        return 30;
    }

    let breakStart = dayjs(combineDateTime(startDate, twelve));
    let breakEnd = dayjs(combineDateTime(startDate, twelveThirty));

    if (startDate.isSameOrBefore(twelve) && endDate.isSameOrAfter(twelveThirty)) {
        breakStart = twelve;
        breakEnd = twelveThirty;
    } else if (startDate.isSameOrAfter(twelve) && endDate.isSameOrBefore(twelveThirty)) {
        breakStart = startDate;
        breakEnd = endDate;
    } else if (startDate.isSameOrAfter(twelve) && startDate.isSameOrBefore(twelveThirty) && endDate.isSameOrAfter(twelveThirty)) {
        breakStart = startDate;
        breakEnd = twelveThirty;
    } else if (startDate.isSameOrBefore(twelve) && endDate.isSameOrAfter(twelve) && endDate.isSameOrBefore(twelveThirty)) {
        breakStart = twelve;
        breakEnd = endDate;
    } else if (startDate.isSameOrBefore(twelve) && endDate.isSameOrBefore(twelve)) {
        breakStart = startDate;
        breakEnd = startDate;
    } else if (startDate.isSameOrAfter(twelveThirty) && endDate.isSameOrAfter(twelveThirty)) {
        breakStart = startDate;
        breakEnd = startDate;
    }

    const breakMinutes = breakEnd.diff(breakStart, "minutes", true);

    return breakMinutes >= 0 ? breakMinutes : 0;
};

export const refreshToken = setUserContext => {
    const url = process.env.REACT_APP_API_URL + "/users/refreshtoken";
    const body = {};
    const config = { withCredentials: true };

    axios.post(url, body, config).then((res => setUserContext(userContext => ({ ...userContext, ...res.data })))).catch(err => { });
};

export const axiosInstance = axios.create();

let tokenRequest;

const resetTokenRequest = () => {
    tokenRequest = null;
};

const getToken = config => {
    if (!tokenRequest) {
        tokenRequest = new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + "/users/refreshtoken";
            const body = {};
            const configuration = { withCredentials: true };

            axios.post(url, body, configuration)
                .then(res => {
                    config.setUserContext(userContext => ({ ...userContext, ...res.data }));
                    config.headers.Authorization = "Bearer " + res.data.token;
                    return resolve(config);
                })
                .catch(err => {
                    return reject(err);
                });
        });

        tokenRequest.then(resetTokenRequest, resetTokenRequest);
    }

    return tokenRequest;
};

axiosInstance.interceptors.response.use((res) => res,
    (err) => {
        if (err.response && err.response.status === 401) {
            return getToken(err.config).then(res => {
                return axios(err.config);
            });
        } else {
            return Promise.reject(err);
        }
    }
);

export const usePullToRefresh = (ref, onTrigger) => {
    useEffect(() => {
        const el = ref.current;
        if (!el) return;

        const handleTouchStart = startEvent => {
            const el = ref.current;
            if (!el) return;
            const parentEl = el.parentNode;

            const initialY = startEvent.touches[0].clientY;

            const addPullIndicator = el => {
                const indicator = el.querySelector(".pull-indicator");

                if (indicator) {
                    if (indicator.classList.contains("flip")) {
                        indicator.classList.remove("flip");
                    }

                    return;
                }

                const pullIndicator = document.createElement("div");
                pullIndicator.className = "pull-indicator";
                pullIndicator.innerHTML = "<span>↓</span>";
                el.appendChild(pullIndicator);
            };

            const removePullIndicator = el => {
                const pullIndicator = el.querySelector(".pull-indicator");

                if (pullIndicator) {
                    pullIndicator.remove();
                }
            };

            const flipArrow = el => {
                const pullIndicator = el.querySelector(".pull-indicator");

                if (pullIndicator && !pullIndicator.classList.contains("flip")) {
                    pullIndicator.classList.add("flip");
                }
            };

            const triggerThreshold = 450;
            const showIndicatorThreshold = 50;

            const handleTouchMove = moveEvent => {
                const el = ref.current;
                if (!el) return;

                const appr = x => {
                    const max = 128;
                    const k = 0.4;

                    return max * (1 - Math.exp((-k * x) / max));
                };

                const currentY = moveEvent.touches[0].clientY;
                const dy = currentY - initialY;
                if (dy < 0) return;

                el.style.transform = `translateY(${appr(dy)}px)`;

                if (dy > triggerThreshold) {
                    flipArrow(parentEl);
                } else if (dy > showIndicatorThreshold) {
                    addPullIndicator(parentEl);
                } else {
                    removePullIndicator(parentEl);
                }
            };

            const handleTouchEnd = endEvent => {
                const el = ref.current;
                if (!el) return;

                const onTransitionEnd = () => {
                    const el = ref.current;
                    if (!el) return;

                    el.style.transition = "";
                    el.removeEventListener("transitionend", onTransitionEnd);
                };

                el.style.transform = "translateY(0)";
                el.style.transition = "transform 0.2s";

                removePullIndicator(el.parentNode);

                const y = endEvent.changedTouches[0].clientY;
                const dy = y - initialY;

                if (dy > triggerThreshold) {
                    onTrigger();
                }

                el.addEventListener("transitionend", onTransitionEnd);

                el.removeEventListener("touchmove", handleTouchMove);
                el.removeEventListener("touchend", handleTouchEnd);
            };

            el.addEventListener("touchmove", handleTouchMove);
            el.addEventListener("touchend", handleTouchEnd);
        };

        el.addEventListener("touchstart", handleTouchStart);

        return () => el.removeEventListener("touchstart", handleTouchStart);
    }, [ref, onTrigger]);
};
import { useState, useEffect, useRef, useMemo } from "react";
import Title from "../../components/Common/Title";
import CreateReportTable from "../../components/CreateReport/CreateReportTable";
import CreateReportDataFilter from "../../components/CreateReport/CreateReportDataFilter";
import ColumnSelector from "../../components/DataTable/ColumnSelector";
import { Grid, Divider, Link } from "@mui/material";
import TheSnackbar from "../../components/Common/TheSnackbar";
import { useNavigate, useOutletContext } from "react-router-dom";

const CreateReport = () => {
    const [fetchedData, setFetchedData] = useState([]);
    const [title, setTitle] = useState("");
    const columns = useMemo(() => ["fullName", "company", "date", "project", "description", "area", "startDate", "endDate", "breakMinutes", "hours"], []);
    const columnsToDisplayName = {
        fullName: "name",
        company: "company",
        date: "date",
        project: "project",
        description: "description",
        area: "area",
        startDate: "start",
        endDate: "end",
        breakMinutes: "break",
        hours: "hours"
    };
    const [selectedColumns, setSelectedColumns] = useState({ ...columns.reduce((columns, column) => ({ ...columns, [column]: true }), {}), company: false });
    const topRef = useRef();
    const [rowCountState, setRowCountState] = useState(0);
    const [fetchedRowCountState, setFetchedRowCountState] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: window.localStorage.getItem("createReportTablePageSize") !== null ? parseInt(window.localStorage.getItem("createReportTablePageSize")) : 250
    });
    const [totalHours, setTotalHours] = useState(0);
    const [fetchedTotalHours, setFetchedTotalHours] = useState(0);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [project, setProject] = useState();
    const [persons, setPersons] = useState([]);
    const [areas, setAreas] = useState([]);
    const [, setRightSidebar] = useOutletContext();

    useEffect(() => {
        setRightSidebar({ open: false });
    }, [setRightSidebar]);

    const navigate = useNavigate();

    useEffect(() => {
        setTotalHours(fetchedTotalHours);
    }, [fetchedTotalHours]);

    useEffect(() => {
        setRowCountState(fetchedRowCountState);
    }, [fetchedRowCountState]);

    return (
        <div ref={topRef}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Title title="Create Report" subtitle="Generate project reports" />
            <CreateReportDataFilter
                setTitle={setTitle}
                data={fetchedData}
                setData={setFetchedData}
                setTotalHours={setFetchedTotalHours}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                setRowCountState={setFetchedRowCountState}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                setLoading={setLoading}
                project={project}
                setProject={setProject}
                persons={persons}
                setPersons={setPersons}
                areas={areas}
                setAreas={setAreas}
            />
            <br />
            <Grid container spacing={2.5} alignItems="center" sx={{ marginBottom: "15px" }}>
                <Grid item><ColumnSelector columns={columns} selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} columnsToDisplayName={columnsToDisplayName} /></Grid>
                <Grid item sx={{ marginLeft: "10px", marginRight: "15px" }}><Divider orientation="vertical" sx={{ height: "45px" }} /></Grid>
                <Grid item>
                    <Link onClick={() => navigate("/projects")} sx={{ cursor: "pointer", textDecoration: "underline", "&:hover": { textDecoration: "underline" } }}>
                        List of Projects
                    </Link>
                </Grid>
                <Grid item sx={{ marginLeft: "10px", marginRight: "15px" }}><Divider orientation="vertical" sx={{ height: "45px" }} /></Grid>
                <Grid item>
                    <Link onClick={() => navigate("/missingtimesheets")} sx={{ cursor: "pointer", textDecoration: "underline", "&:hover": { textDecoration: "underline" } }}>
                        Missing Report
                    </Link>
                </Grid>
            </Grid>
            <CreateReportTable
                data={fetchedData}
                title={title}
                selectedColumns={selectedColumns}
                rowCountState={rowCountState}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                fetchedRowCountState={fetchedRowCountState}
                totalHours={totalHours}
                fetchedTotalHours={fetchedTotalHours}
                setTotalHours={setTotalHours}
                setRowCountState={setRowCountState}
                topRef={topRef}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                setLoading={setLoading}
                setSelectedColumns={selectedColumns}
                setProject={setProject}
                setPersons={setPersons}
                setAreas={setAreas}
            />
        </div>
    );
};

export default CreateReport;
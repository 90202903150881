import MissingTimesheetsTable from "../../components/MissingTimesheets/Supervisor/MissingTimesheetsTable";
import MissingTimesheetsDataFilter from "../../components/MissingTimesheets/Supervisor/MissingTimesheetsDataFilter";
import { Grid, Typography } from "@mui/material";
import TopBar from "../../components/Layout/Standard/TopBar";
import { useContext, useRef, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import TheSnackbar from "../../components/Common/TheSnackbar";
import { useLocation, useNavigate } from "react-router-dom";

const MissingTimesheets = () => {
    const [userContext] = useContext(UserContext);
    const topRef = useRef(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [rowCountState, setRowCountState] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: userContext.MissingTimesheetsPage ? userContext.MissingTimesheetsPage : 0,
        pageSize: window.localStorage.getItem("missingTimesheetsPageSize") !== null ? parseInt(window.localStorage.getItem("missingTimesheetsPageSize")) : 50
    });
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (location.state?.snackbarMessage) {
            setSnackbarMessage(location.state.snackbarMessage);
            setOpenSnackbar(true);
            navigate(".", { replace: true });
        }
    }, [location, navigate]);

    return (
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Grid container item sx={{ justifyContent: "center" }}>
                <TopBar title={"Missing Time"} enableBack />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">People with no time below</Typography>
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <MissingTimesheetsDataFilter
                    setData={setData}
                    setRowCountState={setRowCountState}
                    paginationModel={paginationModel}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarMessage={setSnackbarMessage}
                    setTitle={setTitle}
                    setLoading={setLoading}
                />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                    {title}
                </Typography>
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <MissingTimesheetsTable
                    topRef={topRef}
                    data={data}
                    rowCountState={rowCountState}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    setOpenSnackbar={setOpenSnackbar}
                    openSnackbar={openSnackbar}
                    snackbarMessage={snackbarMessage}
                    loading={loading}
                />
            </Grid>
        </Grid>
    );
};

export default MissingTimesheets;
import DataFilter from "../DataTable/DataFilter.js";
import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils.js";

const ProjectsDataFilter = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [dates, setDates] = useState({
        startDate: userContext.projectsDataFilterStartDate !== undefined ? dayjs(userContext.projectsDataFilterStartDate) : dayjs(new Date()).subtract(3, "month"),
        endDate: userContext.projectsDataFilterEndDate !== undefined ? dayjs(userContext.projectsDataFilterEndDate) : dayjs(new Date())
    });
    const [persons, setPersons] = useState([]);
    const initialized = useRef(false);
    const [updating, setUpdating] = useState(true);

    const setData = props.setData;
    const setRowCountState = props.setRowCountState;
    const setLoading = props.setLoading;
    const setSnackbarMessage = props.setSnackbarMessage;
    const setOpenSnackbar = props.setOpenSnackbar;
    const setPaginationModel = props.setPaginationModel;
    const setOptions = props.setOptions;

    const fields = [
        { name: "person", label: "Person", options: props.options.persons, state: persons, setState: setPersons, multiple: true }
    ];

    useEffect(() => {
        if (dates.startDate) {
            setUserContext(userContext => ({ ...userContext, projectsDataFilterStartDate: dates.startDate }));
        }

        if (dates.endDate) {
            setUserContext(userContext => ({ ...userContext, projectsDataFilterEndDate: dates.endDate }));
        }
    }, [dates, setUserContext]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setLoading(true);
        }
    }, [setData, setLoading, props.paginationModel]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setPaginationModel(paginationModel => ({ ...paginationModel, page: 0 }));
        }
    }, [persons, setPaginationModel, setData]);

    useEffect(() => {
        if (initialized.current) {
            setPersons([]);
        }
    }, [dates]);

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + "/projects";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            let query = "";

            if (Date.parse(dates.startDate) && Date.parse(dates.endDate)) {
                const startDate = new Date(dates.startDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                const endDate = new Date(dates.endDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                query += "&start_date=" + startDate + "&end_date=" + endDate;
            }

            if (props.paginationModel) {
                query += "&page=" + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;
            }

            if (persons.length > 0) {
                query += "&persons=[";

                persons.forEach(person => {
                    query += '"' + encodeURIComponent(person) + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            if (query !== "") {
                url += "?" + query.slice(1);
            }

            axiosInstance.get(url, config)
                .then(res => {
                    setData(res.data.entries);
                    setRowCountState(res.data.totalEntries);
                    setOptions(options => ({
                        ...options,
                        persons: persons.length > 0 ? options.persons : res.data.personOptions
                    }));
                    setLoading(false);
                    setUpdating(false);
                    initialized.current = true;
                })
                .catch(err => {
                    setLoading(false);
                    setUpdating(false);
                    setSnackbarMessage("Unable to load projects data");
                    setOpenSnackbar(true);
                });
        };

        if (props.loading || updating) {
            fetchData();
        }
    }, [props.loading, updating, setUpdating, dates, userContext, setUserContext, props.paginationModel, persons, setData, setRowCountState, setLoading, setSnackbarMessage, setOpenSnackbar, setOptions]);

    return (
        <DataFilter fields={fields} dates={dates} setDates={setDates} data={props.data} />
    );
};

export default ProjectsDataFilter;
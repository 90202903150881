import TimesheetsTable from "../../components/Timesheets/Standard/TimesheetsTable";
import TimesheetsToolbar from "../../components/Timesheets/Standard/TimesheetsToolbar";
import TimesheetsDataFilter from "../../components/Timesheets/Standard/TimesheetsDataFilter";
import { Grid, Typography } from "@mui/material";
import TopBar from "../../components/Layout/Standard/TopBar";
import { useContext, useRef, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import TheSnackbar from "../../components/Common/TheSnackbar";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Main = () => {
    const [userContext, setUserContext] = useContext(UserContext);
    const topRef = useRef(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [rowCountState, setRowCountState] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: userContext.mainPage ? userContext.mainPage : 0,
        pageSize: userContext.userType === "standard" ? 5 : 10
    });
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (location.state?.goToFirstPage) {
            setPaginationModel(paginationModel => ({ ...paginationModel, page: 0 }));
            setUserContext(userContext => ({ ...userContext, mainPage: 0 }));
        }

        if (location.state?.snackbarMessage) {
            setSnackbarMessage(location.state.snackbarMessage);
            setOpenSnackbar(true);
            window.history.replaceState({}, "");
        }
    }, [location, setUserContext]);

    return (
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Grid container item sx={{ justifyContent: "center" }}>
                {userContext.firstName || userContext.lastName ?
                    <TopBar title={t("main.greeting") + (userContext.firstName ? " " + userContext.firstName : "") + (userContext.lastName ? " " + userContext.lastName : "")} />
                    :
                    <TopBar title={t("main.greeting") + " " + userContext.username.substring(0, 1).toUpperCase() + userContext.username.substring(1)} />
                }
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                {userContext.userType === "standard" ?
                    <Typography color="black">{t("main.subheading")}</Typography>
                    :
                    <Typography color="black">See everyone's hours below</Typography>
                }
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <TimesheetsDataFilter
                    setData={setData}
                    setRowCountState={setRowCountState}
                    paginationModel={paginationModel}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarMessage={setSnackbarMessage}
                    setLoading={setLoading}
                    loading={loading}
                />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <TimesheetsToolbar />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <TimesheetsTable
                    topRef={topRef}
                    data={data}
                    rowCountState={rowCountState}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    setOpenSnackbar={setOpenSnackbar}
                    openSnackbar={openSnackbar}
                    snackbarMessage={snackbarMessage}
                    loading={loading}
                />
            </Grid>
        </Grid>
    );
};

export default Main;
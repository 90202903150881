import { useMemo, useCallback } from "react";
import DataTable from "../DataTable/DataTable";
import DataTableFooter from "../DataTable/TableComponents/DataTableFooter";
import DataTableToolbar from "../DataTable/TableComponents/DataTableToolbar";
import TotalEntriesAndHours from "../DataTable/TableComponents/TotalEntriesAndHours";
import { useGridApiRef } from "@mui/x-data-grid";
import ProjectsToolbar from "./ProjectsToolbar";
import TableSummary from "../DataTable/TableComponents/TableSummary";
import { Grid } from "@mui/material";

const ProjectsTable = props => {
    const rows = useMemo(() => props.data, [props.data]);
    const apiRef = useGridApiRef();

    const columns = useMemo(() => {
        const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };

        return [
            {
                field: "project",
                headerName: "Project",
                minWidth: 500,
                flex: 1,
                renderCell: params => <div {...cellParams}>{params.value}</div>
            }
        ];
    }, []);

    const CustomFooter = useCallback(() => { return (<DataTableFooter rows={rows} />) }, [rows]);

    const CustomToolbar = useCallback(() => {
        const totalComponent = <TotalEntriesAndHours totalEntries={props.rowCountState} />;
        const toolbarComponent = <TableSummary totalComponent={totalComponent} title="Projects" columns={columns} />;

        return (<DataTableToolbar toolbarComponent={toolbarComponent} showToolbarContainer />)
    }, [props.rowCountState, columns]);

    const handlePageChange = useCallback(newPaginationModel => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("projectsTablePageSize", newPaginationModel.pageSize);

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    }, [props]);

    return (
        <div>
            <ProjectsToolbar
                apiRef={apiRef}
                options={props.options}
                setSnackbarMessage={props.setSnackbarMessage}
                setOpenSnackbar={props.setOpenSnackbar}
                rowCountState={props.rowCountState}
            />
            <Grid container item sx={{ border: "1px dashed rgba(0, 0, 0, 0.15)", backgroundColor: "rgba(0, 0, 0, 0.025)", mt: 1.5 }}>
                <Grid
                    item
                    sx={{
                        width: "11in",
                        backgroundColor: "rgba(255, 255, 255, 0.25)",
                        padding: "0.5in",
                        "& .MuiTypography-root": { color: "black" }
                    }}
                >
                    <DataTable
                        columns={columns}
                        rows={rows}
                        customFooter={CustomFooter}
                        customToolbar={CustomToolbar}
                        paginationMode="server"
                        paginationModel={props.paginationModel}
                        handlePageChange={handlePageChange}
                        rowCountState={props.rowCountState}
                        loading={props.loading}
                        apiRef={apiRef}
                        sx={{
                            fontSize: "13px",
                            color: "black",
                            border: 0,
                            "& .MuiTypography-root": { color: "black" },
                            "& .MuiDataGrid-cell": {
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                display: "flex",
                                alignItems: "flex-start",
                                border: 0,
                                "&:focus-within, &:focus": { outline: "none" }
                            },
                            "& .MuiDataGrid-overlay": { bgcolor: "transparent" },
                            "& .MuiDataGrid-columnHeaders": { borderColor: "#bbbfca" },
                            "& .MuiDataGrid-iconSeparator": { color: "#bbbfca" },
                            "& .MuiDataGrid-footerContainer": { border: 0 },
                            "&.MuiDataGrid-root": { lineHeight: "normal" },
                            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": { display: "none" },
                            "& .MuiCheckbox-root": { padding: 0 },
                            "& .MuiTablePagination-selectLabel": { color: "black" },
                            "& .MuiInputBase-root": { color: "black" },
                            "& .MuiTablePagination-displayedRows": { color: "black" },
                            "& .MuiTablePagination-actions": { color: "black" },
                            "@media print": {
                                color: "#000000",
                                display: "table",
                                ".MuiDataGrid-footerContainer": { display: "none" },
                                "& .MuiDataGrid-virtualScroller": { display: "table", overflow: "visible" },
                                ".css-i4bv87-MuiSvgIcon-root": { color: "white" },
                                "@page": { size: "landscape" }
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </div >
    );
};

export default ProjectsTable;
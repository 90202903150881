const descriptions = [
    "Apply caulking",
    "Apply high build",
    "Apply Hullgard",
    "Assemble hardware",
    "Clean hardware",
    "Clean up",
    "Cover",
    "Cover and protect",
    "Disassemble hardware",
    "Fairing",
    "Fiberglass work",
    "Grind",
    "Install hardware",
    "Paint",
    "Polish",
    "Polish hardware",
    "Polish stainless steel",
    "Prepare",
    "Prepare and apply high build",
    "Prepare and primer",
    "Prepare and topcoat",
    "Primer",
    "Propspeed",
    "Protect",
    "Remove caulking",
    "Remove hardware",
    "Repair blisters",
    "Sand",
    "Sandblast",
    "Scaffolding",
    "Scaffolding and shrink wrap",
    "Shrink wrap",
    "Spray gelcoat",
    "Spray non-skid",
    "Topcoat",
    "Touch up",
    "Transport parts",
    "Varnish",
    "Wash",
    "Zincs"
];

export default descriptions;
import { Grid } from "@mui/material";
import Title from "../../components/Common/Title";
import UserInfoFields from "../../components/Settings/UserInfoFields";
import UsersFields from "../../components/Settings/UsersFields";
import TheSnackbar from "../../components/Common/TheSnackbar";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import FieldsOutline from "../../components/Common/FieldsOutline";
import { useOutletContext } from "react-router-dom";

const Settings = () => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [userContext] = useContext(UserContext);
    const width = "65%";
    const gridItemWidth = "300px";
    const [, setRightSidebar] = useOutletContext();

    useEffect(() => {
        setRightSidebar({ open: false });
    }, [setRightSidebar]);

    return (
        <div>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Title title="Settings" subtitle="Change settings" />
            <Grid container direction="column">
                <Grid container item>
                    <FieldsOutline title="User Info" width={width}>
                        <UserInfoFields setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} gridItemWidth={gridItemWidth} />
                    </FieldsOutline>
                </Grid>
                {userContext.userType === "admin" &&
                    <Grid container item sx={{ mt: 3 }}>
                        <FieldsOutline title="Privileged Users" width={width}>
                            <UsersFields setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} gridItemWidth={gridItemWidth} />
                        </FieldsOutline>
                    </Grid>
                }
            </Grid>
        </div>
    );
};

export default Settings;
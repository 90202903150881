import { Button, Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import DetailsFields from "../Fields/DetailsFields";
import FieldsOutline from "../../Common/FieldsOutline";
import ErrorBox from "../../Common/ErrorBox";
import { UserContext } from "../../../context/UserContext";
import { axiosInstance } from "../../../utils/utils";

const AddModal = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [disableButton, setDisableButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const loaded = useRef(false);

    const [details, setDetails] = useState({
        firstName: "",
        middleInitial: "",
        lastName: "",
        username: "",
        newPassword: "",
        company: "Luu Marine",
        active: true,
        language: "en",
        location: "",
    });

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        let body = { ...details };
        body.password = body.newPassword;
        delete body["newPassword"];

        const url = process.env.REACT_APP_API_URL + "/users";
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.post(url, body, config)
            .then(res => {
                props.setAdd({ ...props.add, "open": false });
                props.setSnackbarMessage("User added");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const setAdd = props.setAdd;

    useEffect(() => {
        const fieldsNotEmpty = () => {
            const requiredFields = ["username", "newPassword"];
            return requiredFields.every(field => details[field]);
        };

        if (loaded.current) {
            setAdd(add => ({ ...add, modified: true }));
        } else {
            loaded.current = true;
        }

        setDisableButton(!fieldsNotEmpty());
    }, [details, setAdd]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields values={details} setValues={setDetails} options={props.options} />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item justifyContent="flex-end">
                    <Grid item sx={{ mr: 2.5 }}>
                        <Button variant="outlined" onClick={() => props.setAdd({ ...props.add, open: false, modified: false })}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="submit"
                            disabled={disableButton}
                            sx={{ backgroundColor: "rgba(42, 157, 143, 0.6)", color: "#495464", "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" } }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddModal;
import { Button, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import YachtProfileStarboard from "../Vectors/YachtProfileStarboard";
import YachtBehind from "../Vectors/YachtBehind";
import Parts from "../Vectors/Parts";
import { useTranslation } from "react-i18next";

const MainComponent = props => {
    const [color] = useState("rgba(255, 240, 3, 0.9)");
    const [disableButton, setDisableButton] = useState(true);
    const { t } = useTranslation();

    const handleClick = (vector, id) => {
        if (props.selectedId === id && props.selectedVector === vector) {
            props.setSelectedVector("");
            props.setSelectedId("");
        } else {
            props.setSelectedVector(vector);
            props.setSelectedId(id);
        }
    };

    const setSelectedGeneralArea = props.setSelectedGeneralArea;

    useEffect(() => {
        setSelectedGeneralArea(props.selectedId);
    }, [props.selectedId, setSelectedGeneralArea]);

    const handleNext = () => {
        props.setModalComponent(props.selectedId);
    };

    useEffect(() => {
        setDisableButton(props.selectedGeneralArea === "");
    }, [props.selectedGeneralArea]);

    return (
        <>
            <Grid container item alignItems="center" justifyContent="center" sx={{ paddingBottom: "25px" }}>
                <Typography variant="h5">{t("chooseArea.heading")}</Typography>
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">{t("chooseArea.subheading")}</Typography>
            </Grid>
            <Grid container direction="column">
                <Grid container item>
                    <YachtProfileStarboard selectedId={props.selectedId} color={color} handleClick={handleClick} selected={props.selectedVector === "YachtProfileStarboard"} />
                </Grid>
                <Grid container item>
                    <YachtBehind selectedId={props.selectedId} color={color} handleClick={handleClick} selected={props.selectedVector === "YachtBehind"} />
                </Grid>
                <Grid container item justifyContent="center" onClick={() => handleClick("Parts", "parts")} sx={{ mt: "50px" }}>
                    <Typography sx={{ fontWeight: "bold" }}>{t("chooseArea.parts.title")}</Typography>
                </Grid>
                <Grid container item>
                    <Parts selectedId={props.selectedId} color={color} handleClick={handleClick} selected={props.selectedVector === "Parts"} />
                </Grid>
                <Grid container item sx={{ mt: 2.5 }}>
                    <Button
                        disabled={disableButton}
                        onClick={handleNext}
                        sx={{
                            width: "100%",
                            marginTop: "20px",
                            height: "45px",
                            backgroundColor: "rgba(42, 157, 143, 0.6)",
                            color: "#495464",
                            "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" }
                        }}>
                        {t("chooseArea.next")}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default MainComponent;
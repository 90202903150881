import { Grid, TextField, Autocomplete, Typography, IconButton, InputAdornment } from "@mui/material";
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarIcon, TimeIcon } from "@mui/x-date-pickers";
import { useState, useEffect, useContext, useCallback, useRef } from "react";
import SelectAreaModal from "../Modals/SelectAreaModal";
import { UserContext } from "../../../../context/UserContext";
import dayjs from "dayjs";
import { combineDateTime, getBreakMinutes } from "../../../../utils/utils";
import areas from "../../Lists/areas";
import descriptions from "../../Lists/descriptions";
import CheckIcon from "@mui/icons-material/Check";
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { axiosInstance } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";

const DetailsFields = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const headingParams = { variant: "body1", sx: { fontWeight: "bold", color: "#495464", mr: 1 } };
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: false, autoComplete: true, autoHighlight: false, blurOnSelect: true };
    const checkIconParams = { fontSize: "small", sx: { color: "rgba(42, 157, 143, 0.6)" } };
    const [openSelectArea, setOpenSelectArea] = useState(false);
    const [openStartDate, setOpenStartDate] = useState(false);
    const [openStartTime, setOpenStartTime] = useState(false);
    const [openEndTime, setOpenEndTime] = useState(false);
    const [options, setOptions] = useState({
        projects: [],
        areas: [],
        descriptions: descriptions
    });
    const [optionsDates] = useState({ startDate: dayjs(new Date()).subtract(1, "month"), endDate: dayjs(new Date()) });
    const { t } = useTranslation();
    const projectRef = useRef();
    const descriptionRef = useRef();
    const areaRef = useRef();

    const setDetails = props.setDetails;

    const pickerSlotProps = (setOpen, Icon, name, format) => {
        return ({
            textField: {
                InputProps: { placeholder: null, value: props.details[name] ? dayjs(props.details[name]).format(format) : "", endAdornment: (<InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={() => setOpen(true)}><Icon /></InputAdornment>) },
                readOnly: true,
                sx: { input: { cursor: "pointer" }, width: "100%" },
                onClick: () => setOpen(true),
                InputLabelProps: { shrink: true }
            }
        });
    };

    const handleChange = useCallback((name, value) => setDetails(pairs => ({ ...pairs, [name]: value })), [setDetails]);

    const handleDateChange = (name, value) => {
        if (!Date.parse(value)) {
            handleChange(name, null);
        } else {
            if (name === "endTime" && props.details.endTime === null) {
                value.$H += value.$H + 12;
                value.$d.setHours(value.$d.getHours() + 12);
            }

            handleChange(name, value);
        }
    };

    useEffect(() => {
        const areaOptions = [];

        Object.keys(areas).forEach(key => {
            areas[key].areas.forEach(area => areaOptions.push(area.text));
        });

        setOptions(options => ({ ...options, areas: areaOptions.sort() }));
    }, [setOptions]);

    const timesAreValid = () => {
        if (props.details.startDate && props.details.startTime && props.details.endDate && props.details.endTime) {
            const startDate = dayjs(combineDateTime(props.details.startDate, props.details.startTime));
            const endDate = dayjs(combineDateTime(props.details.endDate, props.details.endTime));
            return startDate.isBefore(endDate);
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (props.details.startDate && props.details.startTime && props.details.endTime && props.loaded.current) {
            if (props.details.startTime.isSameOrBefore(props.details.endTime)) {
                setDetails(details => ({ ...details, endDate: props.details.startDate }));
            } else {
                setDetails(details => ({ ...details, endDate: props.details.startDate.add(1, "day") }));
            }
        }
    }, [setDetails, props.details.startDate, props.details.startTime, props.details.endTime, props.loaded]);

    useEffect(() => {
        const fetchOptions = () => {
            let url = process.env.REACT_APP_API_URL + "/timesheets/options";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            let query = "";

            if (Date.parse(optionsDates.startDate) && Date.parse(optionsDates.endDate)) {
                const startDate = new Date(optionsDates.startDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                const endDate = new Date(optionsDates.endDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                query += "&start_date=" + startDate + "&end_date=" + endDate;
            }

            if (query !== "") {
                url += "?" + query.slice(1);
            }

            axiosInstance.get(url, config)
                .then(res => {
                    let url = process.env.REACT_APP_INVENTORY_APP_API_URL + "/inventory/projects/options";
                    const config = { params: { token: userContext.token }, userContext: userContext, setUserContext: setUserContext };

                    let query = "";

                    let startDate = dayjs(new Date()).subtract(1, "month");
                    let endDate = dayjs(new Date());
                    startDate = new Date(startDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                    endDate = new Date(endDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);

                    query += "&start_date=" + startDate + "&end_date=" + endDate;

                    if (query !== "") {
                        url += "?" + query.slice(1);
                    }

                    axiosInstance.get(url, config)
                        .then(rslts => {
                            const projects = Array.from(new Set(res.data.projectOptions.concat(rslts.data.projectOptions))).sort();
                            setOptions(options => ({ ...options, projects: projects }));
                        })
                        .catch(err => {
                            setOptions(options => ({ ...options, projects: res.data.projectOptions }));
                        });
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchOptions();
    }, [userContext, setUserContext, optionsDates]);

    useEffect(() => {
        if (props.details.startDate && props.details.startTime && props.details.endDate && props.details.endTime && props.loaded.current) {
            const startDate = dayjs(combineDateTime(props.details.startDate, props.details.startTime));
            const endDate = dayjs(combineDateTime(props.details.endDate, props.details.endTime));
            handleChange("breakMinutes", getBreakMinutes(startDate, endDate));
        }
    }, [props.details.startDate, props.details.startTime, props.details.endDate, props.details.endTime, handleChange, props.loaded]);

    const isTouchScreenDevice = () => {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (err) {
            return false;
        }
    };

    return (
        <Grid container item spacing={4}>
            <Grid container item spacing={2.5} alignItems="center">
                <Grid container item alignItems="center">
                    <Typography {...headingParams}>{t("details.dateAndTime")}</Typography>
                    {timesAreValid() && <CheckIcon {...checkIconParams} />}
                </Grid>
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid item sx={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t("details.date")}
                            open={openStartDate}
                            onClose={() => setOpenStartDate(false)}
                            value={props.details.startDate}
                            onChange={value => handleDateChange("startDate", value)}
                            sx={{ width: "100%" }}
                            slotProps={pickerSlotProps(setOpenStartDate, CalendarIcon, "startDate", "ddd, MM/DD/YYYY")}
                            minDate={dayjs(new Date()).subtract(1, "week")}
                            maxDate={dayjs(new Date())}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid item sx={{ width: "50%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label={t("details.startTime")}
                            open={openStartTime}
                            onClose={() => setOpenStartTime(false)}
                            value={props.details.startTime}
                            onChange={value => handleDateChange("startTime", value)}
                            sx={{ width: "100%" }}
                            slotProps={pickerSlotProps(setOpenStartTime, TimeIcon, "startTime", "hh:mm a")}
                            timeSteps={{ minutes: 15 }}
                            minutesStep={15}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item sx={{ width: "50%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label={t("details.endTime")}
                            open={openEndTime}
                            onClose={() => setOpenEndTime(false)}
                            value={props.details.endTime}
                            onChange={value => handleDateChange("endTime", value)}
                            sx={{ width: "100%" }}
                            slotProps={pickerSlotProps(setOpenEndTime, TimeIcon, "endTime", "hh:mm a")}
                            timeSteps={{ minutes: 15 }}
                            minutesStep={15}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid container item xs={6} alignItems="center">
                    <Typography {...headingParams}>{t("details.boat")}</Typography>
                    {props.details.project && <CheckIcon {...checkIconParams} />}
                </Grid>
            </Grid>
            <Grid container item ref={projectRef}>
                <Autocomplete
                    {...autocompleteParams}
                    options={options.projects}
                    renderInput={params => (<TextField {...params} label={t("details.boatName")} value={props.details.project} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} InputLabelProps={{ shrink: true }} helperText={t("details.example") + ": Double Down"} />)}
                    onInputChange={(event, value) => handleChange("project", value)}
                    inputValue={props.details.project}
                    onOpen={() => {
                        if (isTouchScreenDevice()) {
                            setTimeout(() => {
                                projectRef.current.style.marginBottom = "90vh";
                                projectRef.current.scrollIntoView();
                            }, 100);
                        }
                    }}
                    onClose={() => projectRef.current.style.marginBottom = "0"}
                />
            </Grid>
            <Grid container item spacing={2.5}>
                <Grid container item xs={6} alignItems="center">
                    <Typography {...headingParams}>{t("details.work")}</Typography>
                    {props.details.description && props.details.area && <CheckIcon {...checkIconParams} />}
                </Grid>
            </Grid>
            <Grid container item ref={descriptionRef}>
                <Autocomplete
                    {...autocompleteParams}
                    options={options.descriptions}
                    renderInput={params => (<TextField {...params} label={t("details.description")} value={props.details.description} inputProps={{ ...params.inputProps, autoCapitalize: "sentences" }} InputLabelProps={{ shrink: true }} helperText={t("details.example") + ": Prepare and topcoat"} />)}
                    onInputChange={(event, value) => handleChange("description", value)}
                    inputValue={String(props.details.description)}
                    onOpen={() => {
                        if (isTouchScreenDevice()) {
                            setTimeout(() => {
                                descriptionRef.current.style.marginBottom = "90vh";
                                descriptionRef.current.scrollIntoView();
                            }, 100);
                        }
                    }}
                    onClose={() => descriptionRef.current.style.marginBottom = "0"}
                />
            </Grid>
            <Grid container item ref={areaRef}>
                <Grid item flex={1} ref={areaRef} sx={{ mr: 2.5 }}>
                    <Autocomplete
                        {...autocompleteParams}
                        options={options.areas}
                        renderInput={params => (<TextField {...params} label={t("details.area")} value={props.details.area} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} InputLabelProps={{ shrink: true }} helperText={t("details.example") + ": Main Deck House"} />)}
                        onInputChange={(event, value) => handleChange("area", value)}
                        inputValue={props.details.area}
                        ref={areaRef}
                        onOpen={() => {
                            if (isTouchScreenDevice()) {
                                setTimeout(() => {
                                    areaRef.current.style.marginBottom = "90vh";
                                    areaRef.current.scrollIntoView();
                                }, 100);
                            }
                        }}
                        onClose={() => areaRef.current.style.marginBottom = "0"}
                    />
                </Grid>
                <Grid item alignItems="center">
                    <IconButton onClick={() => setOpenSelectArea(true)} sx={{ color: "#495464", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                        <CropOriginalIcon sx={{ width: 40, height: 40 }} />
                    </IconButton>
                </Grid>
            </Grid>
            {openSelectArea && <SelectAreaModal open={openSelectArea} setOpen={setOpenSelectArea} setDetails={props.setDetails} />}
        </Grid >
    );
};

export default DetailsFields;
import { Grid, Typography, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMemo, useContext } from "react";
import TheSnackbar from "../../Common/TheSnackbar";
import { UserContext } from "../../../context/UserContext";

const MissingTimesheetsTable = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const rows = useMemo(() => props.data, [props.data]);

    const setOpenSnackbar = props.setOpenSnackbar;

    const handlePageChange = (newPaginationModel, scrollToTop = true) => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("missingTimesheetsPageSize", newPaginationModel.pageSize);
        setUserContext(userContext => ({ ...userContext, mainPage: newPaginationModel.page }));

        if (scrollToTop && props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView();
        }
    };

    const columns = [{
        field: "_id",
        headerName: "Time",
        flex: 1,
        renderCell: params => {
            return (
                <Paper
                    key={params.row._id}
                    sx={{
                        backgroundColor: "rgba(255, 255, 255, 0)",
                        display: "flex",
                        justifyContent: "center",
                        boxShadow: "none",
                        width: "100%"
                    }}
                >
                    <Grid container item direction="column">
                        <Grid item>
                            {userContext.userType === "supervisor" &&
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontSize: "17px" }}>
                                    {(params.row.index + 1) + ". " + params.row.fullName}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            );
        }
    }];

    return (
        <Grid container item>
            <TheSnackbar open={props.openSnackbar} setOpen={setOpenSnackbar} message={props.snackbarMessage} />
            <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                autoHeight
                disableRowSelectionOnClick
                disableColumnMenu
                getRowId={row => row._id}
                slots={{
                    noRowsOverlay: () => {
                        return <Typography variant="body1" align="center" sx={{ color: "#495464" }}>No employees found</Typography>;
                    }
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                paginationMode="server"
                paginationModel={props.paginationModel}
                onPaginationModelChange={handlePageChange}
                rowCount={props.rowCountState}
                loading={props.loading}
                sx={{
                    fontSize: 16,
                    border: 0,
                    "& .MuiDataGrid-cell": {
                        padding: "0 0 20px 0",
                        border: 0,
                        "&:focus-within, &:focus": { outline: "none" }
                    },
                    "& .MuiDataGrid-footerContainer": { border: 0 },
                    "& .MuiDataGrid-columnHeaders": { display: "none" },
                    "& .MuiDataGrid-row:hover": { borderRadius: "0px", backgroundColor: "transparent" }
                }}
            />
        </Grid>
    );
};

export default MissingTimesheetsTable;
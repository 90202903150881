import { useState, useContext, useEffect, useMemo, useCallback } from "react";
import TheModal from "../../Common/TheModal";
import AddModalComponent from "./ModalModalComponents/AddModalComponent";
import EditModalComponent from "./ModalModalComponents/EditModalComponent";
import EditMultipleModalComponent from "./ModalModalComponents/EditMultipleModalComponent";
import TotalEntriesAndHours from "../../DataTable/TableComponents/TotalEntriesAndHours";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import SyncIcon from "@mui/icons-material/Sync";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import SearchIcon from "@mui/icons-material/Search";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import { Grid, Button, Divider, CircularProgress, Box } from "@mui/material";
import DeleteEntryDialog from "../../Common/DeleteEntryDialog";
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import ChooseModalComponent from "./ModalModalComponents/ChooseModalComponent";
import { axiosInstance } from "../../../utils/utils";

const TimesheetsToolbar = (props) => {
    const [add, setAdd] = useState({ open: false, title: "Add time", subtitle: "Record new time", modified: false });
    const [editMultiple, setEditMultiple] = useState({ open: false, title: "Edit times", subtitle: "Modify the selected time entries", modified: false });
    const [choose, setChoose] = useState({ open: false, title: "Select Paymo Project", subtitle: "Choose or create a new Paymo Project", modified: false });
    const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
    const [userContext, setUserContext] = useContext(UserContext);
    const [disableSyncButton, setDisableSyncButton] = useState(false);
    const [disableSkipButton, setDisableSkipButton] = useState(false);
    const [disableChooseButton, setDisableChooseButton] = useState(false);
    const [disableMatchButton, setDisableMatchButton] = useState(false);
    const [lastSelectedProject, setLastSelectedProject] = useState([]);
    const [matching, setMatching] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [skipping, setSkipping] = useState(false);

    const setRightSidebar = props.setRightSidebar;
    const setEdit = props.setEdit;

    const totalComponent = <TotalEntriesAndHours totalEntries={props.rowCountState} totalHours={props.totalHours} />;

    const addModalComponent = useMemo(() => <AddModalComponent
        add={add}
        setAdd={setAdd}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        options={props.options}
        users={props.users}
    />, [add, props.users, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    const editModalComponent = useMemo(() => <EditModalComponent
        edit={props.edit}
        setEdit={setEdit}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        options={props.options}
        users={props.users}
    />, [props.edit, setEdit, props.users, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    const editMultipleModalComponent = useMemo(() => <EditMultipleModalComponent
        editMultiple={editMultiple}
        setEditMultiple={setEditMultiple}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        options={props.options}
        selectionIds={props.selectionIds}
        users={props.users}
    />, [editMultiple, props.selectionIds, props.users, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    const chooseModalComponent = <ChooseModalComponent
        choose={choose}
        setChoose={setChoose}
        selectionIds={props.selectionIds}
        setSelecitonIds={props.setSelecitonIds}
        lastSelectedProject={lastSelectedProject}
        data={props.data}
        setData={props.setData}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
    />;

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: addModalComponent, task: add, setTask: setAdd }));
        }
    }, [setRightSidebar, add, addModalComponent, props.disableModal]);

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: editModalComponent, task: props.edit, setTask: setEdit }));
        }
    }, [setRightSidebar, props.edit, editModalComponent, props.disableModal, setEdit]);

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: editMultipleModalComponent, task: editMultiple, setTask: setEditMultiple }));
        }
    }, [setRightSidebar, editMultiple, editMultipleModalComponent, props.disableModal]);

    const handleDelete = async () => {
        setOpenDeleteMultiple(false);

        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        for (const _id of props.selectionIds) {
            const url = process.env.REACT_APP_API_URL + "/timesheets/" + _id;

            try {
                await axiosInstance.delete(url, config);
            } catch (err) {
                setOpenDeleteMultiple(false);
                props.setSnackbarMessage(err.response.data);
                props.setOpenSnackbar(true);
                return;
            }
        }

        props.setSnackbarMessage("Time entries deleted");
        props.setOpenSnackbar(true);
    };

    const setDisablePaymoButtons = value => {
        setDisableSkipButton(value);
        setDisableChooseButton(value);
        setDisableSyncButton(value);
        setDisableMatchButton(value);
    };

    const handleMatch = () => {
        setDisablePaymoButtons(true);
        setMatching(true);

        let url = process.env.REACT_APP_PAYMO_API_URL + "/projects?where=active=true";
        const config = { headers: { Authorization: `Basic ${btoa(process.env.REACT_APP_PAYMO_API_KEY + ":")}` } };

        axios.get(url, config)
            .then(res => {
                const paymoProjects = res.data.projects;
                let updatedData = [...props.data];

                updatedData.forEach(entry => {
                    const selected = props.selectionIds.length === 0 ? true : props.selectionIds.find(id => entry._id === id);

                    if (!entry.paymoTimeEntryId && selected) {
                        for (let i = 0; i < paymoProjects.length; i++) {
                            if (paymoProjects[i].name.toLowerCase().indexOf(entry.project.toLowerCase()) >= 0) {
                                entry.paymoProject = paymoProjects[i].name;;
                                entry.paymoProjectId = paymoProjects[i].id;
                                break;
                            }
                        }
                    } else {
                        if (!entry.paymoTimeEntryId) {
                            delete entry.paymoProject;
                        }

                        delete entry.paymoProjectId;
                    }
                });

                props.setData(updatedData);
                props.setSnackbarMessage("Match completed");
                props.setOpenSnackbar(true);
                setDisablePaymoButtons(false);
                setMatching(false);
            })
            .catch(err => {
                props.setSnackbarMessage("Unable to connect to Paymo");
                props.setOpenSnackbar(true);
                setDisablePaymoButtons(false);
                setMatching(false);
            });
    };

    const handleSync = async () => {
        setDisablePaymoButtons(true);
        setSyncing(true);

        const modifiedTaskIds = new Set();

        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const paymoConfig = { headers: { Authorization: `Basic ${btoa(process.env.REACT_APP_PAYMO_API_KEY + ":")}` } };

        const data = [...props.data];

        for (const entry of data) {
            const selected = props.selectionIds.length === 0 ? true : props.selectionIds.find(id => entry._id === id);

            if (entry.paymoProjectId && !entry.paymoTimeEntryId && selected) {
                const paymoProject = entry.paymoProject;

                let body = {};
                let res = "";
                let url = "";

                let tasks = [];
                url = process.env.REACT_APP_PAYMO_API_URL + "/tasks?where=project_id=" + entry.paymoProjectId;

                try {
                    res = await axios.get(url, paymoConfig);
                } catch (err) {
                    if (err.response.status === 429) {
                        try {
                            await new Promise(resolve => setTimeout(resolve, 4000));
                            res = await axios.get(url, paymoConfig);
                        } catch {
                            setDisableSyncButton(false);
                            setDisablePaymoButtons(false);
                            props.setSnackbarMessage(err.response.data.message);
                            props.setOpenSnackbar(true);
                            return;
                        }
                    } else {
                        setDisableSyncButton(false);
                        setDisablePaymoButtons(false);
                        props.setSnackbarMessage(err.response.data.message);
                        props.setOpenSnackbar(true);
                        return;
                    }
                }

                tasks = res.data.tasks;

                let matchingTaskId = "";
                let taskName = entry.description;

                if (entry.area) {
                    taskName += " (" + entry.area + ")";
                }

                for (const task of tasks) {
                    if (taskName === task.name) {
                        matchingTaskId = task.id;
                        break;
                    }
                }

                if (!matchingTaskId) {
                    url = process.env.REACT_APP_PAYMO_API_URL + "/tasks";
                    body = { name: taskName, project_id: entry.paymoProjectId };

                    try {
                        res = await axios.post(url, body, paymoConfig);
                        matchingTaskId = res.data.tasks[0].id;
                    } catch (err) {
                        if (err.response.status === 429) {
                            try {
                                await new Promise(resolve => setTimeout(resolve, 4000));
                                res = await axios.post(url, body, paymoConfig);
                                matchingTaskId = res.data.tasks[0].id;
                            } catch {
                                setDisableSyncButton(false);
                                setDisablePaymoButtons(false);
                                props.setSnackbarMessage(err.response.data.message);
                                props.setOpenSnackbar(true);
                                return;
                            }
                        } else {
                            setDisableSyncButton(false);
                            setDisablePaymoButtons(false);
                            props.setSnackbarMessage(err.response.data.message);
                            props.setOpenSnackbar(true);
                            return;
                        }
                    }
                } else {
                    url = process.env.REACT_APP_PAYMO_API_URL + "/tasks/" + matchingTaskId;
                    body = { complete: false };

                    try {
                        res = await axios.post(url, body, paymoConfig);
                    } catch (err) {
                        if (err.response.status === 429) {
                            try {
                                await new Promise(resolve => setTimeout(resolve, 4000));
                                res = await axios.post(url, body, paymoConfig);
                            } catch {
                                setDisableSyncButton(false);
                                setDisablePaymoButtons(false);
                                props.setSnackbarMessage(err.response.data.message);
                                props.setOpenSnackbar(true);
                                return;
                            }
                        } else {
                            setDisableSyncButton(false);
                            setDisablePaymoButtons(false);
                            props.setSnackbarMessage(err.response.data.message);
                            props.setOpenSnackbar(true);
                            return;
                        }
                    }
                }

                url = process.env.REACT_APP_PAYMO_API_URL + "/entries";
                const date = entry.startDate.slice(0, 10);
                const duration = entry.hours * 3600;
                body = { task_id: matchingTaskId, date: date, duration: duration, description: entry.fullName };

                try {
                    res = await axios.post(url, body, paymoConfig);
                } catch (err) {
                    if (err.response.status === 429) {
                        try {
                            await new Promise(resolve => setTimeout(resolve, 4000));
                            res = await axios.post(url, body, paymoConfig);
                        } catch {
                            setDisableSyncButton(false);
                            setDisablePaymoButtons(false);
                            props.setSnackbarMessage(err.response.data.message);
                            props.setOpenSnackbar(true);
                            return;
                        }
                    } else {
                        setDisableSyncButton(false);
                        setDisablePaymoButtons(false);
                        props.setSnackbarMessage(err.response.data.message);
                        props.setOpenSnackbar(true);
                        return;
                    }
                }

                const timeEntryId = res.data.entries[0].id;

                if (timeEntryId) {
                    try {
                        url = process.env.REACT_APP_API_URL + "/timesheets/" + entry._id + "?notify=false";
                        body = { paymoProject: paymoProject, paymoTimeEntryId: timeEntryId };
                        res = await axiosInstance.post(url, body, config);
                    } catch (err) {
                        setDisableSyncButton(false);
                        setDisablePaymoButtons(false);
                        props.setSnackbarMessage("Unable to complete syncing");
                        props.setOpenSnackbar(true);
                        return;
                    }
                }

                modifiedTaskIds.add(matchingTaskId);

                if (props.syncStatus === "Not synced") {
                    props.setData(data => data.filter(dataEntry => dataEntry._id !== entry._id));
                } else {
                    const updatedData = [...props.data];
                    updatedData.find(dataEntry => dataEntry._id === entry._id).paymoTimeEntryId = timeEntryId;
                    props.setData(updatedData);
                }
            }
        }

        // Mark task as completed on Paymo
        for (const taskId of modifiedTaskIds) {
            const url = process.env.REACT_APP_PAYMO_API_URL + "/tasks/" + taskId;
            const body = { complete: true };

            try {
                await axios.post(url, body, paymoConfig);
            } catch (err) {
                if (err.response.status === 429) {
                    try {
                        await new Promise(resolve => setTimeout(resolve, 4000));
                        await axios.post(url, body, paymoConfig);
                    } catch {
                        setDisableSyncButton(false);
                        setDisablePaymoButtons(false);
                        props.setSnackbarMessage(err.response.data.message);
                        props.setOpenSnackbar(true);
                        return;
                    }
                } else {
                    setDisableSyncButton(false);
                    setDisablePaymoButtons(false);
                    props.setSnackbarMessage(err.response.data.message);
                    props.setOpenSnackbar(true);
                    return;
                }
            }
        }

        props.setSnackbarMessage("Syncing completed");
        props.setOpenSnackbar(true);
        setDisablePaymoButtons(false);
        props.setLoading(true);
        setSyncing(false);
    };

    const handleSkip = async () => {
        setDisablePaymoButtons(true);
        setSkipping(true);

        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        for (const entry of props.data) {
            const selected = props.selectionIds.length === 0 ? true : props.selectionIds.find(id => entry._id === id);

            if (!entry.paymoTimeEntryId && selected) {
                try {
                    const url = process.env.REACT_APP_API_URL + "/timesheets/" + entry._id;
                    const body = { paymoTimeEntryId: -2 };
                    await axiosInstance.post(url, body, config);
                } catch (err) {
                    setDisableSkipButton(false);
                    setDisablePaymoButtons(false);
                    props.setSnackbarMessage("Unable to complete skipping");
                    props.setOpenSnackbar(true);
                    return;
                }

                if (props.syncStatus === "Not synced") {
                    props.setData(data => data.filter(dataEntry => dataEntry._id !== entry._id));
                } else {
                    const updatedData = [...props.data];
                    updatedData.find(dataEntry => dataEntry._id === entry._id).paymoTimeEntryId = -2;
                    props.setData(updatedData);
                }
            }
        }

        props.setSnackbarMessage("Skip completed");
        props.setOpenSnackbar(true);
        setDisablePaymoButtons(false);
        setSkipping(false);
        props.setLoading(true);
    };

    useEffect(() => {
        if (props.selectionIds.length > 0) {
            const lastProject = props.data.find(entry => entry._id === props.selectionIds[props.selectionIds.length - 1]);

            if (lastProject) {
                setLastSelectedProject(lastProject.project);
            }
        }
    }, [props.selectionIds, props.data]);

    const handleKeyPress = useCallback(event => {
        if (event.altKey === true && event.keyCode === 187 && !add.open && !props.edit.open && !editMultiple.open) {
            setAdd({ ...add, open: true });
        }
    }, [add, props.edit, editMultiple]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <Grid container wrap="nowrap">
            <Grid container item spacing={2} alignItems="center" justifyContent="flex-start" xs={8.5}>
                <Grid item>
                    <Button onClick={() => props.apiRef.current.exportDataAsPrint()} size="medium">
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><PrintIcon sx={{ fontSize: 20 }} /></span>
                        Print
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => setAdd({ ...add, open: true })}
                        size="medium"
                        sx={{ backgroundColor: "#6096ba", color: "#e7ecef", "&:hover": { backgroundColor: "#6096ba" } }}
                    >
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                        Add
                    </Button>
                </Grid>
                {props.selectionIds.length > 0 &&
                    <>
                        <Grid item>
                            <Button
                                onClick={() => setEditMultiple({ ...editMultiple, open: true })}
                                size="medium"
                                sx={{ backgroundColor: "#e76f51", color: "#e7ecef", "&:hover": { backgroundColor: "#e76f51" } }}
                            >
                                <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><EditIcon sx={{ fontSize: 20 }} /></span>
                                Edit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => setOpenDeleteMultiple(true)}
                                size="medium"
                                sx={{ backgroundColor: "#bb3030", color: "#d9d9d9", "&:hover": { backgroundColor: "#bb3030" } }}
                            >
                                <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><EditIcon sx={{ fontSize: 20 }} /></span>
                                Delete
                            </Button>
                        </Grid>
                    </>
                }
                <Grid item>
                    <Divider orientation="vertical" sx={{ height: "35px" }} />
                </Grid>
                {props.selectionIds.length > 0 &&
                    <Grid item>
                        <Button
                            disabled={disableChooseButton}
                            onClick={() => setChoose({ ...choose, open: true })}
                            size="medium"
                            sx={{ backgroundColor: "#e76f51", color: "#e7ecef", "&:hover": { backgroundColor: "#e76f51" } }}
                        >
                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><SearchIcon sx={{ fontSize: 20 }} /></span>
                            Choose
                        </Button>
                    </Grid>
                }
                <Grid item>
                    <Box sx={{ position: "relative" }}>
                        <Button
                            disabled={disableMatchButton}
                            onClick={handleMatch}
                            size="medium"
                            sx={{ backgroundColor: "#6096ba", color: "#e7ecef", "&:hover": { backgroundColor: "#6096ba" } }}
                        >
                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><JoinInnerIcon sx={{ fontSize: 20 }} /></span>
                            Match
                        </Button>
                        {matching &&
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "#6096ba",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        }
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ position: "relative" }}>
                        <Button onClick={handleSync} size="medium" disabled={disableSyncButton}>
                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><SyncIcon sx={{ fontSize: 20 }} /></span>
                            Sync
                        </Button>
                        {syncing &&
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "#274c77",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        }
                    </Box>
                </Grid>
                {props.selectionIds.length > 0 &&
                    <>
                        <Grid item>
                            <Divider orientation="vertical" sx={{ height: "35px" }} />
                        </Grid>
                        <Grid item>
                            <Box sx={{ position: "relative" }}>
                                <Button onClick={handleSkip} size="medium" variant="outlined" disabled={disableSkipButton}>
                                    <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><CallMissedOutgoingIcon sx={{ fontSize: 20 }} /></span>
                                    Skip
                                </Button>
                                {skipping &&
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "#274c77",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                }
                            </Box>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid container item xs={3.5}>{totalComponent}</Grid>
            {add.open && !props.disableModal && <TheModal task={add} setTask={setAdd} modalComponent={addModalComponent} />}
            {props.edit.open && !props.disableModal && <TheModal task={props.edit} setTask={props.setEdit} modalComponent={editModalComponent} />}
            {editMultiple.open && !props.disableModal && <TheModal task={editMultiple} setTask={setEditMultiple} modalComponent={editMultipleModalComponent} />}
            {choose.open && <TheModal task={choose} setTask={setChoose} modalComponent={chooseModalComponent} />}
            {openDeleteMultiple && <DeleteEntryDialog open={openDeleteMultiple} setOpen={setOpenDeleteMultiple} handleDelete={handleDelete} />}
        </Grid >
    );
};

export default TimesheetsToolbar;
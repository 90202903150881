import { Grid, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";

const TimesheetsToolbar = () => {
    const [userContext] = useContext(UserContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Grid container item>
            {userContext.userType === "standard" ?
                <Button
                    onClick={() => navigate("/addtime")}
                    size="medium"
                    sx={{ height: "45px", width: "120px" }}
                >
                    <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                    {t("main.add")}
                </Button>
                :
                <Button
                    onClick={() => navigate("/missingtimesheets")}
                    size="medium"
                    sx={{ height: "45px" }}
                >
                    Missing Report
                </Button>
            }
        </Grid >
    );
};

export default TimesheetsToolbar;
import { useState, useRef, useEffect } from "react";
import Title from "../../components/Common/Title";
import MissingTimesheetsTable from "../../components/MissingTimesheets/Admin/MissingTimesheetsTable";
import MissingTimesheetsDataFilter from "../../components/MissingTimesheets/Admin/MissingTimesheetsDataFilter";
import TheSnackbar from "../../components/Common/TheSnackbar";
import { useOutletContext } from "react-router-dom";

const MissingTimesheets = () => {
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({ persons: [] });
    const topRef = useRef();
    const [rowCountState, setRowCountState] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: window.localStorage.getItem("missingTimesheetsTablePageSize") !== null ? parseInt(window.localStorage.getItem("missingTimesheetsTablePageSize")) : 100
    });
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [title, setTitle] = useState("");
    const [, setRightSidebar] = useOutletContext();

    useEffect(() => {
        setRightSidebar({ open: false });
    }, [setRightSidebar]);

    return (
        <div ref={topRef}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Title title="Missing Timesheets" subtitle="List of users with missing time" />
            <MissingTimesheetsDataFilter
                data={data}
                setData={setData}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                setRowCountState={setRowCountState}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                setLoading={setLoading}
                options={options}
                setOptions={setOptions}
                setTitle={setTitle}
            />
            <br />
            <MissingTimesheetsTable
                data={data}
                rowCountState={rowCountState}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                topRef={topRef}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                options={options}
                setOptions={setOptions}
                title={title}
            />
        </div>
    );
};

export default MissingTimesheets;
import bottom from "../Images/bottom/bottom.jpg";
import prop from "../Images/bottom/prop.jpg";
import rudder from "../Images/bottom/rudder.jpg";
import zinc from "../Images/bottom/zinc.jpg";
import cutwater from "../Images/bottom/cutwater.jpg";
import hull from "../Images/hull/hull.jpg";
import bootStripe from "../Images/hull/boot_stripe.jpg";
import anchorPocket from "../Images/hull/anchor_pocket.jpg";
import fairlead from "../Images/hull/fairlead.jpg";
import freeingPort from "../Images/hull/freeing_port.jpg";
import rubRail from "../Images/hull/rub_rail.jpg";
import porthole from "../Images/hull/porthole.jpg";
import bracket from "../Images/parts/bracket.jpg";
import deckPlate from "../Images/parts/deck_plate.jpg";
import door from "../Images/parts/door.jpg";
import table from "../Images/parts/table.jpg";
import engineCover from "../Images/parts/engine_cover.jpg";
import light from "../Images/parts/light.jpg";
import lightCover from "../Images/parts/light_cover.jpg";
import speakerCover from "../Images/parts/speaker_cover.jpg";
import hatch from "../Images/parts/hatch.jpg";
import lifeRaft from "../Images/parts/life_raft.jpg";
import lifeRing from "../Images/parts/life_ring.jpg";
import overheadPanel from "../Images/parts/overhead_panel.jpg";
import plate from "../Images/parts/plate.jpg";
import vent from "../Images/parts/vent.jpg";
import ladder from "../Images/parts/ladder.jpg";
import steps from "../Images/parts/steps.jpg";
import radar from "../Images/parts/radar.jpg";
import antenna from "../Images/parts/antenna.jpg";
import dome from "../Images/parts/dome.jpg";
import chair from "../Images/parts/chair.jpg";
import parts from "../Images/parts/parts.jpg";
import superstructure from "../Images/superstructure/superstructure.jpg";
import nameboard from "../Images/superstructure/nameboard.jpg";
import mainDeck from "../Images/superstructure/main_deck.jpg";
import mainDeckHouse from "../Images/superstructure/main_deck_house.jpg";
import mainDeckBrow from "../Images/superstructure/main_deck_brow.jpg";
import mainDeckOverhead from "../Images/superstructure/main_deck_overhead.jpg";
import mainDeckBulwark from "../Images/superstructure/main_deck_bulwark.jpg";
import mainDeckWindow from "../Images/superstructure/main_deck_window.jpg";
import mainDeckFashionBoard from "../Images/superstructure/main_deck_fashion_board.jpg";
import bridgeDeck from "../Images/superstructure/bridge_deck.jpg";
import bridgeDeckHouse from "../Images/superstructure/bridge_deck_house.jpg";
import bridgeDeckBrow from "../Images/superstructure/bridge_deck_brow.jpg";
import bridgeDeckOverhead from "../Images/superstructure/bridge_deck_overhead.jpg";
import bridgeDeckBulwark from "../Images/superstructure/bridge_deck_bulwark.jpg";
import bridgeDeckWindow from "../Images/superstructure/bridge_deck_window.jpg";
import bridgeDeckFashionBoard from "../Images/superstructure/bridge_deck_fashion_board.jpg";
import sundeck from "../Images/superstructure/sundeck.jpg";
import sundeckHouse from "../Images/superstructure/sundeck_house.jpg";
import sundeckBrow from "../Images/superstructure/sundeck_brow.jpg";
import sundeckBulwark from "../Images/superstructure/sundeck_bulwark.jpg";
import sundeckWindow from "../Images/superstructure/sundeck_window.jpg";
import hardtop from "../Images/superstructure/hardtop.jpg";
import mast from "../Images/superstructure/mast.jpg";
import transom from "../Images/transom/transom.jpg";
import transomDoor from "../Images/transom/transom_door.jpg";
import transomSteps from "../Images/transom/transom_steps.jpg";
import transomName from "../Images/transom/transom_name.jpg";
import passerelle from "../Images/transom/passerelle.jpg";
import swimPlatform from "../Images/transom/swim_platform.jpg";
import swimPlatformSkirt from "../Images/transom/swim_platform_skirt.jpg";

const areas = {
    bottom: {
        title: "Bottom",
        subtitle: "chooseArea.bottom.subtitle",
        areas:
            [
                { _id: "bottom", text: "Bottom", image: bottom },
                { _id: "runningGearProp", text: "Running Gear / Prop", image: prop },
                { _id: "rudder", text: "Rudder", image: rudder },
                { _id: "cutwater", text: "Cutwater", image: cutwater },
                { _id: "zinc", text: "Zinc", image: zinc }
            ]
    },
    hull: {
        title: "Hull",
        subtitle: "chooseArea.hull.subtitle",
        areas:
            [
                { _id: "hull", text: "Hull", image: hull },
                { _id: "bootStripe", text: "Boot Stripe", image: bootStripe },
                { _id: "anchorPocket", text: "Anchor Pocket", image: anchorPocket },
                { _id: "fairlead", text: "Fairlead", image: fairlead },
                { _id: "freeingPort", text: "Freeing Port", image: freeingPort },
                { _id: "rubRail", text: "Rub Rail", image: rubRail },
                { _id: "porthole", text: "Porthole", image: porthole }
            ]
    },
    superstructure: {
        title: "Superstructure",
        subtitle: "chooseArea.superstructure.subtitle",
        areas:
            [
                { _id: "superstructure", text: "Superstructure", image: superstructure },
                { _id: "nameboard", text: "Nameboard", image: nameboard },
                { _id: "mainDeck", text: "Main Deck", image: mainDeck },
                { _id: "mainDeckHouse", text: "Main Deck House", image: mainDeckHouse },
                { _id: "mainDeckWindow", text: "Main Deck Window", image: mainDeckWindow },
                { _id: "mainDeckBulwark", text: "Main Deck Bulwark", image: mainDeckBulwark },
                { _id: "mainDeckBrow", text: "Main Deck Brow", image: mainDeckBrow },
                { _id: "mainDeckOverhead", text: "Main Deck Overhead", image: mainDeckOverhead },
                { _id: "mainDeckFashionBoard", text: "Main Deck Fashion Board", image: mainDeckFashionBoard },
                { _id: "bridgeDeck", text: "Bridge Deck", image: bridgeDeck },
                { _id: "bridgeDeckHouse", text: "Bridge Deck House", image: bridgeDeckHouse },
                { _id: "bridgeDeckWindow", text: "Bridge Deck Window", image: bridgeDeckWindow },
                { _id: "bridgeDeckBulwark", text: "Bridge Deck Bulwark", image: bridgeDeckBulwark },
                { _id: "bridgeDeckBrow", text: "Bridge Deck Brow", image: bridgeDeckBrow },
                { _id: "bridgeDeckOverhead", text: "Bridge Deck Overhead", image: bridgeDeckOverhead },
                { _id: "bridgeDeckFashionBoard", text: "Bridge Deck Fashion Board", image: bridgeDeckFashionBoard },
                { _id: "sundeck", text: "Sundeck", image: sundeck },
                { _id: "sundeckHouse", text: "Sundeck House", image: sundeckHouse },
                { _id: "sundeckWindow", text: "Sundeck Window", image: sundeckWindow },
                { _id: "sundeckBulwark", text: "Sundeck Bulwark", image: sundeckBulwark },
                { _id: "sundeckBrow", text: "Sundeck Brow", image: sundeckBrow },
                { _id: "hardtop", text: "Hardtop / Arch", image: hardtop },
                { _id: "mast", text: "Mast", image: mast }
            ]
    },
    transom: {
        title: "Transom",
        subtitle: "chooseArea.transom.subtitle",
        areas:
            [
                { _id: "transom", text: "Transom", image: transom },
                { _id: "transomDoor", text: "Transom Door", image: transomDoor },
                { _id: "transomSteps", text: "Transom Steps", image: transomSteps },
                { _id: "passerelle", text: "Passerelle", image: passerelle },
                { _id: "swimPlatform", text: "Swim Platform", image: swimPlatform },
                { _id: "swimPlatformSkirt", text: "Swim Platform Skirt", image: swimPlatformSkirt },
                { _id: "transomName", text: "Transom Name", image: transomName }
            ]
    },
    parts: {
        title: "Parts",
        subtitle: "chooseArea.parts.subtitle",
        areas:
            [
                { _id: "parts", text: "Parts", image: parts },
                { _id: "hatch", text: "Hatch", image: hatch },
                { _id: "door", text: "Door", image: door },
                { _id: "dome", text: "Dome", image: dome },
                { _id: "ladder", text: "Ladder", image: ladder },
                { _id: "steps", text: "Steps", image: steps },
                { _id: "lifeRaft", text: "Life Raft", image: lifeRaft },
                { _id: "lifeRing", text: "Life Ring", image: lifeRing },
                { _id: "overheadPanel", text: "Overhead Panel", image: overheadPanel },
                { _id: "plate", text: "Plate", image: plate },
                { _id: "radar", text: "Radar", image: radar },
                { _id: "antenna", text: "Antenna", image: antenna },
                { _id: "vent", text: "Vent", image: vent },
                { _id: "deckPlate", text: "Deck Plate", image: deckPlate },
                { _id: "bracket", text: "Bracket", image: bracket },
                { _id: "engineCover", text: "Engine Cover", image: engineCover },
                { _id: "lightCover", text: "Light Cover", image: lightCover },
                { _id: "light", text: "Light", image: light },
                { _id: "speakerCover", text: "Speaker Cover", image: speakerCover },
                { _id: "table", text: "Table", image: table },
                { _id: "chair", text: "Chair", image: chair }
            ]
    },
    additional: {
        areas:
            [
                { text: "All Around" },
                { text: "Entire" }
            ]
    }
};



export default areas;
import TotalEntriesAndHours from "../DataTable/TableComponents/TotalEntriesAndHours";
import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { Grid } from "@mui/material";

const ProjectsToolbar = (props) => {
    const totalComponent = <TotalEntriesAndHours totalEntries={props.rowCountState} />;

    return (
        <Grid container wrap="nowrap">
            <Grid container item spacing={2} alignItems="center" justifyContent="flex-start">
                <Grid item>
                    <Button onClick={() => props.apiRef.current.exportDataAsPrint()} size="medium">
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><PrintIcon sx={{ fontSize: 20 }} /></span>
                        Print
                    </Button>
                </Grid>
            </Grid>
            <Grid container item>{totalComponent}</Grid>
        </Grid>
    );
};

export default ProjectsToolbar;
import { Typography, Grid } from "@mui/material";

const TotalEntriesAndHours = props => {
    return (
        <Grid container item spacing={2.5} alignItems="center" justifyContent="flex-end">
            <Grid item>
                <Typography sx={{ fontSize: "18px" }}><b>Entries:</b> {props.totalEntries}</Typography>
            </Grid>
            {props.totalHours !== undefined &&
                <Grid item>
                    <Typography sx={{ fontSize: "18px" }}><b>Total:</b> {props.totalHours.toFixed(2)} hours</Typography>
                </Grid>
            }
        </Grid>
    );
};

export default TotalEntriesAndHours;
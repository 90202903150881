import { useState, useMemo, useCallback } from "react";
import DataTable from "../DataTable/DataTable";
import DataTableFooter from "../DataTable/TableComponents/DataTableFooter";
import DataTableToolbar from "../DataTable/TableComponents/DataTableToolbar";
import TotalEntriesAndHours from "../DataTable/TableComponents/TotalEntriesAndHours";
import { Tooltip, Link } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid";
import UsersToolbar from "./UsersToolbar";
import TableSummary from "../DataTable/TableComponents/TableSummary";
import LockIcon from "@mui/icons-material/Lock";

const UsersTable = props => {
    const [edit, setEdit] = useState({ open: false, title: "Edit user", subtitle: "Modify the selected user", _id: "", modified: false });
    const [selectionIds, setSelectionIds] = useState([]);
    const rows = useMemo(() => props.data, [props.data]);
    const apiRef = useGridApiRef();

    const setActiveStatus = props.setActiveStatus;
    const setCompany = props.setCompany;

    const columns = useMemo(() => {
        const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };
        const unlockInterval = 1000 * 60 * 60;

        const handleClick = (event, field, value) => {
            event.stopPropagation();

            if (field === "active") {
                setActiveStatus(value ? "Active" : "Inactive");
            } else if (field === "company") {
                setCompany([value]);
            }
        };

        return [
            {
                field: "lastName",
                headerName: "Last Name",
                minWidth: 100,
                flex: 1.4,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "firstName",
                headerName: "First Name",
                minWidth: 100,
                flex: 1.4,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "middleInitial",
                headerName: "M.I.",
                minWidth: 65,
                flex: 0.5,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "username",
                headerName: "Username",
                minWidth: 100,
                flex: 1.75,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "company",
                headerName: "Company",
                minWidth: 100,
                flex: 1.75,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "company", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "language",
                headerName: "Language",
                minWidth: 100,
                flex: 1.25,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "active",
                headerName: "Active",
                minWidth: 90,
                flex: 0.5,
                renderCell: params => <Tooltip title={params.value ? "Active" : "Inactive"}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "active", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value ? "Active" : "Inactive"}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "attempts",
                headerName: "Locked",
                minWidth: 90,
                flex: 0.5,
                headerAlign: "center",
                renderCell: params =>
                    <Tooltip title="Locked">
                        <div style={{ ...cellParams.style, display: "flex", width: "100%", justifyContent: "center" }}>
                            {params.row.attempts === 9 && new Date() - new Date(params.row.last) < unlockInterval ? <LockIcon /> : ""}
                        </div>
                    </Tooltip>
            }
        ];
    }, [setActiveStatus, setCompany]);

    const CustomFooter = useCallback(() => { return (<DataTableFooter rows={rows} />) }, [rows]);

    const CustomToolbar = useCallback(() => {
        const totalComponent = <TotalEntriesAndHours totalEntries={props.rowCountState} />;
        const toolbarComponent = <TableSummary totalComponent={totalComponent} title="List of Users" columns={columns} />;

        return (<DataTableToolbar toolbarComponent={toolbarComponent} />)
    }, [props.rowCountState, columns]);

    const handlePageChange = useCallback(newPaginationModel => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("usersTablePageSize", newPaginationModel.pageSize);

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    }, [props]);

    const handleRowClick = useCallback(row => {
        setEdit(edit => ({ ...edit, open: true, _id: row._id }));
    }, []);

    return (
        <div>
            <UsersToolbar
                apiRef={apiRef}
                selectionIds={selectionIds}
                edit={edit}
                setEdit={setEdit}
                options={props.options}
                setSnackbarMessage={props.setSnackbarMessage}
                setOpenSnackbar={props.setOpenSnackbar}
                rowCountState={props.rowCountState}
                setRightSidebar={props.setRightSidebar}
                disableModal={props.disableModal}
            />
            <DataTable
                columns={columns}
                rows={rows}
                customFooter={CustomFooter}
                customToolbar={CustomToolbar}
                paginationMode="server"
                paginationModel={props.paginationModel}
                handlePageChange={handlePageChange}
                handleRowClick={handleRowClick}
                rowCountState={props.rowCountState}
                loading={props.loading}
                setSelectionIds={setSelectionIds}
                apiRef={apiRef}
            />
        </div>
    );
};

export default UsersTable;
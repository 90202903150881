import { Card, CardContent, Typography } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils";

const DashboardCard = props => {
    const [data, setData] = useState();
    const [userContext, setUserContext] = useContext(UserContext);

    useEffect(() => {
        const fetchData = () => {
            const url = props.url;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config).then(res => setData(+res.data.toFixed(2))).catch(err => setData("0"));
        };

        fetchData();
    }, [props.url, userContext, setUserContext]);

    return (
        <Card sx={{ backgroundColor: "#ffffff", height: props.height, boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}>
            <CardContent align="left">
                <Typography variant="h8">{props.heading}</Typography>
            </CardContent>
            <CardContent align="center" sx={{ marginBottom: 3.5, marginTop: 1.5 }}>
                <Typography variant="h3" sx={{ color: "#000000", marginBottom: 1 }}>{data}</Typography>
                <Typography variant="body1">{props.description}</Typography>
            </CardContent>
        </Card>
    );
};

export default DashboardCard;
import { useMemo, useCallback } from "react";
import DataTable from "../../DataTable/DataTable";
import DataTableFooter from "../../DataTable/TableComponents/DataTableFooter";
import DataTableToolbar from "../../DataTable/TableComponents/DataTableToolbar";
import TotalEntriesAndHours from "../../DataTable/TableComponents/TotalEntriesAndHours";
import { useGridApiRef } from "@mui/x-data-grid";
import MissingTimesheetsToolbar from "./MissingTimesheetsToolbar";
import TableSummary from "../../DataTable/TableComponents/TableSummary";

const MissingTimesheetsTable = props => {
    const rows = useMemo(() => props.data, [props.data]);
    const apiRef = useGridApiRef();

    const columns = useMemo(() => {
        const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };

        return [
            {
                field: "fullName",
                headerName: "Name",
                minWidth: 500,
                flex: 1,
                renderCell: params => <div {...cellParams}>{params.value}</div>
            }
        ];
    }, []);

    const CustomFooter = useCallback(() => { return (<DataTableFooter rows={rows} />) }, [rows]);

    const CustomToolbar = useCallback(() => {
        const totalComponent = <TotalEntriesAndHours totalEntries={props.rowCountState} />;
        const toolbarComponent = <TableSummary
            totalComponent={totalComponent}
            title={props.title}
            columns={columns}
        />;

        return (<DataTableToolbar toolbarComponent={toolbarComponent} />);
    }, [props.rowCountState, columns, props.title]);

    const handlePageChange = useCallback(newPaginationModel => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("missingTimesheetsTablePageSize", newPaginationModel.pageSize);

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    }, [props]);

    return (
        <div>
            <MissingTimesheetsToolbar
                apiRef={apiRef}
                options={props.options}
                setSnackbarMessage={props.setSnackbarMessage}
                setOpenSnackbar={props.setOpenSnackbar}
                rowCountState={props.rowCountState}
            />
            <DataTable
                columns={columns}
                rows={rows}
                customFooter={CustomFooter}
                customToolbar={CustomToolbar}
                paginationMode="server"
                paginationModel={props.paginationModel}
                handlePageChange={handlePageChange}
                rowCountState={props.rowCountState}
                loading={props.loading}
                apiRef={apiRef}
            />
        </div>
    );
};

export default MissingTimesheetsTable;
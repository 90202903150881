import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const UnsyncDialog = props => {
    const handleClose = () => { props.setOpen(false) };

    return (
        <Dialog open={props.open} onClose={handleClose} PaperProps={{ style: { backgroundColor: "#274c77", padding: 30 } }}>
            <DialogTitle color="#e7ecef">Confirm Unsync</DialogTitle>
            <DialogContent><DialogContentText>Are you sure you want to unsync?</DialogContentText></DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={{ backgroundColor: "transparent", color: "#e7ecef", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                    Cancel
                </Button>
                <Button onClick={props.handleUnsync} sx={{ backgroundColor: "#6096ba", color: "#e7ecef", "&:hover": { backgroundColor: "#6096ba" } }}>
                    Unsync
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnsyncDialog;
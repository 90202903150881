import { Button, Grid, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import FieldsOutline from "../../../Common/FieldsOutline";
import axios from "axios";
import dayjs from "dayjs";
import ErrorBox from "../../../Common/ErrorBox";

const AddProjectComponent = props => {
    const [disableButton, setDisableButton] = useState(false);
    const headingParams = { variant: "body1", sx: { fontWeight: "bold" } };
    const gridItemParams = { sx: { width: "50%" } };
    const textFieldParams = { type: "text", fullWidth: true, variant: "outlined" };
    const [details, setDetails] = useState({
        clientId: props.client.id,
        clientName: props.client.name,
        projectName: props.lastSelectedProject + " - T&M (" + dayjs().format("MMM. YYYY") + ")"
    });
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const url = process.env.REACT_APP_PAYMO_API_URL + "/projects";
        const config = { headers: { Authorization: `Basic ${btoa(process.env.REACT_APP_PAYMO_API_KEY + ":")}` } };
        const body = { name: details.projectName, client_id: details.clientId };

        axios.post(url, body, config)
            .then(res => {
                const projectName = res.data.projects[0].name;
                const projectId = res.data.projects[0].id;
                const updatedData = [...props.data];

                props.selectionIds.forEach(id => {
                    const project = updatedData.find(entry => entry._id === id);

                    if (project.paymoTimeEntryId !== undefined || project.paymoTimeEntryId !== null) {
                        project.paymoProject = projectName;
                        project.paymoProjectId = projectId;
                    }
                });

                props.setData(updatedData);
                props.setAddProject(addProject => ({ ...addProject, open: false, openPrevious: false }));

                props.setSnackbarMessage("Paymo projects changed");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data.message);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const handleChange = (name, value) => {
        setDetails({ ...details, [name]: value });

        if (!props.addProject.modified) {
            setAddProject({ ...props.addProject, modified: true });
        }
    };

    const setAddProject = props.setAddProject;

    useEffect(() => {
        const fieldsNotEmpty = () => {
            const requiredDetails = ["projectName"];
            return requiredDetails.every(field => details[field]);
        };

        setDisableButton(!fieldsNotEmpty());
    }, [details]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <Grid container item spacing={2.5}>
                            <Grid item {...gridItemParams}>
                                <Typography {...headingParams}>Client Information</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item {...gridItemParams}>
                            <TextField
                                {...textFieldParams}
                                name="clientName"
                                label="Client name"
                                value={details.clientName}
                                disabled
                                onChange={event => handleChange(event.target.name, event.target.value)}
                            />
                        </Grid>
                        <br />
                        <Grid container item spacing={2.5}>
                            <Grid item {...gridItemParams}>
                                <Typography {...headingParams}>Project Information</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item {...gridItemParams}>
                            <TextField
                                {...textFieldParams}
                                name="projectName"
                                label="Project name"
                                value={details.projectName}
                                onChange={event => handleChange(event.target.name, event.target.value)}
                            />
                        </Grid>
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item justifyContent="flex-end">
                    <Grid item sx={{ mr: 2.5 }}>
                        <Button variant="outlined" onClick={() => props.setAddProject({ ...props.addProject, open: false, modified: false })}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="submit"
                            disabled={disableButton}
                            sx={{ backgroundColor: "#6096ba", color: "#e7ecef", "&:hover": { backgroundColor: "#6096ba" } }}
                        >
                            Add & Change
                        </Button>
                    </Grid>
                </Grid>
            </Grid >
        </form >
    );
};

export default AddProjectComponent;